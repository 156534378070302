/* eslint-disable eqeqeq */
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doc, setDoc, deleteDoc, updateDoc, writeBatch } from '@firebase/firestore';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { db } from 'services';
import { SessionContext } from 'contexts';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import DraggableAvatarPicker from 'components/DraggableAvatarPicker';
import ValueSelectDialog from 'components/Dialog/ValueSelectDialog';
import ValueSelectConfirmDialog from 'components/Dialog/ValueSelectConfirmDialog';
import PlanInfoDialog from 'components/Dialog/PlanInfoDialog';
import useUserPlan from 'stripe/useUserPlan';
import { BASIC, FREE, planFeatures } from 'configs/planFeatures';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MinMaxSlider from 'components/MinMaxSlider';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import {
  ArrowBack,
  CoPresent,
  FormatLineSpacing,
  MapsHomeWork,
  Lock,
  LockOpen,
  Link,
  LinkOff,
  BrowseGallery,
  PeopleAlt,
  Book,
  WorkspacePremium,
} from '@mui/icons-material';
import {
  SelectChangeEvent,
  Box,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Button,
  FormControl,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';
import MultipleSelectChip from 'components/Select/MultipleSelectChip';
import Onboarding from 'components/Onboarding';
import { LESSON_CREATION_KEY, useLessonOnboarding } from './utils/useLessonOnboarding';
import { ILesson } from './utils/types';
import { customColors, theme } from 'styles';
import HelpButton from 'components/Button/HelpButton';
import { DAY_TIME_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';
import {
  getClassHelperText,
  getNotSimultaneousWithHelperText,
  getOccurBeforeHelperText,
  getRoomHelperText,
  getSimultaneousWithHelperText,
  getSubjectHelperText,
  getTeacherHelperText,
} from './utils/helperTextUtils';
import { LoadingButton } from '@mui/lab';

type Props = {
  lesson: ILesson;
  setLesson: Dispatch<SetStateAction<ILesson | undefined>>;
};
export default function Lesson({ lesson, setLesson }: Props) {
  const { t } = useTranslation();
  const { user, file, resources, subjects, lessons, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const fileId = file?.id || file?.name;
  const lessonsPath = 'users/' + ownerEmail + '/files/' + fileId + '/lessons';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
  const history = useHistory();
  const updateLesson = lesson ? lesson.name !== '' : false;
  const [showPlanDialog, setShowPlanDialog] = useState(false);
  const [lessonNumLimitReached, setLessonNumLimitReached] = useState(false);
  const userPlan = useUserPlan(user);
  const { runLessonCreationOnboarding, lessonCreationSteps } = useLessonOnboarding(t, file!);
  const [selectedForDaysBetweenLessons, setSelectedForDaysBetweenLessons] = useState<string[]>([]);
  const [hasAttemptedToSave, setHasAttemptedToSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const classes = resources
    .filter((resource) => {
      return resource.type === 'class';
    })
    .map((class_) => class_.name);

  const teachers = resources
    .filter((resource) => {
      return resource.type === 'teacher';
    })
    .map((teacher) => teacher.name);

  const rooms = resources
    .filter((resource) => {
      return resource.type === 'room';
    })
    .map((room) => room.name);

  const subjects_ = subjects.map((subject) => subject.name);
  const lessons_ = lessons.map((lesson) => lesson.name);

  const days = file!.days;
  const times = file!.times;
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const previousName = lesson ? lesson.name : '';

  async function submitForm() {
    if (userPlan === FREE && lessons.length + 1 > planFeatures.free.maxLessons) {
      setLessonNumLimitReached(true);
    } else if (userPlan === BASIC && lessons.length + 1 > planFeatures.basic.maxLessons) {
      setLessonNumLimitReached(true);
    } else {
      setIsLoading(true);
      try {
        const name = selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses.toString();
        let predefinedTimes: string[] = [];
        for (let i = 0; i < selectedCells.length; i++) {
          predefinedTimes.push(selectedCells[i]);
        }

        const newLesson: ILesson = {
          color: selectedColor,
          photoURL: selectedPhotoURL,
          name: name,
          short: '',

          subject: selectedSubject,
          lessonsWeek: selectedLessonsWeek,
          split: selectedSplit,
          minGapLessons: +selectedMinGapLessons,
          maxGapLessons: +selectedMaxGapLessons,

          classNum: +selectedClassNum,
          classes: selectedClasses,
          teacherNum: +selectedTeacherNum,
          teachers: selectedTeachers,
          roomNum: +selectedRoomNum,
          rooms: selectedRooms,

          priority: subjects.find((sub) => sub.name === selectedSubject[0])?.priority ?? priorityOptions[2],
          predefinedTimes: predefinedTimes,
          simultaneousWith: selectedSimultaneousWith,
          notSimultaneousWith: selectedNotSimultaneousWith,
          occurBefore: selectedOccurBefore,
          occurBeforeMinDays: +selectedOccurBeforeMinDays,

          createdAt: new Date(),
          updatedAt: new Date(),
        };

        const batch = writeBatch(db);
        batch.set(doc(db, lessonsPath, name), newLesson);
        // Delete old record and update references if name has changed
        if (previousName !== '' && previousName !== name) {
          batch.delete(doc(db, lessonsPath, previousName));
        }

        //Check if Constraints Apply to All
        if (selectedForDaysBetweenLessons.length > 0) {
          lessons
            .filter((lesson) => selectedForDaysBetweenLessons.includes(lesson.name))
            .forEach((lesson) =>
              batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                minGapLessons: selectedMinGapLessons,
                maxGapLessons: selectedMaxGapLessons,
                updatedAt: new Date(),
              })
            );
        }
        // if (selectedTimeSplit) {
        //   lessons.forEach((lesson) =>
        //     batch.update(doc(db, lessonsPath + '/' + lesson.name), {
        //       lessonsWeek: selectedLessonsWeek,
        //       split: selectedSplit,
        //       updatedAt: new Date(),
        //     })
        //   );
        // }
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
        batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
        batch
          .commit()
          .then(() => {
            updateLesson
              ? toast.success(selectedSubject[0] + t(' updated sucessfully'))
              : toast.success(selectedSubject[0] + t(' created sucessfully'));
            setLesson(undefined);
          })
          .catch(() => {
            toast.error(t('An error has ocurred'));
            setLesson(undefined);
          });
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      }
    }
  }

  const formik = useFormik<ILesson>({
    initialValues: lesson,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Required field'),
      // short: Yup.string().required('Required field'),
    }),
    onSubmit: async (values) => {
      submitForm();
    },
  });

  const [selectedColor, setSelectedColor] = React.useState<string>(formik.initialValues.color);
  const [selectedPhotoURL, setSelectedPhotoURL] = React.useState<string>(formik.initialValues.photoURL);

  const [selectedClassNum, setSelectedClassNum] = React.useState<number>(formik.initialValues.classNum);

  const [selectedClasses, setSelectedClasses] = React.useState<string[]>(formik.initialValues.classes);

  const [copyLessonOpen, setCopyLessonOpen] = useState(false);
  const [copyLessonConfirmed, setCopyLessonConfirmed] = useState(false);

  function checkCopyLessonConfirmation() {
    if (copyLessonConfirmed) {
      setCopyLessonConfirmed(false);
      copy();
    }
  }
  checkCopyLessonConfirmation();

  function copy() {
    if (userPlan === FREE && lessons.length + 1 > planFeatures.free.maxLessons) {
      setLessonNumLimitReached(true);
    } else if (userPlan === BASIC && lessons.length + 1 > planFeatures.basic.maxLessons) {
      setLessonNumLimitReached(true);
    } else {
      setIsLoadingCopy(true);
      try {
        //Validation and newName adjustments
        const newClass = '' + localStorage.getItem('copyClass');
        if (newClass === '') toast.error(t('An error has ocurred'));
        const newName: string = lesson.subject[0].trim() + SUBJECT_CLASS_SEPARATOR + newClass;
        if (lessons.map((lesson) => lesson.name).includes(newName)) {
          toast.error(newName + t(' already registered'));
          return;
        }
        const newLesson = { ...lesson };
        newLesson.name = newName;
        newLesson.classes = [newClass];
        newLesson.createdAt = new Date();
        newLesson.updatedAt = new Date();
        setDoc(doc(db, lessonsPath, newName), newLesson).then(() => {
          //Set all solutions to outdated
          solutions.forEach((solution) => {
            updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
              status: 'Outdated',
            });
          });
          toast.success(lesson.subject[0].trim() + t(' copied sucessfully'));
          setLesson(undefined);
        });
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      }
    }
  }

  const [selectedSubject, setSelectedSubject] = React.useState<string[]>(formik.initialValues.subject);
  const [selectedLessonsWeek, setSelectedLessonsWeek] = React.useState<number>(formik.initialValues.lessonsWeek);
  const handleChangeLessonsWeek = (event: SelectChangeEvent) => {
    setSelectedLessonsWeek(event.target.value as unknown as number);
    setSelectedSplit([]);
  };

  const [selectedSplit, setSelectedSplit] = React.useState<string[]>(formik.initialValues.split);
  const [selectedGapLessons, setSelectedGapLessons] = React.useState(false);
  const [selectedMinGapLessons, setSelectedMinGapLessons] = React.useState<number>(formik.initialValues.minGapLessons);
  const [selectedMaxGapLessons, setSelectedMaxGapLessons] = React.useState<number>(formik.initialValues.maxGapLessons);
  const [selectedTeacherNum, setSelectedTeacherNum] = React.useState<number>(formik.initialValues.teacherNum);
  const [selectedTeachers, setSelectedTeachers] = React.useState<string[]>(formik.initialValues.teachers);
  const [selectedRoomNum, setSelectedRoomNum] = React.useState<number>(formik.initialValues.roomNum);
  const [selectedRooms, setSelectedRooms] = React.useState<string[]>(formik.initialValues.rooms);
  const [selectedSimultaneousWith, setSelectedSimultaneousWith] = React.useState<string[]>(
    formik.initialValues.simultaneousWith
  );
  const [selectedNotSimultaneousWith, setSelectedNotSimultaneousWith] = React.useState<string[]>(
    formik.initialValues.notSimultaneousWith
  );
  const [selectedOccurBefore, setSelectedOccurBefore] = React.useState<string[]>(formik.initialValues.occurBefore);
  const [selectedOccurBeforeMinDays, setSelectedOccurBeforeMinDays] = React.useState<number>(
    formik.initialValues.occurBeforeMinDays
  );
  const handleChangeOccurBeforeMinDays = (event: SelectChangeEvent) => {
    setSelectedOccurBeforeMinDays(event.target.value as unknown as number);
  };
  //#endregion

  const copyClassOptions = resources
    .filter(
      (res) =>
        res.type === 'class' &&
        !selectedClasses.includes(res.name) &&
        lessons
          .filter((lesson) => lesson.subject[0] === selectedSubject[0])
          .map((les) => {
            return les.classes[0];
          })
          .indexOf(res.name) === -1
    )
    .map((res) => {
      return res.name;
    });

  const [selectedCopyClass, setSelectedCopyClass] = React.useState<string>(
    copyClassOptions.length === 0 ? '' : copyClassOptions[0]
  );

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  //#region States for and Tables

  const [selectedCells, setSelectedCells] = React.useState<readonly string[]>(formik.initialValues.predefinedTimes);

  const cells = useMemo(() => {
    let updatedCells: string[] = [];
    times.forEach((_, t_index) => {
      days.forEach((_, d_index) => {
        updatedCells.push(d_index + DAY_TIME_SEPARATOR + t_index);
      });
    });
    return updatedCells;
  }, [times, days]);

  const isSelected = (name: string) => selectedCells.indexOf(name) !== -1;

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelectedCells = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelectedCells.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelectedCells = newSelectedCells.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelectedCells);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let nameTime = name.split(DAY_TIME_SEPARATOR)[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const selectedIndex = selectedCells.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelectedCells(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedCells.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
    let nameTime = name.split(DAY_TIME_SEPARATOR)[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCells, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCells.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selectedCells.length - 1) {
      newSelected = newSelected.concat(selectedCells.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedCells.slice(0, selectedIndex), selectedCells.slice(selectedIndex + 1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelectedCells(newSelected);
  };

  let { contSelectedAtDay, contSelectedAtTime, contSelected } = useMemo(() => {
    const contSelectedAtDay: number[] = [];
    const contSelectedAtTime: number[] = [];
    let contSelected = 0;

    days.forEach((_) => {
      contSelectedAtDay.push(0);
    });

    times.forEach((_) => {
      contSelectedAtTime.push(0);
    });

    cells.forEach((element) => {
      const cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      const selectedIndex = selectedCells.indexOf(element);

      if (selectedIndex !== -1) {
        contSelectedAtDay[parseInt(cellDay)]++;
        contSelectedAtTime[parseInt(cellTime)]++;
        contSelected++;
      }
    });

    return {
      contSelectedAtDay,
      contSelectedAtTime,
      contSelected,
    };
  }, [days, times, cells, selectedCells]);

  const rowCount: number = times.length;
  const colCount: number = days.length;
  const allCount: number = days.length * times.length;

  //#endregion

  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);

  const [jointClassesConfirmationOpen, setJointClassesConfirmationOpen] = useState(false);
  const [jointClassesConfirmed, setJointClassesConfirmed] = useState(false);
  const checkJointClassesConfirmed = () => {
    if (jointClassesConfirmed) {
      setJointClassesConfirmed(false);
      submitForm();
    }
  };
  checkJointClassesConfirmed();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const checkDeleteConfirmed = () => {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      deleteDoc(doc(db, lessonsPath, lesson.name));
      toast.success(lesson.name + ' deleted sucessfully!');
      setLesson(undefined);
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    }
  };
  checkDeleteConfirmed();

  const [toAllConfirmationOpen, setToAllConfirmationOpen] = useState(false);
  const [toAllConfirmed, setToAllConfirmed] = useState(false);

  const checkToAllConfirmation = () => {
    if (toAllConfirmed) {
      setToAllConfirmed(false);
      submitForm();
    }
  };
  checkToAllConfirmation();

  const [valueClassesSelectOpen, setValueClassesSelectOpen] = useState(false);
  const [valueTeachersSelectOpen, setValueTeachersSelectOpen] = useState(false);
  const [valueRoomsSelectOpen, setValueRoomsSelectOpen] = useState(false);

  const splitOptions: string[] = useMemo(() => {
    const numSplitOptions: number[][] = [];

    //#region Split options processing
    // Source: https://acervolima.com/encontre-todas-as-combinacoes-que-somam-um-determinado-numero/
    // Javascript program to find out
    // all combinations of positive
    // numbers that add upto given
    // number

    /* arr - array to store the
      combination
      index - next location in array
      num - given number
      reducedNum - reduced number */
    function findCombinationsUtil(
      numSplitOptions: number[][],
      arr: number[],
      index: number,
      num: number,
      reducedNum: number
    ) {
      // Base condition
      if (reducedNum < 0) return;

      // If combination is
      // found, print it
      if (reducedNum === 0) {
        let numSplitOption = [];
        for (let i = 0; i < index; i++) numSplitOption.push(arr[i]);
        numSplitOptions.push(numSplitOption);
        return;
        // let splitOption = '';
        // for (let i = 0; i < index; i++) splitOption = splitOption + '' + arr[i] + '-';
        // splitOption = splitOption.substring(0, splitOption.length - 1);
        // splitOptions.push(splitOption);
        // return;
      }

      // Find the previous number
      // stored in arr[]. It helps
      // in maintaining increasing
      // order
      let prev = index === 0 ? 1 : arr[index - 1];

      // note loop starts from
      // previous number i.e. at
      // array location index - 1
      for (let k = prev; k <= num; k++) {
        // next element of
        // array is k
        arr[index] = k;

        // call recursively with
        // reduced number
        findCombinationsUtil(numSplitOptions, arr, index + 1, num, reducedNum - k);
      }
    }

    /* Function to find out all
    combinations of positive
    numbers that add upto given
    number. It uses findCombinationsUtil() */
    function findCombinations(numSplitOptions: number[][], n: number) {
      // array to store the combinations
      // It can contain max n elements
      let arr: number[] = [];

      // find all combinations
      findCombinationsUtil(numSplitOptions, arr, 0, n, n);
    }
    // Driver Code
    findCombinations(numSplitOptions, selectedLessonsWeek);

    // Filter to remove invalid split options and conversion to string
    const splitOptions: string[] = [];
    for (let i = 0; i < numSplitOptions.length; ++i) {
      const res = numSplitOptions[i].filter((num) => num <= times.length);
      if (res.length === numSplitOptions[i].length) {
        // add splitOption as string
        let strSplitOption = '';
        numSplitOptions[i].forEach((num) => (strSplitOption = strSplitOption + num + '-'));
        strSplitOption = strSplitOption.substring(0, strSplitOption.length - 1);
        splitOptions.push(strSplitOption);
      }
    }

    return splitOptions;
  }, [selectedLessonsWeek, times]);

  //#endregion

  function validateInput(): boolean {
    const lessonNames: string[] = lessons.map((lesson) => lesson.name);
    const name = selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses.toString();
    if (previousName !== name && lessonNames.includes(name)) {
      toast.error(`${selectedSubject[0]} ${t('is already offered to class')} ${selectedClasses.toString()}`);
      return false;
    } else if (selectedSubject.length === 0) {
      toast.error(t('Subject cannot be empty'));
      return false;
      // } else if (selectedSplit === '1-1' && +selectedLessonsWeek !== 2) {
    } else if (+selectedSplit.length === 0) {
      toast.error(t('Split Options cannot be empty'));
      return false;
    } else if (selectedClasses.length < selectedClassNum) {
      toast.error(t('Number of selected classes is smaller than required num. of joint classes'));
      return false;
    } else if (selectedTeachers.length < selectedTeacherNum) {
      toast.error(t('Number of selected teachers is smaller than required num. of joint teachers'));
      return false;
    } else if (selectedRooms.length < selectedRoomNum) {
      toast.error(t('Number of selected rooms is smaller than required num. of joint rooms'));
      return false;
    } else if (selectedClasses.length !== 0 && selectedClassNum === 0) {
      toast.error(t('Required num of classes is 0 but you provided class(es) to be assigned'));
      return false;
    } else if (selectedTeachers.length !== 0 && selectedTeacherNum === 0) {
      toast.error(t('Required num of teachers is 0 but you provided teacher(s) to be assigned'));
      return false;
    } else if (selectedRooms.length !== 0 && selectedRoomNum === 0) {
      toast.error(t('Required num of rooms is 0 but you provided room(s) to be assigned'));
      return false;
    } else if (selectedOccurBefore.length !== 0 && selectedOccurBeforeMinDays === 0) {
      toast.error(
        t('You must select how many days, at least, the lessons of ') +
          name +
          t('should occur before any lesson of ') +
          selectedOccurBefore.join(', ')
      );
      return false;
    } else if (selectedOccurBefore.length === 0 && selectedOccurBeforeMinDays > 0) {
      toast.error(
        t('You must select the lesson(s) that require ') +
          name +
          t('to occur ') +
          selectedOccurBeforeMinDays +
          t('days before')
      );
      return false;
    } else {
      return true;
    }
  }

  if (file?.roomsSetting === 0) lessonCreationSteps.splice(4, 1);

  const handleNavToUpgradePlan = () => {
    setLessonNumLimitReached(false);
    history.push('/settings');
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  useEffect(() => {
    if (selectedClassNum === 0) setSelectedClasses([]);
  }, [selectedClassNum]);

  useEffect(() => {
    if (selectedTeacherNum === 0) setSelectedTeachers([]);
  }, [selectedTeacherNum]);

  useEffect(() => {
    if (selectedRoomNum === 0) setSelectedRooms([]);
  }, [selectedRoomNum]);

  function handleSave() {
    setHasAttemptedToSave(true);
    if (!validateInput()) return;
    if (selectedForDaysBetweenLessons.length > 0) setToAllConfirmationOpen(true);
    else if (!jointClassesConfirmed && selectedClasses.length > 1) setJointClassesConfirmationOpen(true);
    else submitForm();
  }

  function getApplyToMoreConfirmationMessage() {
    return (
      <Stack>
        <Typography paddingBottom={2}>{`${t('The following constraints will apply to other lessons')}:`}</Typography>
        {selectedForDaysBetweenLessons.length > 0 && (
          <Typography>{` - ${t('Days between Meetings')}: ${selectedForDaysBetweenLessons.join(', ')}`}</Typography>
        )}
        <Typography paddingTop={2}>{`${t('Are you sure?')}`}</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Onboarding run={runLessonCreationOnboarding} steps={lessonCreationSteps} storageFlagKey={LESSON_CREATION_KEY} />
      <PlanInfoDialog
        open={showPlanDialog}
        positiveLabel={t('Upgrade plan')}
        negativeLabel={t('Back')}
        positiveAction={handleNavToUpgradePlan}
        negativeAction={() => {
          setShowPlanDialog(false);
        }}
        title={
          <>
            <WorkspacePremium sx={{ fontSize: 20, color: customColors.basic, marginRight: 1 }} />
            {t('Basic tier feature')}
          </>
        }
        message={t(
          'This feature is only available in the Basic and Premium plans. Please upgrade your plan to start using now!'
        )}
      />
      <DraggableDialog
        open={jointClassesConfirmationOpen}
        setOpen={setJointClassesConfirmationOpen}
        setConfirmed={setJointClassesConfirmed}
        title={t('Warning')}
        message={`${t('Classes')} ${selectedClasses.toString()} ${t('will have lessons of')} ${selectedSubject[0]} ${t(
          'with the same teacher and room AT THE SAME TIME. Are you sure?'
        )}`}
      />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={lesson.name + t(' will be permanently deleted from this file. Are you sure?')}
      />
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_lesson')}
        ></VideoPlayerDialog>
      )}
      {lessonNumLimitReached && (
        <PlanInfoDialog
          open={lessonNumLimitReached}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Back')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setLessonNumLimitReached(false);
          }}
          title={t('Plan limit reached')}
          message={t(
            'You have reached the limit of lessons for this file in your plan! Please consider upgrading your plan to create more files.'
          )}
        />
      )}
      {toAllConfirmationOpen && (
        <DraggableDialog
          open={toAllConfirmationOpen}
          setOpen={setToAllConfirmationOpen}
          setConfirmed={setToAllConfirmed}
          title={t('Warning')}
          message={getApplyToMoreConfirmationMessage()}
        />
      )}
      {copyLessonOpen && (
        <ValueSelectConfirmDialog
          open={copyLessonOpen}
          setOpen={setCopyLessonOpen}
          setConfirmed={setCopyLessonConfirmed}
          value={selectedCopyClass}
          setValue={setSelectedCopyClass}
          valueOptions={copyClassOptions}
          title={t('Copy Lesson')}
          selectLabel={t('Class')}
          message={`${t('Select the')} ${file?.studentsSetting !== 0 ? t('class') : t('student')} ${t(
            'to which the copied lesson will be taught:'
          )}`}
        />
      )}
      {valueClassesSelectOpen && (
        <ValueSelectDialog
          open={valueClassesSelectOpen}
          setOpen={setValueClassesSelectOpen}
          // setConfirmed={setValueClassesSelectConfirmed}
          value={selectedClassNum}
          setValue={setSelectedClassNum}
          valueOptions={Array.from({ length: classes.length + 1 }, (x, i) => i)}
          title={`${file?.studentsSetting !== 0 ? t('Joint Classes') : t('Joint Students')}`}
          message={`${t('Select the number of')} ${file?.studentsSetting !== 0 ? t('classes') : t('students')} ${t(
            'that attend this lesson JOINTLY and AT THE SAME TIME:'
          )}`}
        />
      )}
      {valueTeachersSelectOpen && (
        <ValueSelectDialog
          open={valueTeachersSelectOpen}
          setOpen={setValueTeachersSelectOpen}
          // setConfirmed={setValueTeachersSelectConfirmed}
          value={selectedTeacherNum}
          setValue={setSelectedTeacherNum}
          valueOptions={Array.from({ length: teachers.length + 1 }, (x, i) => i)}
          title={t('Joint Teachers')}
          message={t('Select the number of teachers that teach this lesson JOINTLY and AT THE SAME TIME:')}
        />
      )}
      {valueRoomsSelectOpen && (
        <ValueSelectDialog
          open={valueRoomsSelectOpen}
          setOpen={setValueRoomsSelectOpen}
          // setConfirmed={setValueTeachersSelectConfirmed}
          value={selectedRoomNum}
          setValue={setSelectedRoomNum}
          valueOptions={Array.from({ length: rooms.length + 1 }, (x, i) => i)}
          title={t('Joint Rooms')}
          message={t('Select the number of rooms that host this lesson JOINTLY and AT THE SAME TIME:')}
        />
      )}
      <DraggableAvatarPicker
        open={avatarPickerOpen}
        setOpen={setAvatarPickerOpen}
        selectedColor={selectedColor}
        selectedPhotoURL={selectedPhotoURL}
        setSelectedColor={setSelectedColor}
        setSelectedPhotoURL={setSelectedPhotoURL}
        resource={lesson}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" paddingBottom={8}>
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item container xs={12} spacing={1} padding={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                    <Tooltip title={t('Back')}>
                      <IconButton
                        style={{ height: 24, width: 24, marginRight: 4 }}
                        color="primary"
                        onClick={() => setLesson(undefined)}
                      >
                        <ArrowBack style={{ height: 20, width: 20 }} />
                      </IconButton>
                    </Tooltip>
                    {updateLesson ? t('Update ') + t('lesson') : t('New lesson')}
                  </Typography>
                  <Divider color={theme.palette.primary.main} />
                </Grid>
                <HelpButton t={t} onClick={() => setVideoPlayerDialogOpen(true)} />
                {updateLesson ? (
                  <>
                    <Grid item xs={2} md={2}>
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => setDeleteConfirmationOpen(true)}
                        color="error"
                        fullWidth
                      >
                        {t('Delete')}
                      </Button>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <LoadingButton
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => setCopyLessonOpen(true)}
                        color="primary"
                        fullWidth
                        loadingPosition="start"
                        loading={isLoadingCopy}
                      >
                        {t('Copy')}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <LoadingButton
                        className={'update-button'}
                        variant="contained"
                        size="small"
                        disabled={readOnly}
                        onClick={handleSave}
                        color="primary"
                        fullWidth
                        loadingPosition="start"
                        loading={isLoading}
                      >
                        {t('Update')}
                      </LoadingButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={3} md={2}>
                    <LoadingButton
                      className={'save-button'}
                      variant="contained"
                      size="small"
                      disabled={readOnly}
                      onClick={handleSave}
                      color="primary"
                      fullWidth
                      loadingPosition="start"
                      loading={isLoading}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Participants')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails className="lesson-definition">
                    <Grid item container xs={12} spacing={1} padding={1}>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                      <Grid className={'lesson-class'} item xs={10} sm={8}>
                        <FormControl fullWidth size="small">
                          <MultipleSelectChip
                            single={true}
                            label={t('Subject')}
                            names={subjects_}
                            personName={selectedSubject}
                            chipColor={subjects.find((sub) => sub.name === selectedSubject[0])?.color}
                            setPersonName={setSelectedSubject}
                            InputProps={{
                              autoFocus: true, // Use the autoFocus attribute
                            }}
                          ></MultipleSelectChip>
                        </FormControl>
                        {getSubjectHelperText(t, selectedSubject, hasAttemptedToSave)}
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip placement="right" title={t('Number of subjects taugth in this lesson')}>
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() =>
                              toast.warning(t('You cannot change the number of subjects taugth in a lesson'))
                            }
                            selected
                            sx={{ textTransform: 'none' }}
                          >
                            <Book sx={{ marginRight: 1 }} />
                            {`×${1}`}
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={1} padding={1}>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                      <Grid className={'lesson-class'} item xs={10} sm={8}>
                        <FormControl fullWidth size="small">
                          <MultipleSelectChip
                            single={selectedClassNum === 1}
                            label={file?.studentsSetting !== 0 ? t('Class') : t('Students')}
                            names={classes}
                            personName={selectedClasses}
                            setPersonName={setSelectedClasses}
                            disabled={selectedClassNum === 0}
                          ></MultipleSelectChip>
                        </FormControl>
                        {getClassHelperText(t, selectedClassNum, selectedClasses, hasAttemptedToSave)}
                      </Grid>
                      <Grid className={'joint-classes'} item xs={2}>
                        <Tooltip
                          placement="right"
                          title={`${
                            file?.studentsSetting !== 0
                              ? t('Number of classes that attend this lesson together')
                              : t('Number of students that attend this lesson together')
                          }`}
                        >
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => setValueClassesSelectOpen(true)}
                            selected={selectedClassNum !== 0}
                            sx={{ textTransform: 'none' }}
                          >
                            <PeopleAlt sx={{ marginRight: 1 }} />
                            {`×${selectedClassNum}`}
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1} padding={1}>
                      {/* Teachers */}
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                      <Grid className={'lesson-teacher'} item xs={10} sm={8}>
                        <FormControl fullWidth size="small">
                          <MultipleSelectChip
                            single={false}
                            label={`${file?.teachersSetting === 2 ? t('Teacher') : t('Teacher Options')}`}
                            names={teachers}
                            // chipColor={resources.find((res) => res.name === selectedTeachers[0])?.color}
                            personName={selectedTeachers}
                            setPersonName={setSelectedTeachers}
                            disabled={selectedTeacherNum === 0}
                          ></MultipleSelectChip>
                        </FormControl>
                        {getTeacherHelperText(t, selectedTeacherNum, selectedTeachers, hasAttemptedToSave)}
                      </Grid>
                      <Grid className={'joint-teachers'} item xs={2}>
                        <Tooltip placement="right" title={t('Number of teachers that teach this lesson together')}>
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            selected={selectedTeacherNum !== 0}
                            onClick={() => setValueTeachersSelectOpen(true)}
                            sx={{ textTransform: 'none' }}
                          >
                            <CoPresent sx={{ marginRight: 1 }} />
                            {`×${selectedTeacherNum}`}
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1} padding={1}>
                      {/* Rooms */}
                      {file?.roomsSetting !== 0 && (
                        <Grid item xs={false} sm={1}>
                          <></>
                        </Grid>
                      )}
                      {file?.roomsSetting !== 0 && (
                        <Grid className={'lesson-room'} item xs={10} sm={8}>
                          <FormControl fullWidth size="small">
                            <MultipleSelectChip
                              label={t('Room Options')}
                              names={rooms}
                              personName={selectedRooms}
                              setPersonName={setSelectedRooms}
                              disabled={selectedRoomNum === 0}
                            ></MultipleSelectChip>
                          </FormControl>
                          {getRoomHelperText(t, selectedRoomNum, selectedRooms)}
                        </Grid>
                      )}
                      {file?.roomsSetting !== 0 && (
                        <Grid item xs={2}>
                          <Tooltip placement="right" title={t('Number of rooms that host this lesson jointly')}>
                            <ToggleButton
                              value="check"
                              fullWidth
                              size="small"
                              color="primary"
                              selected={selectedRoomNum !== 0}
                              onClick={() => setValueRoomsSelectOpen(true)}
                              sx={{ textTransform: 'none' }}
                            >
                              <MapsHomeWork sx={{ marginRight: 1 }} />
                              {` ×${selectedRoomNum}`}
                            </ToggleButton>
                          </Tooltip>
                        </Grid>
                      )}
                      {file?.roomsSetting !== 0 && (
                        <Grid item xs={false} sm={1}>
                          <></>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Distribution of class meetings')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails className="distribution">
                    {/* Lessons per Week and Split */}
                    <Grid item container xs={12} spacing={1} padding={1} flexDirection={'row'}>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                      <Grid item container xs={6} sm={5} justifyContent={'flex-end'}>
                        <Tooltip placement="top-end" title={t('Sets the required number of times for this lesson')}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="days-select-label">{t('Times per Week')}</InputLabel>
                            <Select
                              size="small"
                              labelId="lessons-week-select-label"
                              id="lessons-week-select"
                              defaultValue={'' + selectedLessonsWeek}
                              onChange={handleChangeLessonsWeek}
                              label={t('Times per Week')}
                            >
                              {Array.from({ length: days.length * times.length + 1 }).map((_, index) => {
                                return (
                                  <MenuItem key={`lessonsWeek_${index}`} value={`${index}`}>
                                    {index === 0 ? `↓` : index}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} sm={5} className="split-options">
                        <Tooltip
                          placement="right-end"
                          title={
                            <div>
                              {t('Sets the way the class meetings of this lesson must be divided')}
                              <hr />
                              {t(
                                'For example, 1-1-2 means two single-time (1) class meetings and one double-time class meeting (2). You can set several suitable options:'
                              )}
                              <p />
                              <img
                                src={'/img/examples/splitLesson.png'}
                                width={'100%'}
                                alt={t('Example of Split option')}
                              />
                            </div>
                          }
                        >
                          <MultipleSelectChip
                            single={false}
                            label={t('Split Options')}
                            names={splitOptions}
                            personName={selectedSplit}
                            setPersonName={setSelectedSplit}
                          ></MultipleSelectChip>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={false} sm={1}>
                        <></>
                      </Grid>
                      {/* Gap between Lessons */}
                      <Grid item xs={12} marginTop={4}>
                        <MinMaxSlider
                          name={selectedSubject[0]}
                          leftIcon={<FormatLineSpacing sx={{ transform: 'rotate(90deg)', marginRight: 1 }} />}
                          label={t('Days between Meetings')}
                          labelHelper={t('days between each of its class meetings')}
                          tooltipComponent={
                            <div>
                              {t('Limits the number of days between class meetings of this lesson')}
                              <hr />
                              {`${t(
                                'For example, if Min is set to 2, there will be at least 2 days between the class meetings of this lesson, hence if a class occurs on Tuesday, another class of this lesson can only occur on Thursday or Friday:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/daysBetweenLessons.png'}
                                width={'100%'}
                                alt={t('Example of Days between Meetings constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinGapLessons}
                          setSelectedMin={setSelectedMinGapLessons}
                          selectedMax={selectedMaxGapLessons}
                          setSelectedMax={setSelectedMaxGapLessons}
                          possibleMin={0}
                          possibleMax={days.length - 1}
                          tooltipApplyToAll={t('Apply to all lessons')}
                          selectedMore={selectedForDaysBetweenLessons}
                          setSelectedMore={setSelectedForDaysBetweenLessons}
                          options={lessons
                            .filter(
                              (lesson) =>
                                !selectedForDaysBetweenLessons.includes(lesson.name) &&
                                lesson.name !== subjects[0] + SUBJECT_CLASS_SEPARATOR + classes[0]
                            )
                            .map((res) => res.name)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion className="advanced-lessons">
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Advanced')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                      <Box sx={{ width: '100%' }}>
                        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
                          <Tab label={t('Predefined Times')} />
                          <Tab className={'relations'} label={t('Relations')} />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={selectedTab} index={0}>
                        <TableContainer className={'predefined-times'} component={Paper}>
                          <Table size="small" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected === allCount}
                                    size="small"
                                    color="primary"
                                    onClick={(event) => {
                                      if (userPlan === FREE) {
                                        setShowPlanDialog(true);
                                        return;
                                      }
                                      handleSelectAllClick(event, '**');
                                    }}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                        size="small"
                                        color="primary"
                                        onClick={(event) => {
                                          if (userPlan === FREE) {
                                            setShowPlanDialog(true);
                                            return;
                                          }
                                          handleSelectAllDayClick(event, d_index + ':*');
                                        }}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime[t_index] === colCount}
                                        size="small"
                                        color="primary"
                                        onClick={(event) => {
                                          if (userPlan === FREE) {
                                            setShowPlanDialog(true);
                                            return;
                                          }
                                          handleSelectAllTimeClick(event, '*:' + t_index);
                                        }}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected('' + d_index + DAY_TIME_SEPARATOR + t_index);
                                      return (
                                        <TableCell key={`cell_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}_${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="primary"
                                            onClick={(event) => {
                                              if (userPlan === FREE) {
                                                setShowPlanDialog(true);
                                                return;
                                              }
                                              handleClick(event, '' + d_index + DAY_TIME_SEPARATOR + t_index);
                                            }}
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            {isItemSelected ? <Lock /> : <LockOpen />}
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={selectedTab} index={1}>
                        {/* Relations */}
                        {/* Simultaneous With */}
                        <Grid container spacing={2} paddingTop={2}>
                          <Grid container item spacing={1}>
                            <Grid item xs={1}>
                              <Tooltip
                                title={
                                  <div>
                                    {t('Enforces this lesson to occur at the same times of selected lessons')}
                                    <hr />
                                    {t(
                                      'This option is well suited to schedule optative subjects. For example, you can enforce lessons of Spanish and French to occur at the same time'
                                    )}
                                    {/* <p />
                          <img
                            src={'/img/examples/workingTimes.png'}
                            width={'100%'}
                            alt={t('Example of Working Times constraint')}
                          /> */}
                                  </div>
                                }
                              >
                                <ToggleButton
                                  value="check"
                                  fullWidth
                                  size="small"
                                  color="primary"
                                  style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                  selected={selectedSimultaneousWith.length === 0 ? false : true}
                                >
                                  <Link />
                                </ToggleButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={11} sm={10}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Simultaneous with')}
                                  names={lessons_.filter(
                                    (lesson) =>
                                      lesson !== selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                  )}
                                  personName={selectedSimultaneousWith}
                                  setPersonName={setSelectedSimultaneousWith}
                                  onChangeFn={
                                    userPlan === FREE
                                      ? () => {
                                          setShowPlanDialog(true);
                                        }
                                      : undefined
                                  }
                                ></MultipleSelectChip>
                              </FormControl>
                              {selectedSimultaneousWith.length > 0 && <Typography></Typography>}
                              {getSimultaneousWithHelperText(
                                t,
                                selectedSubject.length > 0 && selectedClasses.length > 0
                                  ? selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                  : '',
                                selectedSimultaneousWith
                              )}
                            </Grid>
                            <Grid item xs={false} sm={1}>
                              <></>
                            </Grid>
                          </Grid>

                          {/* Not Simultaneous With */}
                          <Grid container item spacing={1}>
                            <Grid item xs={1} sm={1}>
                              <Tooltip
                                title={
                                  <div>
                                    {t('Enforces this lesson to occur at different times of selected lessons')}
                                    <hr />
                                    {t(
                                      'This option is well suited to couple of teachers who have children. For example, you can enforce lessons of John to not overlap with lessons of Mary'
                                    )}
                                  </div>
                                }
                              >
                                <ToggleButton
                                  value="check"
                                  fullWidth
                                  size="small"
                                  color="primary"
                                  style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                  selected={selectedNotSimultaneousWith.length === 0 ? false : true}
                                >
                                  <LinkOff />
                                </ToggleButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={11} sm={10}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Not Simultaneous with')}
                                  names={lessons_.filter(
                                    (lesson) =>
                                      lesson !== selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                  )}
                                  personName={selectedNotSimultaneousWith}
                                  setPersonName={setSelectedNotSimultaneousWith}
                                  onChangeFn={
                                    userPlan === FREE
                                      ? () => {
                                          setShowPlanDialog(true);
                                        }
                                      : undefined
                                  }
                                ></MultipleSelectChip>
                              </FormControl>
                              {getNotSimultaneousWithHelperText(
                                t,
                                selectedSubject.length > 0 && selectedClasses.length > 0
                                  ? selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                  : '',
                                selectedNotSimultaneousWith
                              )}
                            </Grid>
                            <Grid item xs={false} sm={1}>
                              <></>
                            </Grid>
                          </Grid>

                          {/* Occur Before */}
                          <Grid container item spacing={1}>
                            <Grid item xs={1} sm={1}>
                              <Tooltip
                                title={
                                  <div>
                                    {t('Enforces this lesson to occurr before the selected lessons')}
                                    <hr />
                                    {t(
                                      'This option is well suited to schedule theorical lessons before practice. You can set the Min of days separating them. For example, you can enforce theorical lessons of Chemestry to occur at least 2 days before the practical lessons'
                                    )}
                                  </div>
                                }
                              >
                                <ToggleButton
                                  value="check"
                                  fullWidth
                                  size="small"
                                  color="primary"
                                  style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                  selected={selectedOccurBefore.length === 0 ? false : true}
                                >
                                  <BrowseGallery />
                                </ToggleButton>
                              </Tooltip>
                            </Grid>
                            <Grid item container xs={11} spacing={1}>
                              <Grid item xs={9} sm={9}>
                                <FormControl fullWidth size="small">
                                  <MultipleSelectChip
                                    label={t('Occur before')}
                                    names={lessons_.filter(
                                      (lesson) =>
                                        lesson !== selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                    )}
                                    personName={selectedOccurBefore}
                                    setPersonName={setSelectedOccurBefore}
                                    onChangeFn={
                                      userPlan === FREE
                                        ? () => {
                                            setShowPlanDialog(true);
                                          }
                                        : undefined
                                    }
                                  ></MultipleSelectChip>
                                </FormControl>
                              </Grid>
                              <Grid item xs={2} sm={2}>
                                <FormControl fullWidth size="small">
                                  <InputLabel id="days-select-label">{t('Min Days Gap')}</InputLabel>
                                  <Select
                                    size="small"
                                    labelId="days-gap-label"
                                    id="days-gap-select"
                                    defaultValue={'' + selectedOccurBeforeMinDays}
                                    onChange={handleChangeOccurBeforeMinDays}
                                    label={t('Min Days Gap')}
                                    sx={{
                                      color: +selectedOccurBeforeMinDays !== 0 ? 'default' : '#fff',
                                    }}
                                  >
                                    {Array.from({ length: days.length }).map((_, index) => {
                                      return (
                                        <MenuItem key={`lessonsWeek_${index}`} value={`${index}`}>
                                          {index === 0 ? `↓` : index}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {getOccurBeforeHelperText(
                                t,
                                selectedSubject.length > 0 && selectedClasses.length > 0
                                  ? selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                  : '',
                                selectedOccurBefore,
                                selectedOccurBeforeMinDays
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </>
  );
}
