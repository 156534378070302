import { useTranslation } from 'react-i18next';
import AccordionTableDescription from 'components/AccordionTableDescription';
import { Accordion, AccordionSummary, DialogContentText, AccordionDetails, Link } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export default function ImportLessonsAccordion() {
  const { t } = useTranslation();

  function createData(name: string, description: string) {
    return { name, description };
  }

  const subjectRows = [createData(t('Chemestry'), ''), createData(t('Calculus I'), '')];

  const timesWeekRows = [
    createData('2', t('Represents 2 times over the week')),
    createData('1', t('Means only one time over the week')),
  ];

  const splitRows = [
    createData('1-1', t('Ensures this lesson will be split into two single classes')),
    createData('1-1-2', t('Represents that this lesson can be split into two single lessons or one double lesson')),
  ];

  const daysBetweenLessonsRows = [
    createData('2-3', t('Represents min 2 and max 3 days between class meetings')),
    createData('1-', t('Means min 1 day between lessons and max unrestricted')),
    createData('-1', t('Means min unrestricted and max 1 days between class meetings')),
  ];

  const numResourcesRows = [
    createData('1', t('Represents that a single class/teacher/room attends/lectures/hosts this lesson (as usual)')),
    createData('2', t('Means that 2 classes/teachers/rooms will attend/lecture/host this lesson jointly')),
  ];

  const classRows = [createData(t('9th grade 902'), ''), createData(t('Computer Engineering 1'), '')];

  const teachersRows = [
    createData(t('John Pearson'), t('Represents that John is assigned to this lesson')),
    createData(
      t('George Fonseca+James Chan'),
      t('Represents that Optables can choose to assign either George or James to this lesson')
    ),
  ];

  const roomsRows = [
    createData(t('A103'), t('Represents that room A103 is assigned to host this lesson')),
    createData(
      t('LAB01+LAB02+LAB03'),
      t('Represents that Optables can choose to assign one of the labs 1, 2, or 3 to host this lesson')
    ),
  ];

  const predefinedTimesRows = [
    createData('1:1+3:2', t('Represents Monday (day 1) at the 1st and Wednesday (day 3) at the 2nd time')),
    createData('1:1', t('You can set only one time as predefined and let Optables choose the others')),
  ];

  const exampleRows = [
    createData(
      t('Times /Week'),
      t('Lessons of Computing and Society will occur 1 time per week. All others occur 2 times per week')
    ),
    createData(
      t('Split'),
      t(
        'Lessons of Computer Programming to both classes CE1 and IS1 can be split into single or double lessons. All others must be single'
      )
    ),
    createData(
      t('Days between Meetings'),
      t(
        'Lessons of Computer Programming will have at least 1 day between their classes (eg. Monday, and Tuesday). All others will have at least 2 and at most 3 days between class meetings (eg. Tuesday and Thursday)'
      )
    ),
    createData(
      t('Num of Teachers'),
      t(
        'Lesson of Chemestry must have 2 teachers lecturing together (for safety reasons). All other lessons are taught by a single teacher'
      )
    ),
    createData(
      t('Teachers'),
      t(
        'Lessons of Computer Programming and Calculus have two teacher options, meaning that one them will be assigned by Optables for each of these lessons. Note that Chemstry will assign the two teacher options jointly as required'
      )
    ),
    createData(
      t('Predefined Times'),
      t(
        'Lessons of Computer Programming to IS1 will occur on Tuesday (day 2) at the 4th time, and on Thursday (day 4) at the 3rd time'
      )
    ),
  ];

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Subject')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column cannot be empty and should contain the name of the subject. If it is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={subjectRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Times /Week')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>{t('This column is mandatory and imported as a number. Ex.:')}</DialogContentText>
          <AccordionTableDescription data={timesWeekRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Split')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column is imported as a set of numbers separated by a dash sign (-). Diferent acceptable split options are separated by a + sign. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={splitRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Days between Meetings')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column is imported as a pair of min-max values. Either min or max can be omitted if not constrained. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={daysBetweenLessonsRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Columns Num of Classes, Teachers, and Rooms')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column is imported as number, representing how many classes/teachers/rooms attend/lecture/host this lesson jointly. If left blank or unspecified, the default value is 1. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={numResourcesRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Class')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column is mandatory and should contain the name of the class that attends this subject. If the class is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={classRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Teachers')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column should contain the name of the teachers that can be assigned to lecture this lesson. Teacher option names are separated by a + sign. If the teacher is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={teachersRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Rooms')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column should contain the name of the rooms that can be assigned to host this lesson. Room option names are separated by a + sign. If the room is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={roomsRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Predefined Times')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('This column is imported as a set of timeslots (day:time) separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={predefinedTimesRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Example')}&nbsp;</DialogContentText>
          <Link href={t('/data/lessons-exampleEN.xlsx')} download>
            {'(download)'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'In this example, 6 lessons, 4 to class CE1 (Computer Engineering 1), and 2 to IS1 (Information Systems) will be imported:'
            )}
          </DialogContentText>
          <img
            src={t('img/examples/importLessonsEN.png')}
            width={'100%'}
            alt={t('Example of Import Lessons')}
            style={{ border: '1pt solid #bdbdbd', borderRadius: '4pt', padding: '4px' }}
          />
          <AccordionTableDescription data={exampleRows} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
