import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { doc, getDoc, updateDoc, writeBatch, collection, query, getDocs, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { db } from 'services';
import { IFile } from './File';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import FileCard from 'components/Card/FileCard';
import PlanInfoDialog from 'components/Dialog/PlanInfoDialog';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import FormDialog from 'components/Dialog/FormDialog';
import { IResource } from 'pages/Resources/Resource';
import { ISubject } from 'pages/Subjects/Subject';
import { ILesson } from 'pages/Lessons/utils/types';
import { ISolution } from 'pages/Solutions/Solution';
import MiniDrawerFiles from 'components/Drawer/MiniDrawerFiles';
import FileShareDialog from 'components/Dialog/FileShareDialog';
import { SharedWithMe } from 'contexts/SessionContext/SessionContext';
import SharedFileCard from 'components/Card/SharedFileCard';
import DemoProjectsDialog from 'components/Dialog/DemoProjectsDialog';
import { Grid, Typography } from '@mui/material';
import PageContainer from 'containers/PageContainer';
import { v4 as uuidv4 } from 'uuid';

const emptyFileWithoutId: IFile = {
  id: '',
  name: '',
  institution: '',
  year: new Date().getFullYear(),
  educationLevels: ['University'],
  country: 'Brazil',
  countryCode: 'BR',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  days: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
  times: ['07:00', '08:00', '09:00', '10:00', '11:00'],
  photoURL: '',

  numClasses: 0,
  numTeachers: 0,
  numRooms: 0,
  numSubjects: 0,
  numLessons: 0,
  numConstraints: 0,
  numSolutions: 0,

  timeslotsSet: false,

  showWorkloadClasses: false,
  showWorkloadTeachers: true,
  showWorkloadRooms: true,

  showWorkingDaysClasses: false,
  showWorkingDaysTeachers: true,
  showWorkingDaysRooms: false,

  showIdleWindowClasses: true,
  showIdleWindowTeachers: true,
  showIdleWindowRooms: true,

  showDailyWorkloadClasses: true,
  showDailyWorkloadTeachers: true,
  showDailyWorkloadRooms: false,

  showRestBetweenDaysClasses: false,
  showRestBetweenDaysTeachers: false,
  showRestBetweenDaysRooms: false,

  showRoomChangesDayClasses: false,
  showRoomChangesDayTeachers: false,
  showRoomChangesDayRooms: false,

  showConsecutiveTimesClasses: false,
  showConsecutiveTimesTeachers: false,
  showConsecutiveTimesRooms: false,

  showTravelTimeClasses: false,
  showTravelTimeTeachers: false,
  showTravelTimeRooms: false,

  showDistinctSubjectsClasses: false,
  showDistinctSubjectsTeachers: false,
  showDistinctSubjectsRooms: false,

  showForbiddenCombinationClasses: false,
  showForbiddenCombinationTeachers: false,
  showForbiddenCombinationRooms: false,

  studentsSetting: 1,
  teachersSetting: 1,
  roomsSetting: 1,

  sharedWith: [],
};

export default function Files() {
  const { t } = useTranslation();
  const history = useHistory();
  const { authUser, user, files, sharedWithMe, setOwnerEmail, setShareMode } = useContext(SessionContext);
  const filesPath = `users/${authUser?.email}/files`;
  const userDoc = doc(db, `users/${authUser?.email}`);

  const [copyNameDialogOpen, setCopyNameDialogOpen] = useState(false);
  const [copyNameDialogConfirmed, setCopyNameDialogConfirmed] = useState(false);
  const [copyFileName, setCopyFileName] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [showPlanLimitDialog, setShowPlanLimitDialog] = useState(false);
  const [clickedFile, setClickedFile] = React.useState<IFile>(emptyFileWithoutId);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [seeDemoOpen, setSeeDemoOpen] = React.useState(false);
  const [isFileShared, setIsFileShared] = React.useState(false);

  const handleCopyClick = (event: React.MouseEvent<unknown>, file: IFile) => {
    setIsFileShared(false);
    setClickedFile(file);
    setCopyFileName(t('Copy of ') + file.name);
    setCopyNameDialogOpen(true);
  };

  const handleSharedCopyClick = (event: React.MouseEvent<unknown>, file: IFile) => {
    setClickedFile(file);
    setIsFileShared(true);
    setCopyFileName(t('Copy of ') + file.name);
    setCopyNameDialogOpen(true);
  };

  const handleShareClick = (event: React.MouseEvent<unknown>, file: IFile) => {
    setIsFileShared(false);
    setClickedFile(file);
    setShareDialogOpen(true);
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>, file: IFile) => {
    setIsFileShared(false);
    setClickedFile(file);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleSharedDeleteClick = (event: React.MouseEvent<unknown>, file: IFile) => {
    setIsFileShared(true);
    setClickedFile(file);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleCardClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setIsFileShared(false);
    if (fileName === '') {
      updateDoc(userDoc, {
        selectedFile: '',
      })
        .then(() => {
          user && setOwnerEmail(user?.email);
          history.push('/file');
        })
        .catch(() => {
          toast.error(t('An error has occurred'));
        });
      return;
    }
    const docRef = doc(db, filesPath, fileName);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.id || fileData.name,
        })
          .then(() => {
            user && setOwnerEmail(user?.email);
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  const handleSharedCardClick = (event: React.MouseEvent<unknown>, shared: SharedWithMe | undefined) => {
    if (!shared) return;
    const sharedFilePath = `users/${shared.user}/files`;
    const docRef = doc(db, sharedFilePath, shared.file);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.id || fileData.name,
        })
          .then(() => {
            setOwnerEmail(shared.user);
            setShareMode(shared.mode);
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  async function checkDeleteConfirmation() {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      if (isFileShared) {
        //TODO logic to remove sharing
        const sharingsUserDoc = doc(db, `sharings/${user?.email}`);
        const sharedFileDoc = doc(
          db,
          `users/${sharedWithMe.find((shared) => shared.file === (clickedFile!.id || clickedFile!.name))?.user}/files/${
            clickedFile!.id || clickedFile!.name
          }`
        );
        getDoc(sharedFileDoc).then((docSnap) => {
          if (docSnap.exists()) {
            const sharedFile = docSnap.data() as IFile;
            if (sharedFile) {
              const batch = writeBatch(db);
              batch.update(sharingsUserDoc, {
                sharedWithMe: sharedWithMe.filter((shared) => shared.file !== (clickedFile!.id || clickedFile!.name)),
              });
              batch.update(sharedFileDoc, {
                sharedWith: sharedFile.sharedWith?.filter((shared) => shared.user !== user?.email),
              });
              batch
                .commit()
                .then(() => {
                  toast.success(clickedFile!.name + t(' unshared sucessfully'));
                })
                .catch(() => {
                  toast.error(t('An error has ocurred'));
                });
            } else {
              toast.error(t('An error has ocurred'));
            }
          }
        });
        setIsFileShared(false);
      } else {
        try {
          const batch = writeBatch(db);
          await Promise.all(
            ['resources', 'subjects', 'lessons', 'solutions'].map(async (collectionLabel) => {
              const querySnapshot = await getDocs(
                query(collection(db, `${filesPath}/${clickedFile!.id || clickedFile!.name}/${collectionLabel}`))
              );
              querySnapshot.forEach((document: any) => {
                batch.delete(
                  doc(db, `${filesPath}/${clickedFile!.id || clickedFile!.name}/${collectionLabel}`, document.id)
                );
              });
            })
          ).then(() => {
            batch.delete(doc(db, filesPath, clickedFile!.id || clickedFile!.name));
            batch
              .commit()
              .then(() => {
                toast.success(clickedFile!.name + t(' deleted sucessfully'));
                history.push('/files');
              })
              .catch(() => {
                toast.error(t('An error has ocurred'));
              });
          });
        } catch (error: any) {
          toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
          console.error({ error });
        }
      }
    }
  }

  checkDeleteConfirmation();

  async function copy() {
    try {
      setCopyNameDialogConfirmed(false);
      //Validation and newName adjustments
      const newName: string = copyFileName;
      if (files.map((file) => file.name).includes(newName)) {
        toast.error(t('File ') + newName + t(' already registered!'));
        return;
      }
      setLoadingOpen(true);
      // Get file and its collections
      const originalFileId = clickedFile!.id || clickedFile!.name;
      const originalFilesPath = isFileShared
        ? `users/${sharedWithMe.find((shared) => shared.file === originalFileId)?.user}/files`
        : filesPath;
      console.log({ originalFilesPath });
      setIsFileShared(false);
      const newFileId = uuidv4();
      const docRef = doc(db, originalFilesPath, originalFileId);
      getDoc(docRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          const file = docSnap.data() as IFile;
          const newFile = { ...file, id: newFileId };
          const resourcesSnap = await getDocs(
            query(collection(db, `${originalFilesPath}/${originalFileId}/resources`))
          );
          const resources = resourcesSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as IResource[];
          const subjectsSnap = await getDocs(query(collection(db, `${originalFilesPath}/${originalFileId}/subjects`)));
          const subjects = subjectsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISubject[];
          const lessonsSnap = await getDocs(query(collection(db, `${originalFilesPath}/${originalFileId}/lessons`)));
          const lessons = lessonsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ILesson[];
          const solutionsSnap = await getDocs(
            query(collection(db, `${originalFilesPath}/${originalFileId}/solutions`))
          );
          const solutions = solutionsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISolution[];
          newFile.name = newName;
          newFile.createdAt = new Date();
          newFile.updatedAt = new Date();
          const batch = writeBatch(db);
          batch.set(doc(db, filesPath, newFile.id), newFile);
          //Copy resources, subjects, lessons, and solutions
          const newFilePath = filesPath + '/' + newFile.id;
          resources.forEach(async (resource: any) => {
            batch.set(doc(db, newFilePath + '/resources', resource.name), resource);
          });
          subjects.forEach(async (subject: any) => {
            batch.set(doc(db, newFilePath + '/subjects', subject.name), subject);
          });
          lessons.forEach(async (lesson: any) => {
            batch.set(doc(db, newFilePath + '/lessons', lesson.name), lesson);
          });
          solutions.forEach(async (solution: any) => {
            batch.set(doc(db, newFilePath + '/solutions', solution.name), solution);
          });
          //Update selected file
          batch.update(userDoc, {
            selectedFile: newName,
          });

          batch
            .commit()
            .then(() => {
              setLoadingOpen(false);
              toast.success(file?.name + t(' copied sucessfully'));
              history.push('/files');
            })
            .catch(() => {
              setLoadingOpen(false);
              toast.error(t('File is too large to be copied'));
              history.push('/files');
            });
        } else {
          toast.error(t('No such document'));
        }
      });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  }

  copyNameDialogConfirmed && copy();

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    history.push('/settings');
  };

  const [sharedFiles, setSharedFiles] = useState<IFile[]>([]);

  useEffect(() => {
    // Fetch shared files and set them in the state
    if (sharedWithMe) {
      const filesPromises = sharedWithMe.map((shared) => {
        const path = '/users/' + shared.user + '/files/' + shared.file;
        return getDoc(doc(db, path))
          .then((doc) => {
            const sharedFile = doc.data() as IFile;
            return sharedFile;
          })
          .catch((error) => {
            console.error(error);
            return null;
          });
      });

      Promise.all(filesPromises).then((sharedFilesData) => {
        const filteredFiles = sharedFilesData.filter((file) => file !== null) as IFile[];
        setSharedFiles(filteredFiles);
      });
    }
  }, [sharedWithMe]);

  return (
    <PageContainer>
      <FileShareDialog open={shareDialogOpen} setOpen={setShareDialogOpen} clickedFile={clickedFile} />
      <DemoProjectsDialog open={seeDemoOpen} setOpen={setSeeDemoOpen} title={t('Demo projects')} message={''} />
      <FormDialog
        open={copyNameDialogOpen}
        setOpen={setCopyNameDialogOpen}
        setConfirmed={setCopyNameDialogConfirmed}
        title={t('Copy File')}
        message={t('Choose a name to the copy of the file. This name cannot be changed later:')}
        fieldLabel={t('File Name')}
        inputText={copyFileName}
        setInputText={setCopyFileName}
      />
      <DraggableDialog
        open={deleteConfirmationDialogOpen}
        setOpen={setDeleteConfirmationDialogOpen}
        setConfirmed={setDeleteConfirmed}
        confirmLabel={isFileShared ? t('Unshare') : t('Delete file')}
        title={t('Warning')}
        message={
          isFileShared
            ? clickedFile!.name + t(' will be no long shared with you')
            : clickedFile!.name + t('Delete file warning')
        }
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <PlanInfoDialog
        open={showPlanLimitDialog}
        positiveLabel={t('Upgrade plan')}
        negativeLabel={t('Back')}
        positiveAction={handleNavToUpgradePlan}
        negativeAction={() => {
          setShowPlanLimitDialog(false);
        }}
        title={t('Plan limit reached')}
        message={t(
          'You have reached the limit of files for your plan! Please consider upgrading your plan to create more files.'
        )}
      />
      <MiniDrawerFiles />
      <Grid container justifyContent="center">
        <Grid
          container
          justifyContent="flex-start"
          maxWidth={'lg'}
          // sx={{ backgroundColor: 'red' }}
          alignItems="flex-start"
          paddingTop={1}
          paddingBottom={8}
          spacing={4}
        >
          <Grid item width={'100%'}>
            <Typography variant="h6" gutterBottom color={'primary'}>
              {t('My files')}
            </Typography>
          </Grid>

          <Grid item>
            <FileCard
              file={null}
              user={user}
              onClick={handleCardClick}
              onCopyClick={handleCopyClick}
              onShareClick={handleShareClick}
              onDeleteClick={handleDeleteClick}
              setSeeDemoOpen={setSeeDemoOpen}
            />
          </Grid>
          {files
            .sort(
              (file1, file2) =>
                (file2.updatedAt as unknown as Timestamp).toMillis() -
                (file1.updatedAt as unknown as Timestamp).toMillis()
            )
            .map((file, index) => {
              return (
                <Grid item key={index}>
                  <FileCard
                    file={file}
                    user={user}
                    onClick={handleCardClick}
                    onCopyClick={handleCopyClick}
                    onShareClick={handleShareClick}
                    onDeleteClick={handleDeleteClick}
                  />
                </Grid>
              );
            })}
          {sharedWithMe && (
            <Grid item width={'100%'}>
              <Typography variant="h6" gutterBottom color={'primary'}>
                {t('Shared with me')}
              </Typography>
            </Grid>
          )}
          {sharedFiles.map((sharedFile, index) => {
            return (
              <Grid item key={index}>
                <SharedFileCard
                  file={sharedFile}
                  user={user}
                  shared={
                    sharedWithMe
                      ? sharedWithMe.find((shared) => sharedFile && shared.file === (sharedFile.id || sharedFile.name))
                      : undefined
                  }
                  onClick={handleSharedCardClick}
                  onCopyClick={handleSharedCopyClick}
                  onDeleteClick={handleSharedDeleteClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </PageContainer>
  );
}
