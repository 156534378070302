import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import MiniDrawer from 'components/Drawer/MiniDrawer';
import { DataGrid, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { SessionContext } from 'contexts';
import { doc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { db } from 'services';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import { setRandomColor } from 'styles/randomColor';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { Delete, HighlightAlt, SearchOff } from '@mui/icons-material';
import { Grid, Typography, Paper, Divider, Button, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import PageContainer from 'containers/PageContainer';
import { createData, useDataGrid } from './utils/useDataGrid';
import Onboarding from 'components/Onboarding';
import {
  EMPTY_CLASSES_KEY,
  EMPTY_ROOMS_KEY,
  EMPTY_TEACHERS_KEY,
  useResourcesOnboarding,
} from './utils/useResourcesOnboarding';
import HelpButton from 'components/Button/HelpButton';
import { theme } from 'styles';
import ImportStepperDialog from 'components/Dialog/ImportStepperDialog';
import useEnterKey from 'hooks/useEnterKey';
import Resource, { IResource } from './Resource';
import { priorityOptionsMap } from 'util/configUtils';

export default function Resources(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const type = props.type;
  const history = useHistory();
  const { resources, file, user, lessons, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { columns } = useDataGrid(t, file!, user!, type, showConstraint);
  const {
    runEmptyClassesOnboarding,
    runEmptyTeachersOnboarding,
    runEmptyRoomsOnboarding,
    emptyClassesSteps,
    emptyTeachersSteps,
    emptyRoomsSteps,
  } = useResourcesOnboarding(t);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const fileId = file?.id || file?.name;

  if (!fileId) {
    history.push('/files');
    toast.warning(t('Select a file first'));
  }

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const resourcesPath = 'users/' + ownerEmail + '/files/' + fileId + '/resources';
  const lessonsPath = `users/${ownerEmail}/files/${fileId}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${fileId}/solutions`;
  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];

  function showConstraint(constrType: string) {
    if (type === 'class') {
      if (constrType === 'Workload') return file?.showWorkloadClasses;
      else if (constrType === 'Working Days') return file?.showWorkingDaysClasses;
      else if (constrType === 'Idle Window') return file?.showIdleWindowClasses;
      else if (constrType === 'Daily Workload') return file?.showDailyWorkloadClasses;
      else if (constrType === 'Rest between Days') return file?.showRestBetweenDaysClasses;
      else if (constrType === 'Room Changes Day') return file?.showRoomChangesDayClasses;
      else if (constrType === 'Consecutive Times') return file?.showConsecutiveTimesClasses;
      else if (constrType === 'Distinct Subjects') return file?.showDistinctSubjectsClasses;
      else if (constrType === 'Forbidden Combination') return file?.showForbiddenCombinationClasses;
    } else if (type === 'teacher') {
      if (constrType === 'Workload') return file?.showWorkloadTeachers;
      else if (constrType === 'Working Days') return file?.showWorkingDaysTeachers;
      else if (constrType === 'Idle Window') return file?.showIdleWindowTeachers;
      else if (constrType === 'Daily Workload') return file?.showDailyWorkloadTeachers;
      else if (constrType === 'Rest between Days') return file?.showRestBetweenDaysTeachers;
      else if (constrType === 'Room Changes Day') return file?.showRoomChangesDayTeachers;
      else if (constrType === 'Consecutive Times') return file?.showConsecutiveTimesTeachers;
      else if (constrType === 'Distinct Subjects') return file?.showDistinctSubjectsTeachers;
      else if (constrType === 'Forbidden Combination') return file?.showForbiddenCombinationTeachers;
    } else if (type === 'room') {
      if (constrType === 'Workload') return file?.showWorkloadRooms;
      else if (constrType === 'Working Days') return file?.showWorkingDaysRooms;
      else if (constrType === 'Idle Window') return file?.showIdleWindowRooms;
      else if (constrType === 'Daily Workload') return file?.showDailyWorkloadRooms;
      else if (constrType === 'Rest between Days') return file?.showRestBetweenDaysRooms;
      else if (constrType === 'Room Changes Day') return file?.showRoomChangesDayRooms;
      else if (constrType === 'Consecutive Times') return file?.showConsecutiveTimesRooms;
      else if (constrType === 'Travel Time') return file?.showTravelTimeRooms;
      else if (constrType === 'Distinct Subjects') return file?.showDistinctSubjectsRooms;
      else if (constrType === 'Forbidden Combination') return file?.showForbiddenCombinationRooms;
    }
    return false;
  }

  let rows: any[] = [];
  resources
    .filter((res) => res.type === type)
    .map(function (resource, index) {
      return rows.push(
        createData(
          resource.short,
          resource.color,
          resource.photoURL,
          resource.name,
          t(resource.priority),
          (resource.minWorkload === 0 ? '↓' : resource.minWorkload) +
            ' - ' +
            (resource.maxWorkload === days.length * times.length ? '↑' : resource.maxWorkload),
          (resource.minWorkingDays === 0 ? '↓' : resource.minWorkingDays) +
            ' - ' +
            (resource.maxWorkingDays === days.length ? '↑' : resource.maxWorkingDays),
          (resource.minIdleWindow === 0 ? '↓' : resource.minIdleWindow) +
            ' - ' +
            (resource.maxIdleWindow === times.length - 2 ? '↑' : resource.maxIdleWindow),
          (resource.minDailyWorkload === 0 ? '↓' : resource.minDailyWorkload) +
            ' - ' +
            (resource.maxDailyWorkload === times.length ? '↑' : resource.maxDailyWorkload),

          (resource.minRestBetweenDays === 0 ? '↓' : resource.minRestBetweenDays) +
            ' - ' +
            (resource.maxRestBetweenDays === times.length * 2 - 2 ? '↑' : resource.maxRestBetweenDays),

          (resource.minRoomChangesDay === 0 ? '↓' : resource.minRoomChangesDay) +
            ' - ' +
            (resource.maxRoomChangesDay === times.length - 1 ? '↑' : resource.maxRoomChangesDay),

          (resource.minConsecutiveTimes === 0 ? '↓' : resource.minConsecutiveTimes) +
            ' - ' +
            (resource.maxConsecutiveTimes === times.length ? '↑' : resource.maxConsecutiveTimes),

          (resource.minDistinctSubjects === 0 ? '↓' : resource.minDistinctSubjects) +
            ' - ' +
            (resource.maxDistinctSubjects === days.length * times.length ? '↑' : resource.maxDistinctSubjects),
          resource.travelTimeRooms,
          resource.forbiddenCombination.map((dayNum) => {
            return days[dayNum];
          }),
          resource.unavailableTimes.length * 100 + resource.undesiredTimes.length,
          resource.unavailableTimes,
          resource.undesiredTimes,
          (resource.updatedAt as unknown as Timestamp).toDate(),
          resource.type
        )
      );
    });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    setSelectedResource(resources.find((resource) => resource.name === name));
  };

  function chooseLabel() {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('Classes') : t('Students');
    } else if (type === 'teacher') {
      return t('Teachers');
    } else if (type === 'room') {
      return t('Rooms');
    } else {
      return t('Resources');
    }
  }

  function getNumberFromType() {
    if (type === 'class') {
      return '2. ';
    } else if (type === 'teacher') {
      return '3. ';
    } else if (type === 'room') {
      return '4. ';
    } else {
      return '';
    }
  }

  const [loadingOpen, setLoadingOpen] = React.useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, resourcesPath, selectedId.toString()));
        //Delete related lessons
        lessons
          .filter(
            (lesson) =>
              lesson.classes.includes(selectedId.toString()) ||
              lesson.teachers.includes(selectedId.toString()) ||
              lesson.rooms.includes(selectedId.toString())
          )
          .forEach((lesson) => batch.delete(doc(db, lessonsPath, lesson.name)));
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + ' ' + chooseLabel() + t(' deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const navigateBackwards = () => {
    if (type === 'class') {
      history.push({ pathname: '/file', state: file });
    } else if (type === 'teacher') {
      history.push('/classes');
    } else if (type === 'room') {
      history.push('/teachers');
    }
  };

  const navigateForward = () => {
    if (type === 'class') {
      history.push('/teachers');
    } else if (type === 'teacher') {
      if (file?.roomsSetting !== 0) {
        history.push('/rooms');
      } else history.push('/subjects');
    } else if (type === 'room') {
      history.push('/subjects');
    }
  };

  const handleImportClick = async () => {
    setImportOpen(true);
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  useEnterKey(handleCreate);

  function handleCreate() {
    setSelectedResource(emptyResource);
  }

  const priorityOptions = [t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];
  const [selectedResource, setSelectedResource] = useState<IResource>();
  const emptyResource: IResource = {
    color: setRandomColor(),
    photoURL: '',
    name: '',
    short: '',
    type: type,
    minWorkload: 0,
    maxWorkload: days.length * times.length,
    minWorkingDays: 0,
    maxWorkingDays: days.length,
    minIdleWindow: 0,
    maxIdleWindow: times.length - 2,
    minDailyWorkload: 0,
    maxDailyWorkload: times.length,
    minRestBetweenDays: 0,
    maxRestBetweenDays: times.length * 2 - 2,
    minRoomChangesDay: 0,
    maxRoomChangesDay: times.length - 1,
    minDistinctSubjects: 0,
    maxDistinctSubjects: days.length * times.length,
    minConsecutiveTimes: 0,
    maxConsecutiveTimes: times.length,
    travelTimeRooms: [],
    minTravelTime: 0,
    priority: priorityOptionsMap[priorityOptions[2]],
    forbiddenCombination: [],
    unavailableTimes: [],
    undesiredTimes: [],
    createdAt: new Date('2022-01-01T07:00:00'),
    updatedAt: new Date('2022-01-01T07:00:00'),
  };
  return (
    <PageContainer>
      {type === 'class' ? (
        <Onboarding steps={emptyClassesSteps} run={runEmptyClassesOnboarding} storageFlagKey={EMPTY_CLASSES_KEY} />
      ) : type === 'teacher' ? (
        <Onboarding steps={emptyTeachersSteps} run={runEmptyTeachersOnboarding} storageFlagKey={EMPTY_TEACHERS_KEY} />
      ) : (
        <Onboarding steps={emptyRoomsSteps} run={runEmptyRoomsOnboarding} storageFlagKey={EMPTY_ROOMS_KEY} />
      )}
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={'Warning'}
        message={selectionModel.length + ' ' + chooseLabel() + t('Delete resource warning')}
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <VideoPlayerDialog
        open={videoPlayerDialogOpen}
        setOpen={setVideoPlayerDialogOpen}
        videoURL={t('video_url_help_resources')}
      ></VideoPlayerDialog>
      <ImportStepperDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={`${t('Import')} ${chooseLabel()}`}
        type={type}
      />
      <MiniDrawer currentPage={location.pathname} />
      {selectedResource ? (
        <Resource resource={selectedResource} setResource={setSelectedResource} type={type} />
      ) : (
        <Grid container justifyContent="center">
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item flexGrow={1}>
                <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                  {getNumberFromType() + chooseLabel()}
                </Typography>
                <Divider color={theme.palette.primary.main} />
              </Grid>
              <HelpButton t={t} onClick={() => setVideoPlayerDialogOpen(true)} />
              {selectionModel.length !== 0 ? (
                <Grid item xs={3} md={2}>
                  <Button
                    startIcon={<Delete />}
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={readOnly}
                    onClick={() => setDeleteConfirmationOpen(true)}
                    color="error"
                  >
                    {`${t('Delete')} (${selectionModel.length})`}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={3} md={2}>
                  <Button
                    className="import-button"
                    // startIcon={<FileDownload />}
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={readOnly}
                    onClick={() => handleImportClick()}
                    color="primary"
                  >
                    {t('Import')}
                  </Button>
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <Button
                  className="create-button"
                  // startIcon={<AddCircle />}
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={handleCreate}
                  color="primary"
                >
                  {t('Create')}
                </Button>
              </Grid>
              {/* </Grid> */}
            </Grid>
            <Grid className="resources-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                // autoPageSize
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[20, 50, 100]}
                // hideFooterPagination
                density={'compact'}
                // loading={rows.length === 0}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                  setSelectionModel(ids);
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'asc' }],
                  },
                }}
                localeText={
                  user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined
                }
                onRowClick={(params, event) => handleClick(event, params.id.toString())}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                      <Typography color={grey[500]} textAlign="center">
                        {`${t('Click on IMPORT or CREATE to add')} ${chooseLabel()}`}
                      </Typography>
                    </Stack>
                  ),
                  NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                      <Typography color={grey[500]} textAlign="center">
                        {t('Local FILTER returns no data')}
                      </Typography>
                    </Stack>
                  ),
                }}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </Grid>
            <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
              <Grid item xs={2}>
                <Button variant="outlined" size="small" fullWidth onClick={() => navigateBackwards()} color="primary">
                  {t('Back')}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="outlined" size="small" fullWidth onClick={() => navigateForward()} color="primary">
                  {t('Next')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </PageContainer>
  );
}
