import { QuestionMark } from '@mui/icons-material';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { TFunction } from 'react-i18next';

type Props = {
  t: TFunction;
  onClick: () => void;
};

export default function HelpButton({ t, onClick }: Props) {
  return (
    <Grid item alignSelf={'center'}>
      <Tooltip title={t('Watch a helpful video')}>
        <IconButton style={{ height: 24, width: 24 }} color="primary" onClick={onClick}>
          <QuestionMark style={{ height: 20, width: 20 }} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
