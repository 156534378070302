import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  amber,
  orange,
  deepOrange,
  brown,
} from '@mui/material/colors';
import { IFile } from 'pages/Files/File';
import { TFunction } from 'react-i18next';
import { DAY_TIME_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from './solutionUtils';

export interface IImportWarning {
  line: number;
  column: string;
  description: string;
  type: 'error' | 'warning';
}

export const MAX_IMPORT_COLS = 8;

export const useImportUtils = (
  t: TFunction<'translation', undefined>,
  warnings: IImportWarning[],
  type: string,
  days: string[],
  times: string[],
  file: IFile | null,
  existingIds?: string[]
) => {
  const MAX_TIMESLOTS = (days.length as number) * (times.length as number);

  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    // yellow,
    amber,
    orange,
    deepOrange,
    brown,
    // grey,
    // blueGrey,
  ];
  const intensities: number[] = [400, 500, 600, 700, 800, 900];

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)][
      intensities[Math.floor(Math.random() * intensities.length)]
    ];
  }

  function getInitials(string: string, maxLength: number) {
    if (string.length <= maxLength) {
      return string.toUpperCase();
    }
    var names = string.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  }

  function chooseLabel(type: string) {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('classes') : t('students');
    } else if (type === 'teacher') {
      return t('teachers');
    } else if (type === 'room') {
      return t('rooms');
    } else {
      return t('resources');
    }
  }

  function formatWarnings() {
    if (warnings.length === 0) return;

    return (
      <div>
        {warnings.map((warning, idx) => {
          return <p key={idx}>{warning.description}</p>;
        })}
      </div>
    );
  }

  function handleName(inputText: string, lineNum: number, column: string, names: string[], className?: string[]) {
    if (validateNameInput(inputText.trim(), lineNum, column, className)) {
      names.push(inputText.trim());
    }
    return formatWarnings();
  }

  function validateNameInput(name: string, lineNum: number, column: string, className?: string[]): boolean {
    if (name.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be ignored')}`,
        type: 'error',
      });
      return false;
    } else if (name.length > 40) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name having more than 40 chars for')} ${column} ${t('will be ignored')}`,
        type: 'error',
      });
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name containing: / \\ | ; # = : _ { } & for')} ${column} ${t('will be ignored')}`,
        type: 'error',
      });
      return false;
    } else if (existingIds && existingIds.includes(name)) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${name} ${t('already exists and will be ignored')}`,
        type: 'warning',
      });
      return true;
    } else if (className && existingIds && existingIds.includes(name + SUBJECT_CLASS_SEPARATOR + className)) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${name} ${t('is already attended by')} ${className} ${t('and will be ignored')}`,
        type: 'warning',
      });
      return true;
    } else return true;
  }

  function handleMinMaxConstraint(
    inputText: string,
    lineNum: number,
    column: string,
    constraintMinMax: number[],
    min: number,
    max: number
  ) {
    if (inputText.trim().length === 0) {
      constraintMinMax.push(min);
      constraintMinMax.push(max);
      return;
    }
    const inputSplit = inputText.split('-').map((text) => {
      return text.trim();
    });

    if (inputSplit.length === 2) {
      if (inputSplit[0] === '') inputSplit[0] = '' + min;
      if (inputSplit[1] === '') inputSplit[1] = '' + max;
      let inputMin: number = +inputSplit[0];
      let inputMax: number = +inputSplit[1];
      if (!Array.from({ length: max + 1 }, (x, i) => i).includes(inputMin)) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Min value for')} ${column} ${t('is not in the allowed range')} (${min}, ${max}) ${t(
            'and will be ignored'
          )}`,
          type: 'warning',
        });
        inputMin = min;
      }
      if (!Array.from({ length: max + 1 }, (x, i) => i).includes(inputMax)) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Max value for')} ${column} ${t('is not in the allowed range')} (${min}, ${max}) ${t(
            'and will be ignored'
          )}`,
          type: 'warning',
        });
        inputMax = max;
      }
      if (inputMin > max) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Min')} (${inputMin}) ${t('is larger than max allowed value')} (${max}) ${t(
            'for'
          )} ${column} ${t('and will be ignored')}`,
          type: 'warning',
        });
        inputMin = min;
      } else if (inputMin > inputMax) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Min')} (${inputMin}) ${t('larger than max')} (${inputMax}) ${t('for')} ${column} ${t(
            'will be ignored'
          )}`,
          type: 'warning',
        });
        inputMin = min;
      }
      constraintMinMax.push(inputMin);
      constraintMinMax.push(inputMax);
    } else {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${column} ${inputText} ${t('is not in the required format (min-max) and will be ignored')}`,
        type: 'warning',
      });
    }
    return formatWarnings();
  }

  function handleArrayOfDays(inputText: string, lineNum: number, column: string, arrayOfDays: number[]) {
    if (inputText.trim().length === 0) {
      return;
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });

    inputSplit.forEach((input) => {
      const day: number = +input.trim();
      if (isNaN(day)) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Day')} ${input.trim()} ${t('in')} ${column} ${input} ${t('is not a valid day')} (1, ${
            days.length
          }) ${t('and will be ignored')}`,
          type: 'warning',
        });
        return;
      }
      if (day > days.length || day < 1) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Day')} ${day} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
            days.length
          }) ${t('and will be ignored')}`,
          type: 'warning',
        });
      } else if (!isNaN(day)) {
        !arrayOfDays.includes(day - 1) && arrayOfDays.push(day - 1);
      }
    });
    return formatWarnings();
  }

  function handleArrayOfTimeslots(inputText: string, lineNum: number, column: string, arrayOfTimes: string[]) {
    if (inputText.trim().length === 0) {
      return;
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });

    inputSplit.forEach((input) => {
      let inputData = input.split(DAY_TIME_SEPARATOR);
      if (inputData.length < 2) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${column} ${inputData} ${t('is not in the required format (day:time) and will be ignored')}`,
          type: 'warning',
        });
      } else {
        const allDays = inputData[0].trim() === '*';
        const allTimes = inputData[1].trim() === '*';
        const day = Number(inputData[0].trim());
        const time = Number(inputData[1].trim());
        const dayIsValid = Array.from({ length: days.length + 1 }, (x, i) => i).includes(day) || day < 1 || allDays;
        const timeIsValid =
          Array.from({ length: times.length + 1 }, (x, i) => i).includes(time) || time < 1 || allTimes;
        if (!dayIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Day')} ${day} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              days.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else if (!timeIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Time')} ${time} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              times.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else {
          if (allDays && allTimes) {
            days.forEach((day, indexDay) => {
              times.forEach((time, indexTime) => {
                !arrayOfTimes.includes(indexDay + DAY_TIME_SEPARATOR + indexTime) &&
                  arrayOfTimes.push(indexDay + DAY_TIME_SEPARATOR + indexTime);
              });
            });
          } else if (allDays) {
            days.forEach((day, index) => {
              !arrayOfTimes.includes(index + DAY_TIME_SEPARATOR + (time - 1)) &&
                arrayOfTimes.push(index + DAY_TIME_SEPARATOR + (time - 1));
            });
          } else if (allTimes) {
            times.forEach((time, index) => {
              !arrayOfTimes.includes(day - 1 + DAY_TIME_SEPARATOR + index) &&
                arrayOfTimes.push(day - 1 + DAY_TIME_SEPARATOR + index);
            });
          } else {
            !arrayOfTimes.includes(day - 1 + DAY_TIME_SEPARATOR + (time - 1)) &&
              arrayOfTimes.push(day - 1 + DAY_TIME_SEPARATOR + (time - 1));
          }
        }
      }
    });
    return formatWarnings();
  }

  function handleLessonResource(
    name: string,
    lineNum: number,
    column: string,
    type: string,
    resources: string[],
    resourcesNum: number[]
  ) {
    const resourceNames = name.split('+');
    resourceNames.forEach((resourceName) => {
      if (validateNameInput(resourceName.trim(), lineNum, column)) {
        resources.push(resourceName.trim());
      }
    });
    if (resources.length < resourcesNum[0]) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('The number requested joint')} ${chooseLabel(type)} ${t(
          'larger than the number of provided'
        )} ${column} ${t('and will be considered as')} ${resources.length}`,
        type: 'error',
      });
      resourcesNum.length = 0;
      resourcesNum.push(resources.length);
    }
    return formatWarnings();
  }

  function handleJointResourcesNum(
    inputText: string,
    lineNum: number,
    column: string,
    resourcesNum: number[],
    defaultValue: number
  ) {
    const inputAsNumber: number = Number(inputText.trim());
    if (inputText.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be considered as')} ${defaultValue}`,
        type: 'warning',
      });
      resourcesNum.push(defaultValue);
    } else if (!Number.isInteger(inputAsNumber)) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Decimal or non-numeric')} ${column} ${t('will be considered as')} ${defaultValue}`,
        type: 'warning',
      });
      resourcesNum.push(defaultValue);
    } else if (inputAsNumber < 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Negative value for')} ${column} ${t('will be considered as')} ${defaultValue}`,
        type: 'warning',
      });
      resourcesNum.push(defaultValue);
    } else {
      resourcesNum.push(inputAsNumber);
    }
    return formatWarnings();
  }

  function handleLessonsWeek(inputText: string, lineNum: number, column: string, lessonsWeek: number[]) {
    const inputAsNumber: number = +inputText.trim();
    if (inputText.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else if (!Number.isInteger(inputAsNumber)) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Decimal or non-numeric')} ${column} ${t('will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else if (inputAsNumber > MAX_TIMESLOTS) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${column} ${t('is higher than the number of timeslots and will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else if (inputAsNumber < 1) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Negative or 0 value for')} ${column} ${t('will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else {
      lessonsWeek.push(inputAsNumber);
    }
    return formatWarnings();
  }

  function handleSplit(inputText: string, lineNum: number, column: string, split: string[], lessonsWeek: number) {
    let defaultSplit: string = '';
    if (isNaN(lessonsWeek) || !Number.isInteger(lessonsWeek)) {
      defaultSplit += '1';
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Decimal or non-numeric value for Times /Week for this lesson, hence')} ${column} ${t(
          'will be considered as'
        )} ${defaultSplit}`,
        type: 'warning',
      });
      split.push(defaultSplit);
      return formatWarnings();
    } else if (lessonsWeek <= 0) {
      defaultSplit += '1';
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Negative/zero value for Times /Week for this lesson, hence')} ${column} ${t(
          'will be considered as'
        )} ${defaultSplit}`,
        type: 'warning',
      });
      split.push(defaultSplit);
      return formatWarnings();
    } else {
      Array.from({ length: lessonsWeek }).forEach((num) => {
        defaultSplit += '1-';
      });
    }
    defaultSplit = defaultSplit.slice(0, -1);
    if (inputText.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be considered as')} ${defaultSplit}`,
        type: 'warning',
      });
      split.push(defaultSplit);
      return formatWarnings();
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });
    inputSplit.forEach((input) => {
      const splitOption = input.split('-').map((text) => {
        return +text.trim();
      });
      let splitSum = 0;
      let splitArray: number[] = [];
      let inputIsOk = true;
      splitOption.forEach((splitNum) => {
        if (inputIsOk) {
          if (!Number.isInteger(splitNum)) {
            warnings.push({
              line: lineNum + 1,
              column: column,
              description: `${t('Decimal or non-numeric')} ${column} ${t('will be considered as')} ${defaultSplit}`,
              type: 'warning',
            });
            inputIsOk = false;
          } else {
            splitSum += splitNum;
            splitArray.push(splitNum);
          }
        }
      });
      if (inputIsOk) {
        if (splitSum !== lessonsWeek) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${column} ${t('sum of')}: ${splitOption} ${t(
              'does not match the expected Times /Week'
            )} ${lessonsWeek} ${t('and will be considered as')} ${defaultSplit}`,
            type: 'warning',
          });
          !split.includes(defaultSplit) && split.push(defaultSplit);
        } else if (splitArray.find((splitNum) => splitNum > times.length)) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${column} ${t(
              'spreads over more times than existing and will be considered as'
            )} ${defaultSplit}`,
            type: 'warning',
          });
          !split.includes(defaultSplit) && split.push(defaultSplit);
        } else {
          split.push(input.replaceAll(' ', ''));
        }
      }
    });
    return formatWarnings();
  }

  function getDefaultSplit(lessonsWeek: number[]) {
    let lessons = 1;
    if (lessonsWeek.length > 0) {
      lessons = lessonsWeek[0];
    }
    let defaultSplit = '';
    Array.from({ length: lessons }).forEach((num) => {
      defaultSplit += '1-';
    });
    defaultSplit = defaultSplit.slice(0, -1);
    return [defaultSplit];
  }

  function resetWarnings() {
    warnings.length = 0;
  }

  const getResourceIdentifierField = () => {
    switch (type) {
      case 'teacher':
        return t('Teacher');
      case 'class':
        return t('Class');
      case 'room':
        return t('Room');
      case 'subject':
      case 'lesson':
        return t('Subject');
      default:
        return t('Name');
    }
  };

  const handleIdentifierInput = (column: string, value: string, handlerFn: any, index: number, name: string[]) => {
    if (column === getResourceIdentifierField()) handlerFn(value, index, column, name);
    return;
  };

  const handleLessonsWeekInput = (
    column: string,
    value: string,
    handlerFn: any,
    index: number,
    lessonsWeekInputFields: string[],
    lessonsWeekInputConfig: any
  ) => {
    if (lessonsWeekInputFields.includes(column)) handlerFn(value, index, column, lessonsWeekInputConfig[column].field);
    return;
  };

  const handleMinMaxInput = (
    column: string,
    value: string,
    handlerFn: any,
    index: number,
    minMaxImportFields: string[],
    minMaxInputConfig: any
  ) => {
    if (minMaxImportFields.includes(column)) {
      handlerFn(
        value,
        index,
        column,
        minMaxInputConfig[column].field,
        minMaxInputConfig[column].min,
        minMaxInputConfig[column].max
      );
    }
  };

  const handleNonNegativeIntegerInput = (
    column: string,
    value: string,
    handlerFn: any,
    index: number,
    nonNegativeIntegerImportFields: string[],
    nonNegativeIntegerImportConfig: any
  ) => {
    if (nonNegativeIntegerImportFields.includes(column)) {
      handlerFn(
        value,
        index,
        column,
        nonNegativeIntegerImportFields,
        nonNegativeIntegerImportConfig[column].field,
        nonNegativeIntegerImportConfig[column].default
      );
    }
  };

  const handleDaysInput = (
    column: string,
    value: string,
    handlerFn: any,
    index: number,
    arrayOfDaysImportFields: string[],
    arrayOfDaysInputConfig: any
  ) => {
    if (arrayOfDaysImportFields.includes(column)) {
      handlerFn(value, index, column, arrayOfDaysInputConfig[column].field);
    }
  };

  const handleTimeslotsInput = (
    column: string,
    value: string,
    handlerFn: any,
    index: number,
    arrayOfTimeslotsImportFields: string[],
    arrayOfTimeslotsInputConfig: any
  ) => {
    if (arrayOfTimeslotsImportFields.includes(column)) {
      handlerFn(value, index, column, arrayOfTimeslotsInputConfig[column].field);
    }
  };

  const getTypeFromColumn = (column: string) => {
    switch (column) {
      case 'Class':
      case 'Turma':
        return 'class';
      case 'Teacher':
      case 'Professor':
        return 'teacher';
      case 'Room':
      case 'Sala':
        return 'room';
    }
  };

  const handleLessonResourceInput = (
    column: string,
    value: string,
    handlerFn: any,
    index: number,
    arrayOfLessonResourceFields: string[],
    arrayOfLessonResourceConfig: any
  ) => {
    if (arrayOfLessonResourceFields.includes(column)) {
      handlerFn(
        value,
        index,
        column,
        getTypeFromColumn(column),
        arrayOfLessonResourceConfig[column].field,
        arrayOfLessonResourceConfig[column].resourceNum
      );
    }
  };

  const fillMinMaxMissingFields = (field: number[], min: number, max: number) => {
    if (field.length === 0) {
      field.push(min, max);
    }
  };

  return {
    getInitials,
    getRandomColor,
    handleName,
    validateNameInput,
    handleMinMaxConstraint,
    handleArrayOfDays,
    handleArrayOfTimeslots,
    handleLessonResource,
    handleJointResourcesNum,
    handleLessonsWeek,
    handleSplit,
    getDefaultSplit,
    resetWarnings,
    getResourceIdentifierField,
    handleIdentifierInput,
    handleMinMaxInput,
    handleNonNegativeIntegerInput,
    handleLessonsWeekInput,
    handleDaysInput,
    handleTimeslotsInput,
    handleLessonResourceInput,
    fillMinMaxMissingFields,
  };
};
