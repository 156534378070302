import { useState } from 'react';
import { useHistory } from 'react-router';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doc } from 'firebase/firestore';
import { getDoc, setDoc } from '@firebase/firestore';
import { useTranslation } from 'react-i18next';

import { auth, authFacebook, authGoogle, db } from 'services';
import { ISingIn } from 'contexts/SessionContext/SessionContext';
import { languageOptions } from 'components/Drawer/MiniDrawerHome';
import RecoverDialog from 'components/Dialog/RecoverDialog';
import { Grid, Button, Typography, Divider, TextField, CircularProgress } from '@mui/material';
import { Facebook } from '@mui/icons-material';
import AgreementLabel from './AgreementLabel';
import { grey } from '@mui/material/colors';

const SignInHome = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const usersPath = 'users';

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, authGoogle);
      socialLoginFirebase();
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  };

  const socialLoginFirebase = async () => {
    //Checks whether user already exists
    const userDoc = await getDoc(doc(db, 'users/' + auth.currentUser?.email));
    if (!userDoc.exists()) {
      //Save user on database
      await setDoc(doc(db, usersPath, auth.currentUser?.email as string), {
        email: auth.currentUser?.email as string,
        name: auth.currentUser?.displayName as string,
        phone: auth.currentUser?.phoneNumber as string,
        photoURL: auth.currentUser?.photoURL as string,
        countryCode: languageOptions.find((option) => option.label === i18n.language.toUpperCase())
          ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
          : 'EN',
        uid: auth.currentUser?.uid,
      }).then(() => {
        history.push('/files');
      });
    } else {
      history.push('/files');
    }
  };

  const signInWithFacebook = async () => {
    try {
      await signInWithPopup(auth, authFacebook);
      socialLoginFirebase();
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  };

  const formik = useFormik<ISingIn>({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Required field'),
      password: Yup.string().required('Required field'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await signInWithEmailAndPassword(auth, values.email, values.password);
        history.push('/files');
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      } finally {
        setLoading(false);
      }
    },
  });

  const { getFieldProps } = formik;
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);

  return (
    <Grid container padding={0}>
      <RecoverDialog
        open={resetPasswordDialogOpen}
        setOpen={setResetPasswordDialogOpen}
        title={t('Password Recovery')}
        message={t('You will receive an email with instructions on how to recover your password.')}
      ></RecoverDialog>
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" style={{ padding: 2 }} spacing={2}>
          <Grid item>
            <img height={'auto'} width={60} src="/img/logo.png" alt="Optables logo"></img>
          </Grid>
          <Grid item container xs={12} justifyContent="center" spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<img src="/img/google.png" height={20} width={28} alt="Logo Google" />}
                onClick={signInWithGoogle}
              >
                <Typography variant="subtitle2" style={{ fontSize: 10 }}>
                  {t('Continue with Google')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="outlined" startIcon={<Facebook />} onClick={signInWithFacebook}>
                <Typography variant="subtitle2" style={{ fontSize: 10 }}>
                  {t('Continue with Facebook')}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ color: grey[600] }}>{t('OR')}</Divider>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label={t('Email')}
                type="email"
                variant="standard"
                size="small"
                fullWidth
                {...getFieldProps('email')}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                type="password"
                label={t('Password')}
                variant="standard"
                size="small"
                fullWidth
                {...getFieldProps('password')}
                error={!!formik.errors.password}
                helperText={formik.errors.password}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent="flex-end">
            <Typography
              color={grey[600]}
              sx={{
                '& .text-action2': {
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#1976d2',
                    textDecoration: 'underline',
                  },
                },
              }}
            >
              <span onClick={() => setResetPasswordDialogOpen(true)} className="text-action2">
                {t('Forgot your password?')}
              </span>
            </Typography>
          </Grid>
          <AgreementLabel />

          <Grid item xs={12}>
            <Button disabled={loading} fullWidth type="submit" variant="contained" color="primary">
              {loading ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                <Typography variant="subtitle2">{t('Sign In')}</Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export { SignInHome };
