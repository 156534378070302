import { colors } from '@mui/material';

const priorityBackgroundColors: Record<string, string> = {
  'Very low': colors.blue[100],
  Low: colors.blue[300],
  Average: colors.blue[500],
  High: colors.blue[700],
  'Very high': colors.blue[900],
  'Muito baixo': colors.blue[100],
  Baixo: colors.blue[300],
  Normal: colors.blue[500],
  Alto: colors.blue[700],
  'Muito alto': colors.blue[900],
};

const priorityLabelColors: Record<string, string> = {
  'Very low': colors.grey[700],
  Low: colors.grey[50],
  Average: colors.grey[50],
  High: colors.grey[50],
  'Very high': colors.grey[50],
  'Muito baixo': colors.grey[700],
  Baixo: colors.grey[50],
  Normal: colors.grey[50],
  Alto: colors.grey[50],
  'Muito alto': colors.grey[50],
};

export { priorityBackgroundColors, priorityLabelColors };
