import { colors, Container } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode[];
};

export default function PageContainer({ children }: Props) {
  return (
    <Container
      maxWidth={false}
      style={{ padding: '56px 8px 8px 65px', minHeight: '100vh', backgroundColor: colors.grey[50] }}
    >
      {children}
    </Container>
  );
}
