import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { doc, setDoc, deleteDoc, updateDoc, writeBatch } from '@firebase/firestore';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';

import { db } from 'services';
import { SessionContext } from 'contexts';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import DraggableAvatarPicker from 'components/DraggableAvatarPicker';
import LessonsOfSubject from './components/LessonsOfSubject';
import { useTranslation } from 'react-i18next';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { ArrowBack } from '@mui/icons-material';
import MultipleSelectChip from 'components/Select/MultipleSelectChip';
import { validateNameInput } from 'util/validationUtils';
import { priorityOptionsMap } from 'util/configUtils';
import { ILesson } from 'pages/Lessons/utils/types';
import Onboarding from 'components/Onboarding';
import { SUBJECT_CREATION_KEY, useSubjectOnboarding } from './utils/useSubjectOnboarding';
import { theme } from 'styles';
import HelpButton from 'components/Button/HelpButton';
import { DAY_TIME_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';
import { priorityBackgroundColors } from 'styles/priorityColors';
import { LoadingButton } from '@mui/lab';

export interface ISubject {
  color: string;
  photoURL: string;
  name: string;
  short: string;
  priority: string;
  unavailableTimes: string[];
  undesiredTimes: string[];
  createdAt: Date;
  updatedAt: Date;
}

type Props = {
  subject: ISubject;
  setSubject: Dispatch<SetStateAction<ISubject | undefined>>;
};
export default function Subject({ subject, setSubject }: Props) {
  const { t } = useTranslation();
  const { user, file, lessons, subjects, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const fileId = file?.id || file?.name;
  const subjectsPath = `users/${ownerEmail}/files/${fileId}/subjects`;
  const lessonsPath = `users/${ownerEmail}/files/${fileId}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${fileId}/solutions`;
  const updateSubject = subject ? subject.name !== '' : false;
  const { runSubjectCreationOnboarding, subjectCreationSteps } = useSubjectOnboarding(t, updateSubject);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = [t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];

  const previousName = subject ? subject.name : '';

  async function updateFileStats() {
    await updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
      updatedAt: new Date(),
    });
  }

  async function submitForm() {
    setIsLoading(true);
    try {
      let unavailableTimes: string[] = [];
      for (let i = 0; i < selected.length; i++) {
        unavailableTimes.push(selected[i]);
      }
      let undesiredTimes: string[] = [];
      for (let i = 0; i < selected2.length; i++) {
        undesiredTimes.push(selected2[i]);
      }
      const newSubject: ISubject = {
        color: selectedColor,
        photoURL: selectedPhotoURL,
        name: name.trim(),
        short: short,
        priority: priorityOptionsMap[selectedPriority[0]],
        unavailableTimes: unavailableTimes,
        undesiredTimes: undesiredTimes,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      const batch = writeBatch(db);
      batch.set(doc(db, subjectsPath, name.trim()), newSubject);
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });
      //Update lesson if priority has changed
      lessons.forEach((lesson) => {
        lesson.subject.forEach((sub) => {
          if (sub === newSubject.name && lesson.priority !== newSubject.priority) {
            batch.update(doc(db, lessonsPath + '/' + lesson.name), {
              priority: newSubject.priority,
            });
          }
        });
      });

      //Delete old record and update references if name has changed
      if (previousName !== '' && previousName !== name.trim()) {
        batch.delete(doc(db, subjectsPath, previousName));
        //Update lesson references
        const lessonsPath = 'users/' + ownerEmail + '/files/' + fileId + '/lessons';
        let lessonsToUpdate: ILesson[] = [];
        lessons.forEach((lesson) => {
          lesson.subject.forEach((sub) => {
            if (sub === previousName) {
              lessonsToUpdate.push(lesson);
            }
          });
        });
        lessonsToUpdate.forEach((lessonToUpdate) => {
          batch.delete(doc(db, lessonsPath, lessonToUpdate.name));
          lessonToUpdate.subject = [name.trim()];
          const newLessonName =
            lessonToUpdate.subject[0] +
            SUBJECT_CLASS_SEPARATOR +
            lessonToUpdate.classes.map((class_) => class_).toString();
          lessonToUpdate.name = newLessonName;
          batch.set(doc(db, lessonsPath, newLessonName), lessonToUpdate);
        });
        // Update name on simultaneous, non-simultaneous, and ordered lessons
        lessons.forEach((lesson) => {
          let newSimLessons: string[] = lesson.simultaneousWith;
          lesson.simultaneousWith.forEach((simLesson) => {
            if (simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] === previousName) {
              const newLessonName = name.trim() + SUBJECT_CLASS_SEPARATOR + simLesson.split(SUBJECT_CLASS_SEPARATOR)[1];
              newSimLessons = newSimLessons.filter(
                (simLesson) => simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] !== previousName
              );
              newSimLessons.push(newLessonName);
              batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                simultaneousWith: newSimLessons,
              });
            }
          });
          let newNotSimLessons: string[] = lesson.notSimultaneousWith;
          lesson.notSimultaneousWith.forEach((simLesson) => {
            if (simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] === previousName) {
              const newLessonName = name.trim() + SUBJECT_CLASS_SEPARATOR + simLesson.split(SUBJECT_CLASS_SEPARATOR)[1];
              newNotSimLessons = newNotSimLessons.filter(
                (simLesson) => simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] !== previousName
              );
              newNotSimLessons.push(newLessonName);
              batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                notSimultaneousWith: newNotSimLessons,
              });
            }
          });
          let newOccurBeforeLessons: string[] = lesson.occurBefore;
          lesson.occurBefore.forEach((simLesson) => {
            if (simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] === previousName) {
              const newLessonName = name.trim() + SUBJECT_CLASS_SEPARATOR + simLesson.split(SUBJECT_CLASS_SEPARATOR)[1];
              newOccurBeforeLessons = newOccurBeforeLessons.filter(
                (simLesson) => simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] !== previousName
              );
              newOccurBeforeLessons.push(newLessonName);
              batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                occurBefore: newOccurBeforeLessons,
              });
            }
          });
        });
      }
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      // Everything went ok!
      batch
        .commit()
        .then(() => {
          updateSubject
            ? toast.success(name.trim() + t(' updated sucessfully'))
            : toast.success(name.trim() + t(' created sucessfully'));
          setSubject(undefined);
        })
        .catch(() => {
          toast.error(t('An error has ocurred'));
          setSubject(undefined);
        });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has ocurred')}`);
      console.error({ error });
    }
  }

  const formik = useFormik<ISubject>({
    initialValues: subject,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values) => {},
  });

  const [name, setName] = React.useState<string>(formik.initialValues.name);
  const [short, setShort] = React.useState<string>(formik.initialValues.short);
  const [selectedColor, setSelectedColor] = React.useState<string>(formik.initialValues.color);
  const [selectedPhotoURL, setSelectedPhotoURL] = React.useState<string>(formik.initialValues.photoURL);

  const relatedLessonsCount = lessons.filter((lesson) => lesson.subject[0] === name).length;

  const copy = () => {
    setIsLoadingCopy(true);
    try {
      //Validation and newName adjustments
      const newName: string = t('Copy of ') + name.trim();
      if (subjects.map((subject) => subject.name).includes(newName)) {
        toast.error(newName + t(' already registered'));
        return;
      }
      const newSubject = { ...subject };
      newSubject.name = newName;
      newSubject.createdAt = new Date();
      newSubject.updatedAt = new Date();
      setDoc(doc(db, subjectsPath, newName), newSubject).then(() => {
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
        toast.success(name.trim() + t(' copied sucessfully'));
        setSubject(undefined);
      });
      updateFileStats();
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  };

  const [selectedPriority, setSelectedPriority] = React.useState<string[]>([t(formik.initialValues.priority)]);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  //#region States for and Tables

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [selected, setSelected] = React.useState<readonly string[]>(formik.initialValues.unavailableTimes);
  const [selected2, setSelected2] = React.useState<readonly string[]>(formik.initialValues.undesiredTimes);

  let cells: any[] = [];
  times.forEach((time, t_index) => {
    days.forEach((day, d_index) => {
      cells.push(d_index + DAY_TIME_SEPARATOR + t_index);
    });
  });

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const isSelected2 = (name: string) => selected2.indexOf(name) !== -1;

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selected];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
      if (cellDay === nameDay) {
        const selectedIndex = selected.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelected(newSelected);
  };

  const handleSelectAllDayClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selected2];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
      if (cellDay === nameDay) {
        const selectedIndex = selected2.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay2[nameDay as unknown as number]++;
          contSelected2++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay2[nameDay as unknown as number]--;
          contSelected2--;
        }
      }
    });
    setSelected2(newSelected);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selected];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let nameTime = name.split(DAY_TIME_SEPARATOR)[1];
      if (cellTime === nameTime) {
        const selectedIndex = selected.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelected(newSelected);
  };

  const handleSelectAllTimeClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selected2];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let nameTime = name.split(DAY_TIME_SEPARATOR)[1];
      if (cellTime === nameTime) {
        const selectedIndex = selected2.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime2[nameTime as unknown as number]++;
          contSelected2++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime2[nameTime as unknown as number]--;
          contSelected2--;
        }
      }
    });
    setSelected2(newSelected);
  };

  const handleSelectAllClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selected];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const selectedIndex = selected.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelected(newSelected);
  };

  const handleSelectAllClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selected2];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const selectedIndex = selected2.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay2[cellDay as unknown as number]++;
        contSelectedAtTime2[cellTime as unknown as number]++;
        contSelected2++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay2[cellDay as unknown as number]--;
        contSelectedAtTime2[cellTime as unknown as number]--;
        contSelected2--;
      }
    });
    setSelected2(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
    let nameTime = name.split(DAY_TIME_SEPARATOR)[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelected(newSelected);
  };

  const handleClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected2.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
    let nameTime = name.split(DAY_TIME_SEPARATOR)[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected2, name);
      contSelectedAtDay2[nameDay as unknown as number]++;
      contSelectedAtTime2[nameTime as unknown as number]++;
      contSelected2++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected2.slice(1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    } else if (selectedIndex === selected2.length - 1) {
      newSelected = newSelected.concat(selected2.slice(0, -1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected2.slice(0, selectedIndex), selected2.slice(selectedIndex + 1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    }
    setSelected2(newSelected);
  };

  let contSelectedAtDay: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay.push(0);
  });
  let contSelectedAtTime: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime.push(0);
  });
  let contSelected: number = 0;

  let contSelectedAtDay2: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay2.push(0);
  });
  let contSelectedAtTime2: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime2.push(0);
  });
  let contSelected2: number = 0;

  cells.forEach((element) => {
    let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
    let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
    const selectedIndex = selected.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay[cellDay as unknown as number]++;
      contSelectedAtTime[cellTime as unknown as number]++;
      contSelected++;
    }
  });

  cells.forEach((element) => {
    let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
    let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
    const selectedIndex = selected2.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay2[cellDay as unknown as number]++;
      contSelectedAtTime2[cellTime as unknown as number]++;
      contSelected2++;
    }
  });

  const rowCount: number = times.length;
  const colCount: number = days.length;
  const allCount: number = days.length * times.length;

  //#endregion

  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const checkConfirmation = () => {
    if (confirmed) {
      deleteDoc(doc(db, subjectsPath, subject.name));
      //Delete related lessons
      lessons
        .filter((lesson) => lesson.subject[0] === subject.name)
        .forEach((lesson) => deleteDoc(doc(db, lessonsPath, lesson.name)));
      //Set solutions to outdated
      solutions.forEach((solution) => {
        updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });

      toast.success(subject.name + t(' deleted sucessfully'));
      setSubject(undefined);
      updateFileStats();
    }
  };

  var getInitials = function (string: string, maxLength: number) {
    if (string.length <= maxLength) {
      return string.toUpperCase();
    }
    var names = string.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  };

  const handleNameChange = (newName: string) => {
    setName(newName);
    setShort(getInitials(newName, 4));
  };

  checkConfirmation();

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  const handleSave = () => {
    const existingSubjectNames = subjects.map((subject) => subject.name);
    if (!validateNameInput(t, name, previousName, existingSubjectNames)) return;
    submitForm();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);

  return (
    <>
      <Onboarding
        run={runSubjectCreationOnboarding}
        steps={subjectCreationSteps}
        storageFlagKey={SUBJECT_CREATION_KEY}
      />
      {/* <CustomSnackbar
        open={snackOpen}
      /> */}
      <VideoPlayerDialog
        open={videoPlayerDialogOpen}
        setOpen={setVideoPlayerDialogOpen}
        videoURL={t('video_url_help_subject')}
      ></VideoPlayerDialog>
      <DraggableDialog
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        setConfirmed={setConfirmed}
        title={'Warning'}
        message={`${subject.name} ${
          relatedLessonsCount === 0 ? '' : `${t('and')} ${relatedLessonsCount} ${t('linked lessons')}`
        } ${t('will be permanently deleted from this file. Are you sure?')}`}
      />
      <DraggableAvatarPicker
        open={avatarPickerOpen}
        setOpen={setAvatarPickerOpen}
        selectedColor={selectedColor}
        selectedPhotoURL={selectedPhotoURL}
        setSelectedColor={setSelectedColor}
        setSelectedPhotoURL={setSelectedPhotoURL}
        resource={subject}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" paddingBottom={8}>
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item container xs={12} spacing={1} padding={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                    <Tooltip title={t('Back')}>
                      <IconButton
                        style={{ height: 24, width: 24, marginRight: 4 }}
                        color="primary"
                        onClick={() => setSubject(undefined)}
                      >
                        <ArrowBack style={{ height: 20, width: 20 }} />
                      </IconButton>
                    </Tooltip>
                    {updateSubject ? t('Update ') + t('subject') : t('New subject')}
                  </Typography>
                  <Divider color={theme.palette.primary.main} />
                </Grid>
                <HelpButton t={t} onClick={() => setVideoPlayerDialogOpen(true)} />
                {updateSubject ? (
                  <>
                    <Grid item xs={2}>
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => setConfirmationOpen(true)}
                        color="error"
                        fullWidth
                      >
                        {t('Delete')}
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <LoadingButton
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={copy}
                        color="primary"
                        fullWidth
                        loadingPosition="start"
                        loading={isLoadingCopy}
                      >
                        {t('Copy')}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={2}>
                      <LoadingButton
                        className={'update-button'}
                        variant="contained"
                        size="small"
                        disabled={readOnly}
                        onClick={handleSave}
                        color="primary"
                        fullWidth
                        loadingPosition="start"
                        loading={isLoading}
                      >
                        {t('Update')}
                      </LoadingButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={3} md={2}>
                    <LoadingButton
                      className={'save-button'}
                      variant="contained"
                      size="small"
                      disabled={readOnly}
                      onClick={handleSave}
                      color="primary"
                      fullWidth
                      loadingPosition="start"
                      loading={isLoading}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={2} sm={1} md={1} lg={1} justifyContent={'center'}>
                <Box
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      borderRadius: 100,
                      backgroundColor: selectedColor,
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  style={{ width: 'fit-content', height: 'fit-content', padding: '0px', margin: '0px' }}
                  onClick={() => setAvatarPickerOpen(true)}
                >
                  <Avatar
                    translate="no"
                    sx={{ bgcolor: selectedColor, fontSize: short.length > 3 ? '12px' : '16px' }}
                    alt={name}
                    src={selectedPhotoURL}
                  >
                    {short}
                  </Avatar>
                </Box>
              </Grid>
              {/* Name */}
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <TextField
                  id="name"
                  required
                  fullWidth
                  size="small"
                  label={t('Name')}
                  variant="outlined"
                  translate="no"
                  defaultValue={formik.initialValues.name}
                  onChange={(e) => handleNameChange(e.target.value)}
                  InputProps={{
                    autoFocus: true, // Use the autoFocus attribute
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSave();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={3} md={3} lg={2}>
                <MultipleSelectChip
                  single={true}
                  label={t('Priority')}
                  names={priorityOptions}
                  chipColor={priorityBackgroundColors[selectedPriority[0]]}
                  personName={selectedPriority}
                  setPersonName={setSelectedPriority}
                />
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Availability')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                      <Box sx={{ width: '100%' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="scrollable"
                          allowScrollButtonsMobile
                          aria-label="wrapped label tabs example"
                        >
                          <Tab label={t('Unavailable Times')} />
                          <Tab className={'undisired-times-tab'} label={t('Undesired Times')} />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={value} index={0}>
                        <TableContainer className={'availability-form'} component={Paper}>
                          <Table size="small" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected === allCount}
                                    size="small"
                                    color="error"
                                    onClick={(event) => handleSelectAllClick(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                        size="small"
                                        color="error"
                                        onClick={(event) => handleSelectAllDayClick(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime[t_index] === colCount}
                                        size="small"
                                        color="error"
                                        onClick={(event) => handleSelectAllTimeClick(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected('' + d_index + DAY_TIME_SEPARATOR + t_index);
                                      return (
                                        <TableCell key={`cell_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}_${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="error"
                                            onClick={(event) =>
                                              handleClick(event, '' + d_index + DAY_TIME_SEPARATOR + t_index)
                                            }
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            <CloseIcon />
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <TableContainer component={Paper}>
                          <Table size="small" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected2 === allCount}
                                    size="small"
                                    color="warning"
                                    onClick={(event) => handleSelectAllClick2(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day2_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay2[d_index] === rowCount}
                                        size="small"
                                        color="warning"
                                        onClick={(event) => handleSelectAllDayClick2(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time2_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime2[t_index] === colCount}
                                        size="small"
                                        color="warning"
                                        onClick={(event) => handleSelectAllTimeClick2(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected2('' + d_index + DAY_TIME_SEPARATOR + t_index);
                                      return (
                                        <TableCell key={`cell2_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}:${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="warning"
                                            onClick={(event) =>
                                              handleClick2(event, '' + d_index + DAY_TIME_SEPARATOR + t_index)
                                            }
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            <RemoveIcon />
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Related lessons')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                  <Grid className={'related-lessons'} item xs={12} sx={{ paddingTop: '0px !important' }}>
                    <LessonsOfSubject subjectFilter={name}></LessonsOfSubject>
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </>
  );
}
