/* eslint-disable eqeqeq */
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doc, deleteDoc, updateDoc, writeBatch } from '@firebase/firestore';

import { db } from 'services';
import { SessionContext } from 'contexts';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import DraggableAvatarPicker from 'components/DraggableAvatarPicker';
import DraggableShowConstraints from 'components/DraggableShowConstraints';
import { IAssignment } from 'pages/Solutions/Solution';
import { useTranslation } from 'react-i18next';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MinMaxSlider, { getAndMoreHelper } from 'components/MinMaxSlider';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import {
  ArrowBack,
  Chair,
  Close,
  DateRange,
  DoubleArrow,
  EmojiTransportation,
  EventRepeat,
  Hotel,
  LinearScale,
  MenuBook,
  MoreTime,
  Remove,
  TransferWithinAStation,
  Visibility,
  WorkHistory,
} from '@mui/icons-material';
import {
  useMediaQuery,
  SelectChangeEvent,
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Button,
  Avatar,
  TextField,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  ToggleButton,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import MultipleSelectChip from 'components/Select/MultipleSelectChip';
import { validateNameInput } from 'util/validationUtils';
import { priorityOptionsMap } from 'util/configUtils';
import { ILesson } from 'pages/Lessons/utils/types';
import { RESOURCE_CREATION_KEY, useResourceOnboarding } from './utils/useResourceOnboarding';
import Onboarding from 'components/Onboarding';
import { theme } from 'styles';
import HelpButton from 'components/Button/HelpButton';
import { DAY_TIME_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';
import { priorityBackgroundColors } from 'styles/priorityColors';
import SelectAllDialog from 'components/Dialog/SelectAllDialog';
import { LoadingButton } from '@mui/lab';

export interface IResource {
  color: string;
  photoURL: string;
  name: string;
  short: string;
  type: string;
  minWorkload: number;
  maxWorkload: number;
  minWorkingDays: number;
  maxWorkingDays: number;
  minIdleWindow: number;
  maxIdleWindow: number;
  minDailyWorkload: number;
  maxDailyWorkload: number;
  minRestBetweenDays: number;
  maxRestBetweenDays: number;
  minRoomChangesDay: number;
  maxRoomChangesDay: number;
  minDistinctSubjects: number;
  maxDistinctSubjects: number;
  minConsecutiveTimes: number;
  maxConsecutiveTimes: number;
  travelTimeRooms: string[];
  minTravelTime: number;
  priority: string;
  forbiddenCombination: number[];
  unavailableTimes: string[];
  undesiredTimes: string[];
  createdAt: Date;
  updatedAt: Date;
}

type Props = {
  resource: IResource;
  setResource: Dispatch<SetStateAction<IResource | undefined>>;
  type: string;
};
export default function Resource({ resource, setResource, type }: Props) {
  const { t } = useTranslation();
  // const type = props.type;
  const { user, file, lessons, resources, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const fileId = file?.id || file?.name;
  const resourcesPath = 'users/' + ownerEmail + '/files/' + fileId + '/resources';
  const lessonsPath = `users/${ownerEmail}/files/${fileId}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${fileId}/solutions`;
  // const history = useHistory();
  const updateResource = resource ? resource.name !== '' : false;
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const readOnly = ownerEmail !== user?.email && shareMode === 'read';
  const { runResourceCreationOnboarding, resourceCreationSteps } = useResourceOnboarding(t, type, updateResource);

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = [t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];

  const [selectedForWorkload, setSelectedForWorkload] = useState<string[]>([]);
  const [selectedForWorkingDays, setSelectedForWorkingDays] = useState<string[]>([]);
  const [selectedForDailyWorkload, setSelectedForDailyWorkload] = useState<string[]>([]);
  const [selectedForIdleWindow, setSelectedForIdleWindow] = useState<string[]>([]);
  const [selectedForRestBetweenDays, setSelectedForRestBetweenDays] = useState<string[]>([]);
  const [selectedForRoomChangesDay, setSelectedForRoomChangesDay] = useState<string[]>([]);
  const [selectedForConsecutiveTimes, setSelectedForConsecutiveTimes] = useState<string[]>([]);
  const [selectedForTravelTime, setSelectedForTravelTime] = useState<string[]>([]);
  const [selectedForDistinctSubjects, setSelectedForDistinctSubjects] = useState<string[]>([]);
  const [selectedForForbiddenCombination, setSelectedForForbiddenCombination] = useState<string[]>([]);

  function redirectLabel() {
    if (type === 'class') {
      return 'classes';
    } else if (type === 'teacher') {
      return 'teachers';
    } else if (type === 'room') {
      return 'rooms';
    } else {
      return 'resources';
    }
  }

  function getLabelFromType() {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('classes') : t('students');
    } else if (type === 'teacher') {
      return t('teachers');
    } else if (type === 'room') {
      return t('rooms');
    } else {
      return t('resources');
    }
  }

  async function updateFileStats() {
    await updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
      updatedAt: new Date(),
    });
  }

  const previousName: string = resource ? resource.name : '';

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);

  async function submitForm() {
    try {
      setIsLoading(true);
      let unavailableTimes: string[] = [];
      for (let i = 0; i < selectedUnavailable.length; i++) {
        unavailableTimes.push(selectedUnavailable[i]);
      }
      let undesiredTimes: string[] = [];
      for (let i = 0; i < selectedUndesired.length; i++) {
        undesiredTimes.push(selectedUndesired[i]);
      }
      let forbiddenCombination: number[] = [];
      for (let i = 0; i < selectedDays.length; i++) {
        forbiddenCombination.push(days.indexOf(selectedDays[i]));
      }
      const newResource: IResource = {
        color: selectedColor,
        photoURL: selectedPhotoURL,
        name: name.trim(),
        short: short,
        type: type,
        minWorkload: +selectedMinWorkload,
        maxWorkload: +selectedMaxWorkload,
        minWorkingDays: +selectedMinWorkingDays,
        maxWorkingDays: +selectedMaxWorkingDays,
        minIdleWindow: +selectedMinIdleWindow,
        maxIdleWindow: +selectedMaxIdleWindow,
        minDailyWorkload: +selectedMinDailyWorkload,
        maxDailyWorkload: +selectedMaxDailyWorkload,
        minRestBetweenDays: +selectedMinRestBetweenDays,
        maxRestBetweenDays: +selectedMaxRestBetweenDays,
        minRoomChangesDay: +selectedMinRoomChangesDay,
        maxRoomChangesDay: +selectedMaxRoomChangesDay,
        minDistinctSubjects: +selectedMinDistinctSubjects,
        maxDistinctSubjects: +selectedMaxDistinctSubjects,
        minConsecutiveTimes: +selectedMinConsecutiveTimes,
        maxConsecutiveTimes: +selectedMaxConsecutiveTimes,
        travelTimeRooms: selectedTravelTimeRooms,
        minTravelTime: +selectedMinTravelTime,
        priority: priorityOptionsMap[selectedPriority[0]],
        forbiddenCombination: forbiddenCombination,
        unavailableTimes: unavailableTimes,
        undesiredTimes: undesiredTimes,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      const batch = writeBatch(db);
      batch.set(doc(db, resourcesPath, name.trim()), newResource);
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });

      //Check if Constraints Apply to More
      const resourcesOfType = resources.filter((res) => res.type === type);
      if (selectedForWorkload.length > 0) {
        resourcesOfType
          .filter((res) => selectedForWorkload.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minWorkload: +selectedMinWorkload,
              maxWorkload: +selectedMaxWorkload,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForWorkingDays.length > 0) {
        resourcesOfType
          .filter((res) => selectedForWorkingDays.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minWorkingDays: +selectedMinWorkingDays,
              maxWorkingDays: +selectedMaxWorkingDays,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForIdleWindow.length > 0) {
        resourcesOfType
          .filter((res) => selectedForIdleWindow.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minIdleWindow: +selectedMinIdleWindow,
              maxIdleWindow: +selectedMaxIdleWindow,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForDailyWorkload.length > 0) {
        resourcesOfType
          .filter((res) => selectedForDailyWorkload.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minDailyWorkload: +selectedMinDailyWorkload,
              maxDailyWorkload: +selectedMaxDailyWorkload,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForRestBetweenDays.length > 0) {
        resourcesOfType
          .filter((res) => selectedForRestBetweenDays.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minRestBetweenDays: +selectedMinRestBetweenDays,
              maxRestBetweenDays: +selectedMaxRestBetweenDays,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForRoomChangesDay.length > 0) {
        resourcesOfType
          .filter((res) => selectedForRoomChangesDay.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minRoomChangesDay: +selectedMinRoomChangesDay,
              maxRoomChangesDay: +selectedMaxRoomChangesDay,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForConsecutiveTimes.length > 0) {
        resourcesOfType
          .filter((res) => selectedForConsecutiveTimes.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minConsecutiveTimes: +selectedMinConsecutiveTimes,
              maxConsecutiveTimes: +selectedMaxConsecutiveTimes,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForDistinctSubjects.length > 0) {
        resourcesOfType
          .filter((res) => selectedForDistinctSubjects.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              minDistinctSubjects: +selectedMinDistinctSubjects,
              maxDistinctSubjects: +selectedMaxDistinctSubjects,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForTravelTime.length > 0) {
        resourcesOfType
          .filter((res) => selectedForTravelTime.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              travelTimeRooms: selectedTravelTimeRooms,
              minTravelTime: +selectedMinTravelTime,
              updatedAt: new Date(),
            })
          );
      }
      if (selectedForForbiddenCombination.length > 0) {
        resourcesOfType
          .filter((res) => selectedForForbiddenCombination.includes(res.name))
          .forEach((resType) =>
            batch.update(doc(db, resourcesPath + '/' + resType.name), {
              forbiddenCombination: forbiddenCombination,
              updatedAt: new Date(),
            })
          );
      }
      // Delete old record and update references if name has changed
      if (previousName !== '' && previousName !== name.trim()) {
        batch.delete(doc(db, resourcesPath, previousName));
        //Update lesson references
        const lessonsPath = 'users/' + ownerEmail + '/files/' + fileId + '/lessons';
        if (type === 'class') {
          let lessonsToUpdate: ILesson[] = [];
          lessons.forEach((lesson) => {
            lesson.classes.forEach((class_) => {
              if (class_ === previousName) {
                lessonsToUpdate.push(lesson);
              }
            });
          });
          lessonsToUpdate.forEach((lessonToUpdate) => {
            batch.delete(doc(db, lessonsPath, lessonToUpdate.name));
            lessonToUpdate.classes.splice(lessonToUpdate.classes.indexOf(previousName), 1);
            lessonToUpdate.classes.push(name.trim());
            const newLessonName =
              lessonToUpdate.subject[0] +
              SUBJECT_CLASS_SEPARATOR +
              lessonToUpdate.classes.map((class_) => class_).toString();
            lessonToUpdate.name = newLessonName;
            batch.set(doc(db, lessonsPath, newLessonName), lessonToUpdate);
          });
          // Update name on simultaneous, non-simultaneous, and ordered lessons
          lessons.forEach((lesson) => {
            let newSimLessons: string[] = lesson.simultaneousWith;
            lesson.simultaneousWith.forEach((simLesson) => {
              if (simLesson.split(SUBJECT_CLASS_SEPARATOR)[1] === previousName) {
                const newLessonName =
                  simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] + SUBJECT_CLASS_SEPARATOR + name.trim();
                newSimLessons = newSimLessons.filter(
                  (simLesson) => simLesson.split(SUBJECT_CLASS_SEPARATOR)[1] !== previousName
                );
                newSimLessons.push(newLessonName);
                batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                  simultaneousWith: newSimLessons,
                });
              }
            });
            let newNotSimLessons: string[] = lesson.notSimultaneousWith;
            lesson.notSimultaneousWith.forEach((simLesson) => {
              if (simLesson.split(SUBJECT_CLASS_SEPARATOR)[1] === previousName) {
                const newLessonName =
                  simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] + SUBJECT_CLASS_SEPARATOR + name.trim();
                newNotSimLessons = newNotSimLessons.filter(
                  (simLesson) => simLesson.split(SUBJECT_CLASS_SEPARATOR)[1] !== previousName
                );
                newNotSimLessons.push(newLessonName);
                batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                  notSimultaneousWith: newNotSimLessons,
                });
              }
            });
            let newOccurBeforeLessons: string[] = lesson.occurBefore;
            lesson.occurBefore.forEach((simLesson) => {
              if (simLesson.split(SUBJECT_CLASS_SEPARATOR)[1] === previousName) {
                const newLessonName =
                  simLesson.split(SUBJECT_CLASS_SEPARATOR)[0] + SUBJECT_CLASS_SEPARATOR + name.trim();
                newOccurBeforeLessons = newOccurBeforeLessons.filter(
                  (simLesson) => simLesson.split(SUBJECT_CLASS_SEPARATOR)[1] !== previousName
                );
                newOccurBeforeLessons.push(newLessonName);
                batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                  occurBefore: newOccurBeforeLessons,
                });
              }
            });
          });
        } else if (type === 'teacher') {
          let lessonsToUpdate: ILesson[] = [];
          lessons.forEach((lesson) => {
            lesson.teachers.forEach((teacher) => {
              if (teacher === previousName) {
                lessonsToUpdate.push(lesson);
              }
            });
          });
          lessonsToUpdate.forEach((lessonToUpdate) => {
            lessonToUpdate.teachers.splice(lessonToUpdate.teachers.indexOf(previousName), 1);
            lessonToUpdate.teachers.push(name.trim());
            batch.set(doc(db, lessonsPath, lessonToUpdate.name), lessonToUpdate);
          });
        } else if (type === 'room') {
          let lessonsToUpdate: ILesson[] = [];
          lessons.forEach((lesson) => {
            lesson.rooms.forEach((room) => {
              if (room === previousName) {
                lessonsToUpdate.push(lesson);
              }
            });
          });
          lessonsToUpdate.forEach((lessonToUpdate) => {
            lessonToUpdate.rooms.splice(lessonToUpdate.rooms.indexOf(previousName), 1);
            lessonToUpdate.rooms.push(name.trim());
            batch.set(doc(db, lessonsPath, lessonToUpdate.name), lessonToUpdate);
          });
        }
        //Update solution references
        const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
        solutions.forEach((solution) => {
          let updatedAssignments: IAssignment[] = solution.assignments;
          let needsUpdate = false;
          solution.assignments.forEach((assignment, index) => {
            let splitAssignment = assignment.lessonRef.split(SUBJECT_CLASS_SEPARATOR);
            let assigmentSubject = splitAssignment[0];
            let assigmentClasses = splitAssignment.length > 1 ? splitAssignment[1] : '';
            if (assigmentClasses.includes(previousName)) {
              const newLessonRef =
                assigmentSubject + SUBJECT_CLASS_SEPARATOR + assigmentClasses.replace(previousName, name.trim());
              updatedAssignments[index].lessonRef = newLessonRef;
              updatedAssignments[index].resources.splice(updatedAssignments[index].resources.indexOf(previousName), 1);
              updatedAssignments[index].resources.push(name.trim());
              needsUpdate = true;
            } else {
              if (assignment.resources.includes(previousName)) {
                updatedAssignments[index].resources.splice(
                  updatedAssignments[index].resources.indexOf(previousName),
                  1
                );
                updatedAssignments[index].resources.push(name.trim());
                needsUpdate = true;
              }
            }
          });
          if (needsUpdate) {
            solution.assignments = updatedAssignments;
            batch.update(doc(db, solutionsPath + '/' + solution.name), {
              assignments: updatedAssignments,
            });
          }
        });
      }
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      // Everything went ok!
      batch
        .commit()
        .then(() => {
          updateResource
            ? toast.success(name.trim() + t(' updated sucessfully'))
            : toast.success(name.trim() + t(' created sucessfully'));
          setResource(undefined);
        })
        .catch(() => {
          toast.error(t('An error has ocurred'));
          setResource(undefined);
        });
    } catch (error: any) {
      toast.error(error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has ocurred'));
      console.error({ error });
    }
  }

  const formik = useFormik<IResource>({
    initialValues: resource,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Required field'),
      // short: Yup.string().required('Required field'),
    }),
    onSubmit: async (values) => {
      submitForm();
    },
  });

  function dayIdxToStr(indexes: number[]) {
    return indexes.map((index) => days[index]);
  }

  const [name, setName] = React.useState<string>(formik.initialValues.name);
  const [short, setShort] = React.useState<string>(formik.initialValues.short);
  const [selectedColor, setSelectedColor] = React.useState<string>(formik.initialValues.color);
  const [selectedPhotoURL, setSelectedPhotoURL] = React.useState<string>(formik.initialValues.photoURL);
  const [selectedDays, setSelectedDays] = React.useState<string[]>(
    dayIdxToStr(formik.initialValues.forbiddenCombination)
  );

  const [showWorkload, setShowWorkload] = React.useState<boolean>(
    resource.minWorkload !== 0 || resource.maxWorkload !== days.length * times.length
      ? true
      : type === 'class'
      ? (file?.showWorkloadClasses as boolean)
      : type === 'teacher'
      ? (file?.showWorkloadTeachers as boolean)
      : type === 'room'
      ? (file?.showWorkloadRooms as boolean)
      : false
  );
  const [showWorkingDays, setShowWorkingDays] = React.useState<boolean>(
    resource.minWorkingDays !== 0 || resource.maxWorkingDays !== days.length
      ? true
      : type === 'class'
      ? (file?.showWorkingDaysClasses as boolean)
      : type === 'teacher'
      ? (file?.showWorkingDaysTeachers as boolean)
      : type === 'room'
      ? (file?.showWorkingDaysRooms as boolean)
      : false
  );
  const [showIdleWindow, setShowIdleWindow] = React.useState<boolean>(
    resource.minIdleWindow !== 0 || resource.maxIdleWindow !== times.length - 2
      ? true
      : type === 'class'
      ? (file?.showIdleWindowClasses as boolean)
      : type === 'teacher'
      ? (file?.showIdleWindowTeachers as boolean)
      : type === 'room'
      ? (file?.showIdleWindowRooms as boolean)
      : false
  );
  const [showDailyWorkload, setShowDailyWorkload] = React.useState<boolean>(
    resource.minDailyWorkload !== 0 || resource.maxDailyWorkload !== times.length
      ? true
      : type === 'class'
      ? (file?.showDailyWorkloadClasses as boolean)
      : type === 'teacher'
      ? (file?.showDailyWorkloadTeachers as boolean)
      : type === 'room'
      ? (file?.showDailyWorkloadRooms as boolean)
      : false
  );
  const [showRestBetweenDays, setShowRestBetweenDays] = React.useState<boolean>(
    resource.minRestBetweenDays !== 0 || resource.maxRestBetweenDays !== times.length * 2 - 2
      ? true
      : type === 'class'
      ? (file?.showRestBetweenDaysClasses as boolean)
      : type === 'teacher'
      ? (file?.showRestBetweenDaysTeachers as boolean)
      : type === 'room'
      ? (file?.showRestBetweenDaysRooms as boolean)
      : false
  );
  const [showRoomChangesDay, setShowRoomChangesDay] = React.useState<boolean>(
    resource.minRoomChangesDay !== 0 || resource.maxRoomChangesDay !== times.length - 1
      ? true
      : type === 'class'
      ? (file?.showRoomChangesDayClasses as boolean)
      : type === 'teacher'
      ? (file?.showRoomChangesDayTeachers as boolean)
      : type === 'room'
      ? (file?.showRoomChangesDayRooms as boolean)
      : false
  );
  const [showConsecutiveTimes, setShowConsecutiveTimes] = React.useState<boolean>(
    resource.minConsecutiveTimes !== 0 || resource.maxConsecutiveTimes !== times.length
      ? true
      : type === 'class'
      ? (file?.showConsecutiveTimesClasses as boolean)
      : type === 'teacher'
      ? (file?.showConsecutiveTimesTeachers as boolean)
      : type === 'room'
      ? (file?.showConsecutiveTimesRooms as boolean)
      : false
  );
  const [showDistinctSubjects, setShowDistinctSubjects] = React.useState<boolean>(
    resource.minDistinctSubjects !== 0 || resource.maxDistinctSubjects !== days.length * times.length
      ? true
      : type === 'class'
      ? (file?.showDistinctSubjectsClasses as boolean)
      : type === 'teacher'
      ? (file?.showDistinctSubjectsTeachers as boolean)
      : type === 'room'
      ? (file?.showDistinctSubjectsRooms as boolean)
      : false
  );
  const [showTravelTime, setShowTravelTime] = React.useState<boolean>(
    resource.travelTimeRooms.length !== 0 && resource.minTravelTime !== 0
      ? true
      : type === 'room'
      ? (file?.showTravelTimeRooms as boolean)
      : false
  );

  const [showForbiddenCombination, setShowForbiddenCombination] = React.useState<boolean>(
    resource.forbiddenCombination.length !== 0
      ? true
      : type === 'class'
      ? (file?.showForbiddenCombinationClasses as boolean)
      : type === 'teacher'
      ? (file?.showForbiddenCombinationTeachers as boolean)
      : type === 'room'
      ? (file?.showForbiddenCombinationRooms as boolean)
      : false
  );

  const copy = () => {
    setIsLoadingCopy(true);
    try {
      //Validation and newName adjustments
      const newName: string = t('Copy of ') + name.trim();
      if (resources.map((resource) => resource.name).includes(newName)) {
        toast.error(newName + t(' already registered'));
        return;
      }
      const newResource = { ...resource };
      newResource.name = newName;
      newResource.createdAt = new Date();
      newResource.updatedAt = new Date();
      const batch = writeBatch(db);
      batch.set(doc(db, resourcesPath, newName), newResource);
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        toast.success(name.trim() + t(' copied sucessfully'));
        setResource(undefined);

        // history.push('/' + redirectLabel());
      });
    } catch (error: any) {
      toast.error(error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred'));
      console.error({ error });
    }
  };

  const [selectedPriority, setSelectedPriority] = React.useState<string[]>([t(formik.initialValues.priority)]);

  const [selectedWorkload, setSelectedWorkload] = React.useState(false);
  const [selectedMinWorkload, setSelectedMinWorkload] = React.useState<number>(formik.initialValues.minWorkload);
  const [selectedMaxWorkload, setSelectedMaxWorkload] = React.useState<number>(formik.initialValues.maxWorkload);

  const [selectedWorkingDays, setSelectedWorkingDays] = React.useState(false);
  const [selectedMinWorkingDays, setSelectedMinWorkingDays] = React.useState<number>(
    formik.initialValues.minWorkingDays
  );
  const [selectedMaxWorkingDays, setSelectedMaxWorkingDays] = React.useState<number>(
    formik.initialValues.maxWorkingDays
  );

  const [selectedIdleWindow, setSelectedIdleWindow] = React.useState(false);
  const [selectedMinIdleWindow, setSelectedMinIdleWindow] = React.useState<number>(formik.initialValues.minIdleWindow);
  const [selectedMaxIdleWindow, setSelectedMaxIdleWindow] = React.useState<number>(formik.initialValues.maxIdleWindow);

  const [selectedDailyWorkload, setSelectedDailyWorkload] = React.useState(false);
  const [selectedMinDailyWorkload, setSelectedMinDailyWorkload] = React.useState<number>(
    formik.initialValues.minDailyWorkload
  );
  const [selectedMaxDailyWorkload, setSelectedMaxDailyWorkload] = React.useState<number>(
    formik.initialValues.maxDailyWorkload
  );

  const [selectedRestBetweenDays, setSelectedRestBetweenDays] = React.useState(false);
  const [selectedMinRestBetweenDays, setSelectedMinRestBetweenDays] = React.useState<number>(
    formik.initialValues.minRestBetweenDays
  );
  const [selectedMaxRestBetweenDays, setSelectedMaxRestBetweenDays] = React.useState<number>(
    formik.initialValues.maxRestBetweenDays
  );

  const [selectedRoomChangesDay, setSelectedRoomChangesDay] = React.useState(false);
  const [selectedMinRoomChangesDay, setSelectedMinRoomChangesDay] = React.useState<number>(
    formik.initialValues.minRoomChangesDay
  );
  const [selectedMaxRoomChangesDay, setSelectedMaxRoomChangesDay] = React.useState<number>(
    formik.initialValues.maxRoomChangesDay
  );

  const [selectedConsecutiveTimes, setSelectedConsecutiveTimes] = React.useState(false);
  const [selectedMinConsecutiveTimes, setSelectedMinConsecutiveTimes] = React.useState<number>(
    formik.initialValues.minConsecutiveTimes
  );
  const [selectedMaxConsecutiveTimes, setSelectedMaxConsecutiveTimes] = React.useState<number>(
    formik.initialValues.maxConsecutiveTimes
  );

  const [selectedTravelTime, setSelectedTravelTime] = React.useState(false);
  const [selectedTravelTimeRooms, setSelectedTravelTimeRooms] = React.useState<string[]>(
    formik.initialValues.travelTimeRooms
  );
  const [selectedMinTravelTime, setSelectedMinTravelTime] = React.useState<number>(formik.initialValues.minTravelTime);
  const handleChangeMinTravelTime = (event: SelectChangeEvent) => {
    setSelectedMinTravelTime(event.target.value as unknown as number);
  };

  const [selectedDistinctSubjects, setSelectedDistinctSubjects] = React.useState(false);
  const [selectedMinDistinctSubjects, setSelectedMinDistinctSubjects] = React.useState<number>(
    formik.initialValues.minDistinctSubjects
  );
  const [selectedMaxDistinctSubjects, setSelectedMaxDistinctSubjects] = React.useState<number>(
    formik.initialValues.maxDistinctSubjects
  );

  const [selectedForbiddenCombination, setSelectedForbiddenCombination] = React.useState(false);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //#region States for and Tables

  const [selectedUnavailable, setSelectedUnavailable] = React.useState<readonly string[]>(
    formik.initialValues.unavailableTimes
  );
  const [selectedUndesired, setSelectedUndesired] = React.useState<readonly string[]>(
    formik.initialValues.undesiredTimes
  );

  let cells: any[] = [];
  times.forEach((time, t_index) => {
    days.forEach((day, d_index) => {
      cells.push(d_index + DAY_TIME_SEPARATOR + t_index);
    });
  });

  const isSelected = (name: string) => selectedUnavailable.indexOf(name) !== -1;
  const isSelected2 = (name: string) => selectedUndesired.indexOf(name) !== -1;

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUnavailable];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedUnavailable.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedUnavailable(newSelected);
  };

  const handleSelectAllDayClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUndesired];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedUndesired.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay2[nameDay as unknown as number]++;
          contSelected2++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay2[nameDay as unknown as number]--;
          contSelected2--;
        }
      }
    });
    setSelectedUndesired(newSelected);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUnavailable];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let nameTime = name.split(DAY_TIME_SEPARATOR)[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedUnavailable.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedUnavailable(newSelected);
  };

  const handleSelectAllTimeClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUndesired];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let nameTime = name.split(DAY_TIME_SEPARATOR)[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedUndesired.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime2[nameTime as unknown as number]++;
          contSelected2++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime2[nameTime as unknown as number]--;
          contSelected2--;
        }
      }
    });
    setSelectedUndesired(newSelected);
  };

  const handleSelectAllClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUnavailable];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const selectedIndex = selectedUnavailable.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelectedUnavailable(newSelected);
  };

  const handleSelectAllClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUndesired];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const selectedIndex = selectedUndesired.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay2[cellDay as unknown as number]++;
        contSelectedAtTime2[cellTime as unknown as number]++;
        contSelected2++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay2[cellDay as unknown as number]--;
        contSelectedAtTime2[cellTime as unknown as number]--;
        contSelected2--;
      }
    });
    setSelectedUndesired(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedUnavailable.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
    let nameTime = name.split(DAY_TIME_SEPARATOR)[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUnavailable, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUnavailable.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selectedUnavailable.length - 1) {
      newSelected = newSelected.concat(selectedUnavailable.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUnavailable.slice(0, selectedIndex),
        selectedUnavailable.slice(selectedIndex + 1)
      );
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelectedUnavailable(newSelected);
  };

  const handleClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedUndesired.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
    let nameTime = name.split(DAY_TIME_SEPARATOR)[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUndesired, name);
      contSelectedAtDay2[nameDay as unknown as number]++;
      contSelectedAtTime2[nameTime as unknown as number]++;
      contSelected2++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUndesired.slice(1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    } else if (selectedIndex === selectedUndesired.length - 1) {
      newSelected = newSelected.concat(selectedUndesired.slice(0, -1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUndesired.slice(0, selectedIndex),
        selectedUndesired.slice(selectedIndex + 1)
      );
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    }
    setSelectedUndesired(newSelected);
  };

  let contSelectedAtDay: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay.push(0);
  });
  let contSelectedAtTime: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime.push(0);
  });
  let contSelected: number = 0;

  let contSelectedAtDay2: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay2.push(0);
  });
  let contSelectedAtTime2: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime2.push(0);
  });
  let contSelected2: number = 0;

  cells.forEach((element) => {
    let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
    let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
    const selectedIndex = selectedUnavailable.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay[cellDay as unknown as number]++;
      contSelectedAtTime[cellTime as unknown as number]++;
      contSelected++;
    }
  });

  cells.forEach((element) => {
    let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
    let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
    const selectedIndex = selectedUndesired.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay2[cellDay as unknown as number]++;
      contSelectedAtTime2[cellTime as unknown as number]++;
      contSelected2++;
    }
  });

  const rowCount: number = times.length;
  const colCount: number = days.length;
  const allCount: number = days.length * times.length;

  //#endregion

  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      deleteDoc(doc(db, resourcesPath, resource.name));
      //Delete related lessons
      lessons
        .filter(
          (lesson) =>
            lesson.classes.includes(resource.name) ||
            lesson.teachers.includes(resource.name) ||
            lesson.rooms.includes(resource.name)
        )
        .forEach((lesson) => deleteDoc(doc(db, lessonsPath, lesson.name)));
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });

      toast.success(resource.name + ' deleted sucessfully');
      setResource(undefined);
      // history.push('/' + redirectLabel());
      updateFileStats();
    }
  };
  checkDeleteConfirmation();

  const [showConstraintsOpen, setShowConstraintsOpen] = useState(false);
  const [showConstraintsConfirmed, setShowConstraintsConfirmed] = useState(false);

  const checkShowConstraintsConfirmation = () => {
    if (showConstraintsConfirmed) {
      setShowConstraintsConfirmed(false);
      if (type === 'class')
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          showWorkloadClasses: showWorkload,
          showWorkingDaysClasses: showWorkingDays,
          showIdleWindowClasses: showIdleWindow,
          showDailyWorkloadClasses: showDailyWorkload,
          showRestBetweenDaysClasses: showRestBetweenDays,
          showRoomChangesDayClasses: showRoomChangesDay,
          showConsecutiveTimesClasses: showConsecutiveTimes,
          showTravelTimeClasses: showTravelTime,
          showDistinctSubjectsClasses: showDistinctSubjects,
          showForbiddenCombinationClasses: showForbiddenCombination,
        });
      else if (type === 'teacher')
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          showWorkloadTeachers: showWorkload,
          showWorkingDaysTeachers: showWorkingDays,
          showIdleWindowTeachers: showIdleWindow,
          showDailyWorkloadTeachers: showDailyWorkload,
          showRestBetweenDaysTeachers: showRestBetweenDays,
          showRoomChangesDayTeachers: showRoomChangesDay,
          showConsecutiveTimesTeachers: showConsecutiveTimes,
          showTravelTimeTeachers: showTravelTime,
          showDistinctSubjectsTeachers: showDistinctSubjects,
          showForbiddenCombinationTeachers: showForbiddenCombination,
        });
      else if (type === 'room')
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          showWorkloadRooms: showWorkload,
          showWorkingDaysRooms: showWorkingDays,
          showIdleWindowRooms: showIdleWindow,
          showDailyWorkloadRooms: showDailyWorkload,
          showRestBetweenDaysRooms: showRestBetweenDays,
          showRoomChangesDayRooms: showRoomChangesDay,
          showConsecutiveTimesRooms: showConsecutiveTimes,
          showTravelTimeRooms: showTravelTime,
          showDistinctSubjectsRooms: showDistinctSubjects,
          showForbiddenCombinationRooms: showForbiddenCombination,
        });
    }
  };
  checkShowConstraintsConfirmation();

  const [toAllConfirmationOpen, setToAllConfirmationOpen] = useState(false);
  const [toAllConfirmed, setToAllConfirmed] = useState(false);

  const checkToAllConfirmation = () => {
    if (toAllConfirmed) {
      setToAllConfirmed(false);
      submitForm();
    }
  };
  checkToAllConfirmation();

  var getInitials = function (string: string, maxLength: number) {
    if (string.length <= maxLength) {
      return string.toUpperCase();
    }
    var names = string.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  };

  const handleNameChange = (newName: string) => {
    setName(newName);
    setShort(getInitials(newName, 4));
  };

  // const StyledButton = styled(Button)`
  //   pointer-events: none;
  //   &:hover {
  //     cursor: default;
  //     background-color: primary;
  //   }
  // `;

  const linkedLessonsCount = lessons.filter(
    (lesson) =>
      lesson.classes.includes(resource.name) ||
      lesson.teachers.includes(resource.name) ||
      lesson.rooms.includes(resource.name)
  ).length;

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  function validateTravelTimeConstr() {
    if (Number(selectedMinTravelTime) !== 0 && selectedTravelTimeRooms.length === 0) {
      toast.error(t('You need to select the rooms to which free timeslot(s) to commute time are required'));
      return false;
    }
    if (Number(selectedMinTravelTime) === 0 && selectedTravelTimeRooms.length > 0) {
      toast.error(
        t('You need to select how many free timeslot(s) are needed to commute from ') +
          { name } +
          t(' to ') +
          selectedTravelTimeRooms.join(', ')
      );
      return false;
    }
    return true;
  }

  const handleSave = () => {
    const existingResourceNames = resources.map((resource) => resource.name);
    if (!validateNameInput(t, name, previousName, existingResourceNames) || !validateTravelTimeConstr()) return;
    selectedForWorkload.length > 0 ||
    selectedForWorkingDays.length > 0 ||
    selectedForDailyWorkload.length > 0 ||
    selectedForIdleWindow.length > 0 ||
    selectedForRestBetweenDays.length > 0 ||
    selectedForRoomChangesDay.length > 0 ||
    selectedForConsecutiveTimes.length > 0 ||
    selectedForDistinctSubjects.length > 0 ||
    selectedForTravelTime.length > 0 ||
    selectedForForbiddenCombination.length > 0
      ? setToAllConfirmationOpen(true)
      : submitForm();
  };

  function getApplyToMoreConfirmationMessage() {
    return (
      <Stack>
        <Typography paddingBottom={2}>{`${t(
          'The following constraints will apply to other'
        )} ${getLabelFromType()}:`}</Typography>
        {selectedForWorkload.length > 0 && (
          <Typography>{` - ${t('Working Times')}: ${selectedForWorkload.join(', ')}`}</Typography>
        )}
        {selectedForWorkingDays.length > 0 && (
          <Typography>{` - ${t('Working Days')}: ${selectedForWorkingDays.join(', ')}`}</Typography>
        )}
        {selectedForIdleWindow.length > 0 && (
          <Typography>{` - ${t('Idle Window')}: ${selectedForIdleWindow.join(', ')}`}</Typography>
        )}
        {selectedForDailyWorkload.length > 0 && (
          <Typography>{` - ${t('Daily Workload')}: ${selectedForDailyWorkload.join(', ')}`}</Typography>
        )}
        {selectedForRestBetweenDays.length > 0 && (
          <Typography>{` - ${t('Rest between Days')}: ${selectedForRestBetweenDays.join(', ')}`}</Typography>
        )}
        {selectedForRoomChangesDay.length > 0 && (
          <Typography>{` - ${t('Rooms per Day')}: ${selectedForRoomChangesDay.join(', ')}`}</Typography>
        )}
        {selectedForConsecutiveTimes.length > 0 && (
          <Typography>{` - ${t('Consecutive Times')}: ${selectedForConsecutiveTimes.join(', ')}`}</Typography>
        )}
        {selectedForDistinctSubjects.length > 0 && (
          <Typography>{` - ${t('Distinct Subjects')}: ${selectedForDistinctSubjects.join(', ')}`}</Typography>
        )}
        {selectedForTravelTime.length > 0 && (
          <Typography>{` - ${t('Travel Time')}: ${selectedForTravelTime.join(', ')}`}</Typography>
        )}
        {selectedForForbiddenCombination.length > 0 && (
          <Typography>{` - ${t('Forbidden Combination')}: ${selectedForForbiddenCombination.join(', ')}`}</Typography>
        )}
        <Typography paddingTop={2}>{`${t('Are you sure?')}`}</Typography>
      </Stack>
    );
  }

  const [isSelectMoreTravelTimeDialogOpen, setIsSelectMoreTravelTimeDialogOpen] = useState(false);
  const [isSelectMoreForbiddenCombinationDialogOpen, setIsSelectMoreForbiddenCombinationDialogOpen] = useState(false);

  return (
    <>
      <Onboarding
        run={runResourceCreationOnboarding}
        steps={resourceCreationSteps}
        storageFlagKey={RESOURCE_CREATION_KEY}
      />
      <SelectAllDialog
        open={isSelectMoreTravelTimeDialogOpen}
        setOpen={setIsSelectMoreTravelTimeDialogOpen}
        title={t('Apply this constraint to more resources')}
        message={`${t('Select the resources to which you want to apply')} ${t('Travel Time')} ${t(
          'constraint values along with'
        )} ${name}:`}
        selected={selectedForTravelTime}
        setSelected={setSelectedForTravelTime}
        options={resources
          .filter((res) => res.type === type && !selectedForTravelTime.includes(res.name) && res.name !== name)
          .map((res) => res.name)}
      />
      <SelectAllDialog
        open={isSelectMoreForbiddenCombinationDialogOpen}
        setOpen={setIsSelectMoreForbiddenCombinationDialogOpen}
        title={t('Apply this constraint to more resources')}
        message={`${t('Select the resources to which you want to apply')} ${t('Forbidden Combination')} ${t(
          'constraint values along with'
        )} ${name}:`}
        selected={selectedForForbiddenCombination}
        setSelected={setSelectedForForbiddenCombination}
        options={resources
          .filter(
            (res) => res.type === type && !selectedForForbiddenCombination.includes(res.name) && res.name !== name
          )
          .map((res) => res.name)}
      />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={`${resource.name} ${
          linkedLessonsCount === 0 ? '' : `${t('and')} ${linkedLessonsCount} ${t('linked lessons')}`
        } ${t('will be permanently deleted from this file. Are you sure?')}`}
      />
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_subjects')}
        ></VideoPlayerDialog>
      )}
      <DraggableShowConstraints
        open={showConstraintsOpen}
        setOpen={setShowConstraintsOpen}
        title={t('Show/hide Constraints')}
        message={`${t('Choose the constraints to show for')} ${getLabelFromType()}:`}
        setConfirmed={setShowConstraintsConfirmed}
        type={type}
        showWorkload={showWorkload}
        showWorkingDays={showWorkingDays}
        showIdleWindow={showIdleWindow}
        showDailyWorkload={showDailyWorkload}
        showRestBetweenDays={showRestBetweenDays}
        showRoomChangesDay={showRoomChangesDay}
        showConsecutiveTimes={showConsecutiveTimes}
        showTravelTime={showTravelTime}
        showDistinctSubjects={showDistinctSubjects}
        showForbiddenCombination={showForbiddenCombination}
        setShowWorkload={setShowWorkload}
        setShowWorkingDays={setShowWorkingDays}
        setShowIdleWindow={setShowIdleWindow}
        setShowDailyWorkload={setShowDailyWorkload}
        setShowRestBetweenDays={setShowRestBetweenDays}
        setShowRoomChangesDay={setShowRoomChangesDay}
        setShowConsecutiveTimes={setShowConsecutiveTimes}
        setShowTravelTime={setShowTravelTime}
        setShowDistinctSubjects={setShowDistinctSubjects}
        setShowForbiddenCombination={setShowForbiddenCombination}
      />
      <DraggableDialog
        open={toAllConfirmationOpen}
        setOpen={setToAllConfirmationOpen}
        setConfirmed={setToAllConfirmed}
        title={t('Warning')}
        message={getApplyToMoreConfirmationMessage()}
      />
      <DraggableAvatarPicker
        open={avatarPickerOpen}
        setOpen={setAvatarPickerOpen}
        selectedColor={selectedColor}
        selectedPhotoURL={selectedPhotoURL}
        setSelectedColor={setSelectedColor}
        setSelectedPhotoURL={setSelectedPhotoURL}
        resource={resource}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" paddingBottom={8}>
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item container xs={12} spacing={1} padding={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                    <Tooltip title={t('Back')}>
                      <IconButton
                        style={{ height: 24, width: 24, marginRight: 4 }}
                        color="primary"
                        onClick={() => setResource(undefined)}
                      >
                        <ArrowBack style={{ height: 20, width: 20 }} />
                      </IconButton>
                    </Tooltip>
                    {updateResource ? t('Update ') + t(type) : t(`New ${type}`)}
                  </Typography>
                  <Divider color={theme.palette.primary.main} />
                </Grid>
                <HelpButton t={t} onClick={() => setVideoPlayerDialogOpen(true)} />
                {updateResource ? (
                  <>
                    <Grid item xs={2}>
                      <Button
                        // startIcon={<Delete />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => setDeleteConfirmationOpen(true)}
                        color="error"
                        fullWidth
                      >
                        {t('Delete')}
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <LoadingButton
                        // startIcon={<FileCopy />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={copy}
                        color="primary"
                        fullWidth
                        loadingPosition="start"
                        loading={isLoadingCopy}
                      >
                        {t('Copy')}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={2}>
                      <LoadingButton
                        className={'update-button'}
                        variant="contained"
                        size="small"
                        disabled={readOnly}
                        onClick={handleSave}
                        color="primary"
                        fullWidth
                        loadingPosition="start"
                        loading={isLoading}
                      >
                        {t('Update')}
                      </LoadingButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={3} md={2}>
                    <LoadingButton
                      className={'save-button'}
                      variant="contained"
                      disabled={readOnly}
                      size="small"
                      onClick={handleSave}
                      color="primary"
                      fullWidth
                      loadingPosition="start"
                      loading={isLoading}
                    >
                      {t('Save')}
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} spacing={1} padding={1} className="name-priority">
                <Grid container item xs={2} sm={1} md={1} lg={1} justifyContent={'center'}>
                  <Box
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                        borderRadius: 100,
                        backgroundColor: selectedColor,
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    style={{ width: 'fit-content', height: 'fit-content', padding: '0px', margin: '0px' }}
                    onClick={() => setAvatarPickerOpen(true)}
                  >
                    <Avatar
                      translate="no"
                      sx={{ bgcolor: selectedColor, fontSize: short.length > 3 ? '12px' : '16px' }}
                      alt={name}
                      src={selectedPhotoURL}
                    >
                      {short}
                    </Avatar>
                  </Box>
                </Grid>
                {/* Name */}
                <Grid item xs={8} sm={8} md={8} lg={9}>
                  <TextField
                    id="name"
                    required
                    fullWidth
                    size="small"
                    label={t('Name')}
                    translate="no"
                    variant="outlined"
                    defaultValue={formik.initialValues.name}
                    onChange={(e) => handleNameChange(e.target.value)}
                    InputProps={{
                      autoFocus: true, // Use the autoFocus attribute
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSave();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={3} md={3} lg={2}>
                  <MultipleSelectChip
                    single={true}
                    label={t('Priority')}
                    names={priorityOptions}
                    chipColor={priorityBackgroundColors[selectedPriority[0]]}
                    personName={selectedPriority}
                    setPersonName={setSelectedPriority}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Availability')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                      <Box sx={{ width: '100%' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="scrollable"
                          allowScrollButtonsMobile
                          aria-label="wrapped label tabs example"
                        >
                          <Tab label={t('Unavailable Times')} />
                          <Tab className={'undisired-times-tab'} label={t('Undesired Times')} />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={value} index={0}>
                        <TableContainer className="availability-form" component={Paper}>
                          <Table size="small" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected === allCount}
                                    size="small"
                                    color="error"
                                    onClick={(event) => handleSelectAllClick(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                        size="small"
                                        color="error"
                                        onClick={(event) => handleSelectAllDayClick(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime[t_index] === colCount}
                                        size="small"
                                        color="error"
                                        onClick={(event) => handleSelectAllTimeClick(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected('' + d_index + DAY_TIME_SEPARATOR + t_index);
                                      return (
                                        <TableCell key={`cell_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}_${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="error"
                                            onClick={(event) =>
                                              handleClick(event, '' + d_index + DAY_TIME_SEPARATOR + t_index)
                                            }
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            <Close />
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="Undesired Times" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected2 === allCount}
                                    size="small"
                                    color="warning"
                                    onClick={(event) => handleSelectAllClick2(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day2_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay2[d_index] === rowCount}
                                        size="small"
                                        color="warning"
                                        onClick={(event) => handleSelectAllDayClick2(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time2_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime2[t_index] === colCount}
                                        size="small"
                                        color="warning"
                                        onClick={(event) => handleSelectAllTimeClick2(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected2('' + d_index + DAY_TIME_SEPARATOR + t_index);
                                      return (
                                        <TableCell key={`cell2_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}:${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="warning"
                                            onClick={(event) =>
                                              handleClick2(event, '' + d_index + DAY_TIME_SEPARATOR + t_index)
                                            }
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            <Remove />
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Constraints')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} className="constraints-form">
                      {showWorkload && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<WorkHistory sx={{ marginRight: 1 }} />}
                          label={t('Working Times')}
                          labelHelper={t('working times')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of times having lessons for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Min and Max are set to 5, this')} ${t(type)} ${t(
                                'will have lessons in 5 times throughout the timetable:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/workingTimes.png'}
                                width={'100%'}
                                alt={t('Example of Working Times constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinWorkload}
                          setSelectedMin={setSelectedMinWorkload}
                          selectedMax={selectedMaxWorkload}
                          setSelectedMax={setSelectedMaxWorkload}
                          possibleMin={0}
                          possibleMax={times.length * days.length}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForWorkload}
                          setSelectedMore={setSelectedForWorkload}
                          options={resources
                            .filter(
                              (res) => res.type === type && !selectedForWorkload.includes(res.name) && res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showWorkingDays && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<DateRange sx={{ marginRight: 1 }} />}
                          label={t('Working Days')}
                          labelHelper={t('working days')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of days with lessons for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 2, this')} ${t(type)} ${t(
                                'will have lessons in at most 2 days:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/workingDays.png'}
                                width={'100%'}
                                alt={t('Example of Working Days constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinWorkingDays}
                          setSelectedMin={setSelectedMinWorkingDays}
                          selectedMax={selectedMaxWorkingDays}
                          setSelectedMax={setSelectedMaxWorkingDays}
                          possibleMin={0}
                          possibleMax={days.length}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForWorkingDays}
                          setSelectedMore={setSelectedForWorkingDays}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type && !selectedForWorkingDays.includes(res.name) && res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showIdleWindow && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<Chair sx={{ marginRight: 1 }} />}
                          label={t('Idle Window')}
                          labelHelper={t('idle times between working times')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of idle times between working times for this')} ${t(type)}`}
                              <hr />
                              {`${t(
                                'For example, if Max is set to 1, window between 2nd and 4th times on Tuesday would be allowed, but window between 1st and 4th times on Thursday would be forbidden:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/idleWindow.png'}
                                width={'100%'}
                                alt={t('Example of Idle Window constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinIdleWindow}
                          setSelectedMin={setSelectedMinIdleWindow}
                          selectedMax={selectedMaxIdleWindow}
                          setSelectedMax={setSelectedMaxIdleWindow}
                          possibleMin={0}
                          possibleMax={times.length - 2}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForIdleWindow}
                          setSelectedMore={setSelectedForIdleWindow}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type && !selectedForIdleWindow.includes(res.name) && res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showDailyWorkload && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<EventRepeat sx={{ marginRight: 1 }} />}
                          label={t('Daily Workload')}
                          labelHelper={t('busy times per day')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of times with lessons per day for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 2, this')} ${t(type)} ${t(
                                'will have at most 2 lessons per day:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/dailyWorkload.png'}
                                width={'100%'}
                                alt={t('Example of Daily Workload constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinDailyWorkload}
                          setSelectedMin={setSelectedMinDailyWorkload}
                          selectedMax={selectedMaxDailyWorkload}
                          setSelectedMax={setSelectedMaxDailyWorkload}
                          possibleMin={0}
                          possibleMax={times.length}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForDailyWorkload}
                          setSelectedMore={setSelectedForDailyWorkload}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type && !selectedForDailyWorkload.includes(res.name) && res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showRestBetweenDays && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<Hotel sx={{ marginRight: 1 }} />}
                          label={t('Rest between Days')}
                          labelHelper={t('times for rest between days')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of rest times between one day and the next')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Min is set to 1, this')} ${t(type)} ${t(
                                'will have at least 1 free slot between lessons from a day and the next, enforcing the 1st time on Friday to be free:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/restBetweenDays.png'}
                                width={'100%'}
                                alt={t('Example of Rest between Days constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinRestBetweenDays}
                          setSelectedMin={setSelectedMinRestBetweenDays}
                          selectedMax={selectedMaxRestBetweenDays}
                          setSelectedMax={setSelectedMaxRestBetweenDays}
                          possibleMin={0}
                          possibleMax={times.length * 2 - 2}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForRestBetweenDays}
                          setSelectedMore={setSelectedForRestBetweenDays}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type && !selectedForRestBetweenDays.includes(res.name) && res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showRoomChangesDay && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<TransferWithinAStation sx={{ marginRight: 1 }} />}
                          label={t('Rooms per Day')}
                          labelHelper={t('used rooms per day')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of different rooms used per day by this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 1, this')} ${t(type)} ${t(
                                "will have lessons in at most 1 room in any day, enforcing all lessons on Tuesday to be in the same room, same applies to Thursday's lessons:"
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/roomChangesDay.png'}
                                width={'100%'}
                                alt={t('Example of Rooms per Day constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinRoomChangesDay}
                          setSelectedMin={setSelectedMinRoomChangesDay}
                          selectedMax={selectedMaxRoomChangesDay}
                          setSelectedMax={setSelectedMaxRoomChangesDay}
                          possibleMin={0}
                          possibleMax={times.length - 1}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForRoomChangesDay}
                          setSelectedMore={setSelectedForRoomChangesDay}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type && !selectedForRoomChangesDay.includes(res.name) && res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showConsecutiveTimes && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<LinearScale sx={{ marginRight: 1 }} />}
                          label={t('Consecutive Times')}
                          labelHelper={t('consecutive busy times in a day')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number consecutive times with lessons for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 2, this')} ${t(type)} ${t(
                                'will have at most 2 consecutive slots throughout the timetable:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/consecutiveTimes.png'}
                                width={'100%'}
                                alt={t('Example of Consecutive Times constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinConsecutiveTimes}
                          setSelectedMin={setSelectedMinConsecutiveTimes}
                          selectedMax={selectedMaxConsecutiveTimes}
                          setSelectedMax={setSelectedMaxConsecutiveTimes}
                          possibleMin={0}
                          possibleMax={times.length}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForConsecutiveTimes}
                          setSelectedMore={setSelectedForConsecutiveTimes}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type &&
                                !selectedForConsecutiveTimes.includes(res.name) &&
                                res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showDistinctSubjects && (
                        <MinMaxSlider
                          name={name.trim()}
                          leftIcon={<MenuBook sx={{ marginRight: 1 }} />}
                          label={t('Distinct Subjects')}
                          labelHelper={t('distinct subjects to teach')}
                          tooltipComponent={
                            <div>
                              {`${t('Limits the number of distinct subjects taught by this')} ${t(type)}`}
                              <hr />
                              {t(
                                'For example, if Max is set to 2, this teacher will lecture at most 2 different subjects, yellow (4 times) and blue (2 times):'
                              )}
                              <p />
                              <img
                                src={'/img/examples/distinctSubjects.png'}
                                width={'100%'}
                                alt={t('Example of Distinct Subjects constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinDistinctSubjects}
                          setSelectedMin={setSelectedMinDistinctSubjects}
                          selectedMax={selectedMaxDistinctSubjects}
                          setSelectedMax={setSelectedMaxDistinctSubjects}
                          possibleMin={0}
                          possibleMax={days.length * times.length}
                          tooltipApplyToAll={`${t('Apply to more')} ${getLabelFromType()}`}
                          selectedMore={selectedForDistinctSubjects}
                          setSelectedMore={setSelectedForDistinctSubjects}
                          options={resources
                            .filter(
                              (res) =>
                                res.type === type &&
                                !selectedForDistinctSubjects.includes(res.name) &&
                                res.name !== name
                            )
                            .map((res) => res.name)}
                        />
                      )}
                      {showTravelTime && (
                        <Grid container item xs={12} spacing={1}>
                          <Grid xs={false} sm={1} md={1}>
                            <></>
                          </Grid>
                          <Grid item container xs={12} sm={4} md={3} justifyContent={'flex-end'}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Travel time description')}
                                  <hr />
                                  {t(
                                    'Consider you are setting the room that hosts brown lesson. If you select the room in which blue lesson is assigned and set Times to 1, any teacher/class that takes both brown and blue lessons will have at least 1 free slot to commute:'
                                  )}
                                  <p />
                                  <img
                                    src={'/img/examples/travelTime.png'}
                                    width={'100%'}
                                    alt={t('Example of Travel Time constraint')}
                                  />
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                size="small"
                                color="primary"
                                sx={{
                                  textTransform: 'none',
                                  justifyContent: widthLessThan600 ? 'center' : 'flex-end',
                                  paddingRight: 2,
                                }}
                                style={{ borderWidth: 0, backgroundColor: '#ffffff' }}
                                selected={
                                  (selectedMinTravelTime as number) == 0 || selectedTravelTimeRooms.length === 0
                                    ? false
                                    : true
                                }
                              >
                                <EmojiTransportation sx={{ marginRight: 1 }} />
                                {t('Travel Time')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item container xs={8} sm={4} spacing={1}>
                            <Grid item xs={6}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Rooms')}
                                  names={resources
                                    .filter((res) => res.type === 'room' && res.name !== name)
                                    .map((res) => {
                                      return res.name;
                                    })}
                                  personName={selectedTravelTimeRooms}
                                  setPersonName={setSelectedTravelTimeRooms}
                                ></MultipleSelectChip>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth size="small">
                                <InputLabel id="min-travel-time-label">{t('Timeslots')}</InputLabel>
                                <Select
                                  size="small"
                                  labelId="min-consecutive-times-select-label"
                                  id="min-travel-time-select"
                                  defaultValue={'0' + formik.initialValues.minTravelTime}
                                  onChange={handleChangeMinTravelTime}
                                  sx={{
                                    color: +selectedMinTravelTime !== 0 ? 'default' : '#fff',
                                  }}
                                  label={t('Timeslots')}
                                >
                                  {Array.from({ length: +times.length - 1 + 1 }).map((_, index) => {
                                    return (
                                      <MenuItem key={`minTravelTimes_${index}`} value={`${index}`}>
                                        {index === 0 ? `↓` : index}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            {Number(selectedMinTravelTime) !== 0 && selectedTravelTimeRooms.length > 0 && (
                              <Typography variant="body2" sx={{ color: theme.palette.primary.main }} paddingLeft={1}>
                                {name} {getAndMoreHelper(t, selectedForTravelTime)}
                                {t('will have at least')} {selectedMinTravelTime}
                                {t(' free times to commute to rooms')} {selectedTravelTimeRooms.join(', ')}
                              </Typography>
                            )}
                            {Number(selectedMinTravelTime) !== 0 && selectedTravelTimeRooms.length === 0 && (
                              <Typography variant="body2" color="error" paddingLeft={1}>
                                {t(
                                  'You need to select the rooms to which free timeslot(s) to commute time are required'
                                )}
                              </Typography>
                            )}
                            {Number(selectedMinTravelTime) === 0 && selectedTravelTimeRooms.length > 0 && (
                              <Typography variant="body2" color="error" paddingLeft={1}>
                                {t('You need to select how many free timeslot(s) are needed to commute from ')} {name}{' '}
                                {t(' to ')} {selectedTravelTimeRooms.join(', ')}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item sm={3} md={2}>
                            <Tooltip title={`${t('Apply to more')} ${getLabelFromType()}`} placement="right-end">
                              <ToggleButton
                                value="check"
                                size="small"
                                color="primary"
                                selected={selectedForTravelTime.length > 0}
                                onChange={() => setIsSelectMoreTravelTimeDialogOpen(true)}
                                // sx={{ width: 160 }}
                                fullWidth
                              >
                                <DoubleArrow />
                                {widthLessThan600
                                  ? ''
                                  : selectedForTravelTime.length > 0
                                  ? `${t('More ')}${selectedForTravelTime.length}`
                                  : t('More ')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid sm={false} md={2}>
                            <></>
                          </Grid>
                          {widthLessThan600 && (
                            <Grid item xs={12}>
                              <Divider></Divider>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {showForbiddenCombination && (
                        <Grid container item xs={12} spacing={1}>
                          <Grid xs={false} sm={1} md={1}>
                            <></>
                          </Grid>
                          <Grid item container xs={12} sm={4} md={3} justifyContent={'flex-end'}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Enforces to have at least one day without lessons among the selected days')}
                                  <hr />
                                  {`${t(
                                    'For example, if Monday and Friday are selected, at least one of them will be free for this '
                                  )} ${t(type)}:`}
                                  <p />
                                  <img
                                    src={'/img/examples/forbiddenCombination.png'}
                                    width={'100%'}
                                    alt={t('Example of Forbidden Combination constraint')}
                                  />
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                size="small"
                                color="primary"
                                sx={{
                                  textTransform: 'none',
                                  justifyContent: widthLessThan600 ? 'center' : 'flex-end',
                                  paddingRight: 2,
                                }}
                                style={{ borderWidth: 0, backgroundColor: '#ffffff' }}
                                selected={selectedDays.length === 0 ? false : true}
                              >
                                <MoreTime sx={{ marginRight: 1 }} />
                                {t('Forbidden Combination')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={8} sm={4}>
                            <FormControl fullWidth size="small">
                              <MultipleSelectChip
                                label={t('Days')}
                                names={days}
                                personName={selectedDays}
                                setPersonName={setSelectedDays}
                              ></MultipleSelectChip>
                              {selectedDays.length > 0 && (
                                <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                  {name} {getAndMoreHelper(t, selectedForForbiddenCombination)}
                                  {t('will have at least free one day among')} {selectedDays.join(', ')}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item sm={3} md={2}>
                            <Tooltip title={`${t('Apply to more')} ${getLabelFromType()}`} placement="right-end">
                              <ToggleButton
                                value="check"
                                size="small"
                                color="primary"
                                selected={selectedForForbiddenCombination.length > 0}
                                onChange={() => setIsSelectMoreForbiddenCombinationDialogOpen(true)}
                                fullWidth
                              >
                                <DoubleArrow />
                                {widthLessThan600
                                  ? ''
                                  : selectedForForbiddenCombination.length > 0
                                  ? `${t('More ')}${selectedForForbiddenCombination.length}`
                                  : t('More ')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid sm={false} md={1}>
                            <></>
                          </Grid>
                          {widthLessThan600 && (
                            <Grid item xs={12}>
                              <Divider></Divider>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          startIcon={<Visibility />}
                          variant="outlined"
                          size="small"
                          onClick={() => setShowConstraintsOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Show More Constraints')}
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </>
  );
}
