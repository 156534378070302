export const COURTESY = 'courtesy';
export const FREE = 'free';
export const BASIC = 'basic';
export const PREMIUM = 'premium';
export const COUNTRY_CODE_BR = 'BR';

export const planFeatures = {
  free: {
    name: 'Free',
    fullPriceBRL: '0,00',
    priceBRL: '0,00',
    fullPriceUSD: '0.00',
    priceUSD: '0.00',
    maxFiles: 2,
    maxLessons: 100,
    maxRuntime: 60,
    exportFormats: [],
  },
  basic: {
    name: 'Basic',
    fullPriceBRL: '49,99',
    priceBRL: '29,99',
    fullPriceUSD: '19.99',
    priceUSD: '9.99',
    maxFiles: 10,
    maxLessons: 300,
    maxRuntime: 600,
    exportFormats: ['xlsx'],
  },
  premium: {
    name: 'Premium',
    fullPriceBRL: '79,99',
    priceBRL: '49,99',
    fullPriceUSD: '29.99',
    priceUSD: '19.99',
    maxFiles: Number.POSITIVE_INFINITY,
    maxLessons: Number.POSITIVE_INFINITY,
    maxRuntime: 999999,
    exportFormats: ['xlsx', 'json', 'csv'],
  },
};
