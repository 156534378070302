import * as React from 'react';
import { IFile } from 'pages/Files/File';
import { IUser, SharedWithMe } from 'contexts/SessionContext/SessionContext';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import { Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Avatar,
  Menu,
  MenuItem,
  CardMedia,
  Grid,
  CardContent,
  Typography,
  Divider,
  CardActions,
  Tooltip,
  IconButton,
  colors,
} from '@mui/material';
import { AccountBalance, FileCopy, Delete } from '@mui/icons-material';

export default function SharedFileCard(props: {
  file: IFile | null;
  user: IUser | null;
  shared: SharedWithMe | undefined;
  onClick: (event: React.MouseEvent<unknown>, shared: SharedWithMe | undefined) => void;
  onCopyClick: (event: React.MouseEvent<unknown>, file: IFile) => void;
  onDeleteClick: (event: React.MouseEvent<unknown>, file: IFile) => void;
}) {
  const { t } = useTranslation();
  const file = props.file;
  const user = props.user;
  const shared = props.shared;
  const onClick = props.onClick;
  const onCopyClick = props.onCopyClick;
  const onDeleteClick = props.onDeleteClick;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ width: 320 }} elevation={4}>
      {file && (
        <>
          <CardActionArea onClick={(event) => onClick(event, shared)}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ borderRadius: '0px', height: '24px', width: '32px' }}
                  src={`https://flagcdn.com/h60/${file.countryCode.toLowerCase()}.png`}
                  aria-label="recipe"
                >
                  R
                </Avatar>
              }
              title={file.name}
              subheader={
                user?.countryCode === 'BR'
                  ? dayjs((file.updatedAt as unknown as Timestamp).toDate())
                      .locale(ptBR)
                      .format('DD [de] MMMM [de] YYYY [às] HH:mm[h]')
                  : dayjs((file.updatedAt as unknown as Timestamp).toDate()).format('MMMM DD,YY[ at ]hh:mma')
              }
            />
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleOptionsClose}>
              <MenuItem onClick={handleOptionsClose}>Profile</MenuItem>
              <MenuItem onClick={handleOptionsClose}>My account</MenuItem>
              <MenuItem onClick={handleOptionsClose}>Logout</MenuItem>
            </Menu>
            <Box
              sx={{
                overflow: 'auto',
                maxHeight: 248,
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
              }}
            >
              {file.photoURL ? (
                <CardMedia component="img" height="160px" image={file.photoURL} alt="Photo" />
              ) : (
                <Grid
                  container
                  minHeight={'160px'}
                  width={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{ backgroundColor: colors.grey[100] }}
                >
                  <AccountBalance sx={{ fontSize: 60 }} htmlColor={colors.grey[600]} />
                </Grid>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {`${file.institution} • ${file.year}`}
                </Typography>
                {file.description ? (
                  <>
                    {file.description.split('\\n').map((i, key) => {
                      return (
                        <Typography key={key} variant="body2" color="text.secondary">
                          {i}
                          <br />
                        </Typography>
                      );
                    })}
                  </>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    &nbsp;
                  </Typography>
                )}
              </CardContent>
            </Box>
          </CardActionArea>
          <Divider />
          <CardActions disableSpacing>
            <Tooltip title={t('Copy file')}>
              <IconButton aria-label="copy file" onClick={(event) => onCopyClick(event, file)}>
                <FileCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Delete file')}>
              <IconButton aria-label="delete" onClick={(event) => onDeleteClick(event, file)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </CardActions>
        </>
      )}
    </Card>
  );
}
