import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import PrioritySelect from 'components/Select/PrioritySelect';
import { SessionContext } from 'contexts';
import { ISolution } from '../Solution';
import RuntimeSelect from 'components/Select/RuntimeSelect';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  Paper,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  Grid,
  Typography,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert,
} from '@mui/material';
import { grey, blue } from '@mui/material/colors';
import { PaperComponent } from 'util/dialogUtils';
import { priorityOptionsMap } from 'util/configUtils';
import { BASIC, COURTESY, FREE, planFeatures, PREMIUM } from 'configs/planFeatures';
import { OPTIMAL, PROCESSING } from 'util/solutionUtils';
import { getConstrBounds } from 'util/validationUtils';
import EmptyConstraints from './EmptyConstraints';
import { Info } from '@mui/icons-material';

export interface SolverTypesMap {
  [key: string]: string;
}

export interface DisplayTypesMap {
  [key: string]: string;
}

export interface RunningTimeSecondsMap {
  [key: number]: string;
}

export interface RunningTimeLabelsMap {
  [key: string]: number;
}

export default function ConfigsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const solution: ISolution = props.solution;
  const setSolution = props.setSolution;
  const setConfirmed = props.setConfirmed;
  const priorityOptions: string[] = props.priorityOptions;
  const userPlan = props.userPlan;
  const runtimeSuggestion = props.runtimeSuggestion;
  const handleGenerate = props.handleGenerate;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const { file, resources, subjects, lessons } = useContext(SessionContext);

  const solverTypesMap: SolverTypesMap = {};
  solverTypesMap['Optimal'] = 'Optimal';
  solverTypesMap['Fast'] = 'Fast';
  solverTypesMap['Ótimo'] = 'Optimal';
  solverTypesMap['Rápido'] = 'Fast';

  const displayTypesMap: DisplayTypesMap = {};
  displayTypesMap['Compact'] = 'Compact';
  displayTypesMap['Individual tables'] = 'Individual tables';
  displayTypesMap['Compacto'] = 'Compact';
  displayTypesMap['Tabelas individuais'] = 'Individual tables';

  const handleClose = () => {
    setDisplay(t(solution.display));
    setSolver(t(solution.solver));
    setRunningTimeLabel(mapRunningTimeSecondsToLabels[solution.runningTime]);

    setClashesClasses(t(solution.clashesClasses));
    setWorkloadClasses(t(solution.workloadClasses));
    setWorkingDaysClasses(t(solution.workingDaysClasses));
    setIdleWindowClasses(t(solution.idleWindowClasses));
    setDailyWorkloadClasses(t(solution.dailyWorkloadClasses));
    setDistinctSubjectsClasses(t(solution.distinctSubjectsClasses));
    setForbiddenCombinationClasses(t(solution.forbiddenCombinationClasses));
    setUnavailableTimesClasses(t(solution.unavailableTimesClasses));
    setUndesiredTimesClasses(t(solution.undesiredTimesClasses));

    setClashesTeachers(t(solution.clashesTeachers));
    setWorkloadTeachers(t(solution.workloadTeachers));
    setWorkingDaysTeachers(t(solution.workingDaysTeachers));
    setIdleWindowTeachers(t(solution.idleWindowTeachers));
    setDailyWorkloadTeachers(t(solution.dailyWorkloadTeachers));
    setDistinctSubjectsTeachers(t(solution.distinctSubjectsTeachers));
    setForbiddenCombinationTeachers(t(solution.forbiddenCombinationTeachers));
    setUnavailableTimesTeachers(t(solution.unavailableTimesTeachers));
    setUndesiredTimesTeachers(t(solution.undesiredTimesTeachers));

    setClashesRooms(t(solution.clashesRooms));
    setWorkloadRooms(t(solution.workloadRooms));
    setWorkingDaysRooms(t(solution.workingDaysRooms));
    setIdleWindowRooms(t(solution.idleWindowRooms));
    setDailyWorkloadRooms(t(solution.dailyWorkloadRooms));
    setDistinctSubjectsRooms(t(solution.distinctSubjectsRooms));
    setForbiddenCombinationRooms(t(solution.forbiddenCombinationRooms));
    setUnavailableTimesRooms(t(solution.unavailableTimesRooms));
    setUndesiredTimesRooms(t(solution.undesiredTimesRooms));

    setUnavailableTimesSubjects(t(solution.unavailableTimesSubjects));
    setUndesiredTimesSubjects(t(solution.undesiredTimesSubjects));

    setAssignTimes(t(solution.assignTimes));
    setAssignResources(t(solution.assignResources));
    setSplit(t(solution.split));
    setDaysBetweenLessons(t(solution.daysBetweenLessons));
    setPredefinedTimes(t(solution.predefinedTimes));
    setSimultaneousWith(t(solution.simultaneousWith));
    setNotSimultaneousWith(t(solution.notSimultaneousWith));
    setOccurBefore(t(solution.occurBefore));

    setRestBetweenDaysClasses(t(solution.restBetweenDaysClasses));
    setRoomChangesDayClasses(t(solution.roomChangesDayClasses));
    setConsecutiveTimesClasses(t(solution.consecutiveTimesClasses));
    setRestBetweenDaysTeachers(t(solution.restBetweenDaysTeachers));
    setRoomChangesDayTeachers(t(solution.roomChangesDayTeachers));
    setConsecutiveTimesTeachers(t(solution.consecutiveTimesTeachers));
    setConsecutiveTimesRooms(t(solution.consecutiveTimesRooms));
    setTravelTimeRooms(t(solution.travelTimeRooms));

    setOpen(false);
  };

  const handleDefault = () => {
    setDisplay(t('Compact'));
    setSolver(t('Optimal'));
    setRunningTimeLabel(mapRunningTimeSecondsToLabels[runtimeSuggestion]);

    setClashesClasses(priorityOptionsMap[priorityOptions[5]]);
    setWorkloadClasses(priorityOptionsMap[priorityOptions[5]]);
    setWorkingDaysClasses(priorityOptionsMap[priorityOptions[3]]);
    setIdleWindowClasses(priorityOptionsMap[priorityOptions[3]]);
    setDailyWorkloadClasses(priorityOptionsMap[priorityOptions[3]]);
    setDistinctSubjectsClasses(priorityOptionsMap[priorityOptions[3]]);
    setForbiddenCombinationClasses(priorityOptionsMap[priorityOptions[3]]);
    setUnavailableTimesClasses(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesClasses(priorityOptionsMap[priorityOptions[1]]);
    setRestBetweenDaysClasses(priorityOptionsMap[priorityOptions[1]]);
    setRoomChangesDayClasses(priorityOptionsMap[priorityOptions[1]]);
    setConsecutiveTimesClasses(priorityOptionsMap[priorityOptions[1]]);

    setClashesTeachers(priorityOptionsMap[priorityOptions[5]]);
    setWorkloadTeachers(priorityOptionsMap[priorityOptions[5]]);
    setWorkingDaysTeachers(priorityOptionsMap[priorityOptions[3]]);
    setIdleWindowTeachers(priorityOptionsMap[priorityOptions[1]]);
    setDailyWorkloadTeachers(priorityOptionsMap[priorityOptions[1]]);
    setDistinctSubjectsTeachers(priorityOptionsMap[priorityOptions[1]]);
    setForbiddenCombinationTeachers(priorityOptionsMap[priorityOptions[3]]);
    setUnavailableTimesTeachers(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesTeachers(priorityOptionsMap[priorityOptions[1]]);
    setRestBetweenDaysTeachers(priorityOptionsMap[priorityOptions[1]]);
    setRoomChangesDayTeachers(priorityOptionsMap[priorityOptions[1]]);
    setConsecutiveTimesTeachers(priorityOptionsMap[priorityOptions[1]]);

    setClashesRooms(priorityOptionsMap[priorityOptions[5]]);
    setWorkloadRooms(priorityOptionsMap[priorityOptions[5]]);
    setWorkingDaysRooms(priorityOptionsMap[priorityOptions[3]]);
    setIdleWindowRooms(priorityOptionsMap[priorityOptions[3]]);
    setDailyWorkloadRooms(priorityOptionsMap[priorityOptions[3]]);
    setDistinctSubjectsRooms(priorityOptionsMap[priorityOptions[3]]);
    setForbiddenCombinationRooms(priorityOptionsMap[priorityOptions[3]]);
    setUnavailableTimesRooms(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesRooms(priorityOptionsMap[priorityOptions[1]]);
    setConsecutiveTimesRooms(priorityOptionsMap[priorityOptions[1]]);
    setTravelTimeRooms(priorityOptionsMap[priorityOptions[1]]);

    setAssignTimes(priorityOptionsMap[priorityOptions[5]]);
    setAssignResources(priorityOptionsMap[priorityOptions[5]]);
    setSplit(priorityOptionsMap[priorityOptions[5]]);
    setDaysBetweenLessons(priorityOptionsMap[priorityOptions[5]]);
    setPredefinedTimes(priorityOptionsMap[priorityOptions[5]]);
    setSimultaneousWith(priorityOptionsMap[priorityOptions[5]]);
    setNotSimultaneousWith(priorityOptionsMap[priorityOptions[5]]);
    setOccurBefore(priorityOptionsMap[priorityOptions[5]]);
    setUnavailableTimesSubjects(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesSubjects(priorityOptionsMap[priorityOptions[1]]);
    toast.success(t('Default configs have been restored'));
  };
  const handleConfirm = () => {
    setConfirmed(true);
    let newSolution = { ...solution };
    newSolution.status = PROCESSING;
    newSolution.display = displayTypesMap[display];
    newSolution.solver = solverTypesMap[solver];
    newSolution.runningTime = mapRunningTimeLabelsToSeconds[runningTimeLabel];
    newSolution.clashesClasses = priorityOptionsMap[clashesClasses];
    newSolution.workloadClasses = priorityOptionsMap[workloadClasses];
    newSolution.workingDaysClasses = priorityOptionsMap[workingDaysClasses];
    newSolution.idleWindowClasses = priorityOptionsMap[idleWindowClasses];
    newSolution.dailyWorkloadClasses = priorityOptionsMap[dailyWorkloadClasses];
    newSolution.distinctSubjectsClasses = priorityOptionsMap[distinctSubjectsClasses];
    newSolution.forbiddenCombinationClasses = priorityOptionsMap[forbiddenCombinationClasses];
    newSolution.unavailableTimesClasses = priorityOptionsMap[unavailableTimesClasses];
    newSolution.undesiredTimesClasses = priorityOptionsMap[undesiredTimesClasses];
    newSolution.restBetweenDaysClasses = priorityOptionsMap[restBetweenDaysClasses];
    newSolution.roomChangesDayClasses = priorityOptionsMap[roomChangesDayClasses];
    newSolution.consecutiveTimesClasses = priorityOptionsMap[consecutiveTimesClasses];

    newSolution.clashesTeachers = priorityOptionsMap[clashesTeachers];
    newSolution.workloadTeachers = priorityOptionsMap[workloadTeachers];
    newSolution.workingDaysTeachers = priorityOptionsMap[workingDaysTeachers];
    newSolution.idleWindowTeachers = priorityOptionsMap[idleWindowTeachers];
    newSolution.dailyWorkloadTeachers = priorityOptionsMap[dailyWorkloadTeachers];
    newSolution.distinctSubjectsTeachers = priorityOptionsMap[distinctSubjectsTeachers];
    newSolution.forbiddenCombinationTeachers = priorityOptionsMap[forbiddenCombinationTeachers];
    newSolution.unavailableTimesTeachers = priorityOptionsMap[unavailableTimesTeachers];
    newSolution.undesiredTimesTeachers = priorityOptionsMap[undesiredTimesTeachers];
    newSolution.restBetweenDaysTeachers = priorityOptionsMap[restBetweenDaysTeachers];
    newSolution.roomChangesDayTeachers = priorityOptionsMap[roomChangesDayTeachers];
    newSolution.consecutiveTimesTeachers = priorityOptionsMap[consecutiveTimesTeachers];

    newSolution.clashesRooms = priorityOptionsMap[clashesRooms];
    newSolution.workloadRooms = priorityOptionsMap[workloadRooms];
    newSolution.workingDaysRooms = priorityOptionsMap[workingDaysRooms];
    newSolution.idleWindowRooms = priorityOptionsMap[idleWindowRooms];
    newSolution.dailyWorkloadRooms = priorityOptionsMap[dailyWorkloadRooms];
    newSolution.distinctSubjectsRooms = priorityOptionsMap[distinctSubjectsRooms];
    newSolution.forbiddenCombinationRooms = priorityOptionsMap[forbiddenCombinationRooms];
    newSolution.unavailableTimesRooms = priorityOptionsMap[unavailableTimesRooms];
    newSolution.undesiredTimesRooms = priorityOptionsMap[undesiredTimesRooms];
    newSolution.consecutiveTimesRooms = priorityOptionsMap[consecutiveTimesRooms];
    newSolution.travelTimeRooms = priorityOptionsMap[travelTimeRooms];

    newSolution.assignTimes = priorityOptionsMap[assignTimes];
    newSolution.assignResources = priorityOptionsMap[assignResources];
    newSolution.split = priorityOptionsMap[split];
    newSolution.daysBetweenLessons = priorityOptionsMap[daysBetweenLessons];
    newSolution.predefinedTimes = priorityOptionsMap[predefinedTimes];
    newSolution.simultaneousWith = priorityOptionsMap[simultaneousWith];
    newSolution.notSimultaneousWith = priorityOptionsMap[notSimultaneousWith];
    newSolution.occurBefore = priorityOptionsMap[occurBefore];
    newSolution.unavailableTimesSubjects = priorityOptionsMap[unavailableTimesSubjects];
    newSolution.undesiredTimesSubjects = priorityOptionsMap[undesiredTimesSubjects];

    setSolution(newSolution);
    setOpen(false);
    handleGenerate();
  };

  // const backgroundColors = [grey[100], green[100], green[300], green[500], green[700], green[900]];
  const backgroundColors = [grey[100], blue[100], blue[300], blue[500], blue[700], blue[900]];
  const textColors = [grey[700], grey[700], grey[50], grey[50], grey[50], grey[50]];

  const constraintTypesClasses = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesClasses: string[] = [];
    const hasWorkingTimesConst = resources.find(
      (res) =>
        res.type === 'class' && (res.minWorkload !== BOUNDS.minWorkload || res.maxWorkload !== BOUNDS.maxWorkload)
    );
    const hasWorkingDaysConst = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minWorkingDays !== BOUNDS.minWorkingDays || res.maxWorkingDays !== BOUNDS.maxWorkingDays)
    );
    const hasIdleWindow = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minIdleWindow !== BOUNDS.minIdleWindow || res.maxIdleWindow !== BOUNDS.maxIdleWindow)
    );
    const hasDailyWorkloadConst = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minDailyWorkload !== BOUNDS.minDailyWorkload || res.maxDailyWorkload !== BOUNDS.maxDailyWorkload)
    );
    const hasDistinctSubjects = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minDistinctSubjects !== BOUNDS.minDistinctSubjects ||
          res.maxDistinctSubjects !== BOUNDS.maxDistinctSubjects)
    );
    const hasForbiddenCombination = resources.find(
      (res) => res.type === 'class' && res.forbiddenCombination.length !== 0
    );
    const hasUnavailableTimes = resources.find((res) => res.type === 'class' && res.unavailableTimes.length !== 0);
    const hasUndesiredTimes = resources.find((res) => res.type === 'class' && res.undesiredTimes.length !== 0);
    const hasRestBetweenDays = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minRestBetweenDays !== BOUNDS.minRestBetweenDays || res.maxRestBetweenDays !== BOUNDS.maxRestBetweenDays)
    );
    const hasRoomsPerDayConstr = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minRoomChangesDay !== BOUNDS.minRoomChangesDay || res.maxRoomChangesDay !== BOUNDS.maxRoomChangesDay)
    );
    const hasConsecutiveTimesConstr = resources.find(
      (res) =>
        res.type === 'class' &&
        (res.minConsecutiveTimes !== BOUNDS.minConsecutiveTimes ||
          res.maxConsecutiveTimes !== BOUNDS.maxConsecutiveTimes)
    );
    hasWorkingDaysConst && constraintTypesClasses.push(t('Working Days'));
    hasWorkingTimesConst && constraintTypesClasses.push(t('Working Times'));
    hasDailyWorkloadConst && constraintTypesClasses.push(t('Daily Workload'));
    hasIdleWindow && constraintTypesClasses.push(t('Idle Window'));
    hasDistinctSubjects && constraintTypesClasses.push(t('Distinct Subjects'));
    hasForbiddenCombination && constraintTypesClasses.push(t('Forbidden Combination'));
    hasUnavailableTimes && constraintTypesClasses.push(t('Unavailable Times'));
    hasUndesiredTimes && constraintTypesClasses.push(t('Undesired Times'));
    hasRestBetweenDays && constraintTypesClasses.push(t('Rest between Days'));
    hasRoomsPerDayConstr && constraintTypesClasses.push(t('Rooms per Day'));
    hasConsecutiveTimesConstr && constraintTypesClasses.push(t('Consecutive Times'));
    return constraintTypesClasses;
  }, [t, file, resources]);

  const constraintTypesTeachers = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesTeachers: string[] = [];
    const hasWorkingTimesConst = resources.find(
      (res) =>
        res.type === 'teacher' && (res.minWorkload !== BOUNDS.minWorkload || res.maxWorkload !== BOUNDS.maxWorkload)
    );
    const hasWorkingDaysConst = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minWorkingDays !== BOUNDS.minWorkingDays || res.maxWorkingDays !== BOUNDS.maxWorkingDays)
    );
    const hasIdleWindow = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minIdleWindow !== BOUNDS.minIdleWindow || res.maxIdleWindow !== BOUNDS.maxIdleWindow)
    );
    const hasDailyWorkloadConst = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minDailyWorkload !== BOUNDS.minDailyWorkload || res.maxDailyWorkload !== BOUNDS.maxDailyWorkload)
    );
    const hasDistinctSubjects = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minDistinctSubjects !== BOUNDS.minDistinctSubjects ||
          res.maxDistinctSubjects !== BOUNDS.maxDistinctSubjects)
    );
    const hasForbiddenCombination = resources.find(
      (res) => res.type === 'teacher' && res.forbiddenCombination.length !== 0
    );
    const hasUnavailableTimes = resources.find((res) => res.type === 'teacher' && res.unavailableTimes.length !== 0);
    const hasUndesiredTimes = resources.find((res) => res.type === 'teacher' && res.undesiredTimes.length !== 0);
    const hasRestBetweenDays = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minRestBetweenDays !== BOUNDS.minRestBetweenDays || res.maxRestBetweenDays !== BOUNDS.maxRestBetweenDays)
    );
    const hasRoomsPerDayConstr = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minRoomChangesDay !== BOUNDS.minRoomChangesDay || res.maxRoomChangesDay !== BOUNDS.maxRoomChangesDay)
    );
    const hasConsecutiveTimesConstr = resources.find(
      (res) =>
        res.type === 'teacher' &&
        (res.minConsecutiveTimes !== BOUNDS.minConsecutiveTimes ||
          res.maxConsecutiveTimes !== BOUNDS.maxConsecutiveTimes)
    );
    hasWorkingDaysConst && constraintTypesTeachers.push(t('Working Days'));
    hasWorkingTimesConst && constraintTypesTeachers.push(t('Working Times'));
    hasDailyWorkloadConst && constraintTypesTeachers.push(t('Daily Workload'));
    hasIdleWindow && constraintTypesTeachers.push(t('Idle Window'));
    hasDistinctSubjects && constraintTypesTeachers.push(t('Distinct Subjects'));
    hasForbiddenCombination && constraintTypesTeachers.push(t('Forbidden Combination'));
    hasUnavailableTimes && constraintTypesTeachers.push(t('Unavailable Times'));
    hasUndesiredTimes && constraintTypesTeachers.push(t('Undesired Times'));
    hasRestBetweenDays && constraintTypesTeachers.push(t('Rest between Days'));
    hasRoomsPerDayConstr && constraintTypesTeachers.push(t('Rooms per Day'));
    hasConsecutiveTimesConstr && constraintTypesTeachers.push(t('Consecutive Times'));
    return constraintTypesTeachers;
  }, [t, file, resources]);

  const constraintTypesRooms = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesRooms: string[] = [];
    const hasWorkingTimesConst = resources.find(
      (res) => res.type === 'room' && (res.minWorkload !== BOUNDS.minWorkload || res.maxWorkload !== BOUNDS.maxWorkload)
    );
    const hasWorkingDaysConst = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minWorkingDays !== BOUNDS.minWorkingDays || res.maxWorkingDays !== BOUNDS.maxWorkingDays)
    );
    const hasIdleWindow = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minIdleWindow !== BOUNDS.minIdleWindow || res.maxIdleWindow !== BOUNDS.maxIdleWindow)
    );
    const hasDailyWorkloadConst = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minDailyWorkload !== BOUNDS.minDailyWorkload || res.maxDailyWorkload !== BOUNDS.maxDailyWorkload)
    );
    const hasDistinctSubjects = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minDistinctSubjects !== BOUNDS.minDistinctSubjects ||
          res.maxDistinctSubjects !== BOUNDS.maxDistinctSubjects)
    );
    const hasForbiddenCombination = resources.find(
      (res) => res.type === 'room' && res.forbiddenCombination.length !== 0
    );
    const hasUnavailableTimes = resources.find((res) => res.type === 'room' && res.unavailableTimes.length !== 0);
    const hasUndesiredTimes = resources.find((res) => res.type === 'room' && res.undesiredTimes.length !== 0);
    const hasRestBetweenDays = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minRestBetweenDays !== BOUNDS.minRestBetweenDays || res.maxRestBetweenDays !== BOUNDS.maxRestBetweenDays)
    );
    const hasRoomsPerDayConstr = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minRoomChangesDay !== BOUNDS.minRoomChangesDay || res.maxRoomChangesDay !== BOUNDS.maxRoomChangesDay)
    );
    const hasConsecutiveTimesConstr = resources.find(
      (res) =>
        res.type === 'room' &&
        (res.minConsecutiveTimes !== BOUNDS.minConsecutiveTimes ||
          res.maxConsecutiveTimes !== BOUNDS.maxConsecutiveTimes)
    );
    const hasTravelTimeConstr = resources.find(
      (res) => res.type === 'room' && res.minTravelTime !== BOUNDS.minTravelTime
    );
    hasWorkingDaysConst && constraintTypesRooms.push(t('Working Days'));
    hasWorkingTimesConst && constraintTypesRooms.push(t('Working Times'));
    hasDailyWorkloadConst && constraintTypesRooms.push(t('Daily Workload'));
    hasIdleWindow && constraintTypesRooms.push(t('Idle Window'));
    hasDistinctSubjects && constraintTypesRooms.push(t('Distinct Subjects'));
    hasForbiddenCombination && constraintTypesRooms.push(t('Forbidden Combination'));
    hasUnavailableTimes && constraintTypesRooms.push(t('Unavailable Times'));
    hasUndesiredTimes && constraintTypesRooms.push(t('Undesired Times'));
    hasRestBetweenDays && constraintTypesRooms.push(t('Rest between Days'));
    hasRoomsPerDayConstr && constraintTypesRooms.push(t('Rooms per Day'));
    hasConsecutiveTimesConstr && constraintTypesRooms.push(t('Consecutive Times'));
    hasTravelTimeConstr && constraintTypesRooms.push(t('Travel Time'));
    return constraintTypesRooms;
  }, [t, file, resources]);

  const constraintTypesLessons = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesLessons: string[] = [];
    // constraintTypesLessons.push(t('Split'));
    const hasDaysBetweenLessons = lessons.find(
      (lesson) =>
        lesson.minGapLessons !== BOUNDS.minDaysBetweenLessons || lesson.maxGapLessons !== BOUNDS.maxDaysBetweenLessons
    );
    const hasPredefinedTimesConstr = lessons.find((lesson) => lesson.predefinedTimes.length !== 0);
    const hasSimultaneousWithConstr = lessons.find((lesson) => lesson.simultaneousWith.length !== 0);
    const hasNotSimultaneousWithConstr = lessons.find((lesson) => lesson.notSimultaneousWith.length !== 0);
    const hasOccurBeforeConstr = lessons.find((lesson) => lesson.occurBefore.length !== 0);
    const hasUnavailableTimesConstr = subjects.find((subject) => subject.unavailableTimes.length !== 0);
    const hasUndesiredTimesConstr = subjects.find((subject) => subject.undesiredTimes.length !== 0);
    hasDaysBetweenLessons && constraintTypesLessons.push(t('Days between Meetings'));
    hasPredefinedTimesConstr && constraintTypesLessons.push(t('Predefined Times'));
    hasSimultaneousWithConstr && constraintTypesLessons.push(t('Simultaneous with'));
    hasNotSimultaneousWithConstr && constraintTypesLessons.push(t('Not Simultaneous with'));
    hasOccurBeforeConstr && constraintTypesLessons.push(t('Occur before'));
    hasUnavailableTimesConstr && constraintTypesLessons.push(t('Subject Unavailable Times'));
    hasUndesiredTimesConstr && constraintTypesLessons.push(t('Subject Undesired Times'));
    return constraintTypesLessons;
  }, [t, file, lessons, subjects]);

  const [clashesClasses, setClashesClasses] = useState<string>(t(solution.clashesClasses));
  const [workloadClasses, setWorkloadClasses] = useState<string>(t(solution.workloadClasses));
  const [workingDaysClasses, setWorkingDaysClasses] = useState<string>(t(solution.workingDaysClasses));
  const [idleWindowClasses, setIdleWindowClasses] = useState<string>(t(solution.idleWindowClasses));
  const [dailyWorkloadClasses, setDailyWorkloadClasses] = useState<string>(t(solution.dailyWorkloadClasses));
  const [distinctSubjectsClasses, setDistinctSubjectsClasses] = useState<string>(t(solution.distinctSubjectsClasses));
  const [forbiddenCombinationClasses, setForbiddenCombinationClasses] = useState<string>(
    t(solution.forbiddenCombinationClasses)
  );
  const [unavailableTimesClasses, setUnavailableTimesClasses] = useState<string>(t(solution.unavailableTimesClasses));
  const [undesiredTimesClasses, setUndesiredTimesClasses] = useState<string>(t(solution.undesiredTimesClasses));
  const [restBetweenDaysClasses, setRestBetweenDaysClasses] = useState<string>(t(solution.restBetweenDaysClasses));
  const [consecutiveTimesClasses, setConsecutiveTimesClasses] = useState<string>(t(solution.consecutiveTimesClasses));
  const [roomChangesDayClasses, setRoomChangesDayClasses] = useState<string>(t(solution.roomChangesDayClasses));

  const [clashesTeachers, setClashesTeachers] = useState<string>(t(solution.clashesTeachers));
  const [workloadTeachers, setWorkloadTeachers] = useState<string>(t(solution.workloadTeachers));
  const [workingDaysTeachers, setWorkingDaysTeachers] = useState<string>(t(solution.workingDaysTeachers));
  const [idleWindowTeachers, setIdleWindowTeachers] = useState<string>(t(solution.idleWindowTeachers));
  const [dailyWorkloadTeachers, setDailyWorkloadTeachers] = useState<string>(t(solution.dailyWorkloadTeachers));
  const [distinctSubjectsTeachers, setDistinctSubjectsTeachers] = useState<string>(
    t(solution.distinctSubjectsTeachers)
  );
  const [forbiddenCombinationTeachers, setForbiddenCombinationTeachers] = useState<string>(
    t(solution.forbiddenCombinationTeachers)
  );
  const [unavailableTimesTeachers, setUnavailableTimesTeachers] = useState<string>(
    t(solution.unavailableTimesTeachers)
  );
  const [undesiredTimesTeachers, setUndesiredTimesTeachers] = useState<string>(t(solution.undesiredTimesTeachers));
  const [restBetweenDaysTeachers, setRestBetweenDaysTeachers] = useState<string>(t(solution.restBetweenDaysTeachers));
  const [consecutiveTimesTeachers, setConsecutiveTimesTeachers] = useState<string>(
    t(solution.consecutiveTimesTeachers)
  );
  const [roomChangesDayTeachers, setRoomChangesDayTeachers] = useState<string>(t(solution.roomChangesDayTeachers));

  const [clashesRooms, setClashesRooms] = useState<string>(t(solution.clashesRooms));
  const [workloadRooms, setWorkloadRooms] = useState<string>(t(solution.workloadRooms));
  const [workingDaysRooms, setWorkingDaysRooms] = useState<string>(t(solution.workingDaysRooms));
  const [idleWindowRooms, setIdleWindowRooms] = useState<string>(t(solution.idleWindowRooms));
  const [dailyWorkloadRooms, setDailyWorkloadRooms] = useState<string>(t(solution.dailyWorkloadRooms));
  const [distinctSubjectsRooms, setDistinctSubjectsRooms] = useState<string>(t(solution.distinctSubjectsRooms));
  const [forbiddenCombinationRooms, setForbiddenCombinationRooms] = useState<string>(
    t(solution.forbiddenCombinationRooms)
  );
  const [unavailableTimesRooms, setUnavailableTimesRooms] = useState<string>(t(solution.unavailableTimesRooms));
  const [undesiredTimesRooms, setUndesiredTimesRooms] = useState<string>(t(solution.undesiredTimesRooms));
  const [consecutiveTimesRooms, setConsecutiveTimesRooms] = useState<string>(t(solution.consecutiveTimesRooms));
  const [travelTimeRooms, setTravelTimeRooms] = useState<string>(t(solution.travelTimeRooms));

  const [unavailableTimesSubjects, setUnavailableTimesSubjects] = useState<string>(
    t(solution.unavailableTimesSubjects)
  );
  const [undesiredTimesSubjects, setUndesiredTimesSubjects] = useState<string>(t(solution.undesiredTimesSubjects));
  const [assignTimes, setAssignTimes] = useState<string>(t(solution.assignTimes));
  const [assignResources, setAssignResources] = useState<string>(t(solution.assignResources));
  const [split, setSplit] = useState<string>(t(solution.split));
  const [daysBetweenLessons, setDaysBetweenLessons] = useState<string>(t(solution.daysBetweenLessons));
  const [predefinedTimes, setPredefinedTimes] = useState<string>(t(solution.predefinedTimes));
  const [simultaneousWith, setSimultaneousWith] = useState<string>(t(solution.simultaneousWith));
  const [notSimultaneousWith, setNotSimultaneousWith] = useState<string>(t(solution.notSimultaneousWith));
  const [occurBefore, setOccurBefore] = useState<string>(t(solution.occurBefore));

  const valuesClasses = [
    // clashesClasses,
    workloadClasses,
    workingDaysClasses,
    idleWindowClasses,
    dailyWorkloadClasses,
    distinctSubjectsClasses,
    forbiddenCombinationClasses,
    unavailableTimesClasses,
    undesiredTimesClasses,
    restBetweenDaysClasses,
    roomChangesDayClasses,
    consecutiveTimesClasses,
  ];
  const valuesTeachers = [
    // clashesTeachers,
    workloadTeachers,
    workingDaysTeachers,
    idleWindowTeachers,
    dailyWorkloadTeachers,
    distinctSubjectsTeachers,
    forbiddenCombinationTeachers,
    unavailableTimesTeachers,
    undesiredTimesTeachers,
    restBetweenDaysTeachers,
    roomChangesDayTeachers,
    consecutiveTimesTeachers,
  ];
  const valuesRooms = [
    // clashesRooms,
    workloadRooms,
    workingDaysRooms,
    idleWindowRooms,
    dailyWorkloadRooms,
    distinctSubjectsRooms,
    forbiddenCombinationRooms,
    unavailableTimesRooms,
    undesiredTimesRooms,
    consecutiveTimesRooms,
    travelTimeRooms,
  ];
  const valuesLessons = [
    // assignTimes,
    // assignResources,
    split,
    daysBetweenLessons,
    predefinedTimes,
    simultaneousWith,
    notSimultaneousWith,
    occurBefore,
    unavailableTimesSubjects,
    undesiredTimesSubjects,
  ];
  const settersClasses = [
    // setClashesClasses,
    setWorkloadClasses,
    setWorkingDaysClasses,
    setIdleWindowClasses,
    setDailyWorkloadClasses,
    setDistinctSubjectsClasses,
    setForbiddenCombinationClasses,
    setUnavailableTimesClasses,
    setUndesiredTimesClasses,
    setRestBetweenDaysClasses,
    setRoomChangesDayClasses,
    setConsecutiveTimesClasses,
  ];
  const settersTeachers = [
    // setClashesTeachers,
    setWorkloadTeachers,
    setWorkingDaysTeachers,
    setIdleWindowTeachers,
    setDailyWorkloadTeachers,
    setDistinctSubjectsTeachers,
    setForbiddenCombinationTeachers,
    setUnavailableTimesTeachers,
    setUndesiredTimesTeachers,
    setRestBetweenDaysTeachers,
    setRoomChangesDayTeachers,
    setConsecutiveTimesTeachers,
  ];
  const settersRooms = [
    // setClashesRooms,
    setWorkloadRooms,
    setWorkingDaysRooms,
    setIdleWindowRooms,
    setDailyWorkloadRooms,
    setDistinctSubjectsRooms,
    setForbiddenCombinationRooms,
    setUnavailableTimesRooms,
    setUndesiredTimesRooms,
    setConsecutiveTimesRooms,
    setTravelTimeRooms,
  ];
  const settersLessons = [
    // setAssignTimes,
    // setAssignResources,
    setSplit,
    setDaysBetweenLessons,
    setPredefinedTimes,
    setSimultaneousWith,
    setNotSimultaneousWith,
    setOccurBefore,
    setUnavailableTimesSubjects,
    setUndesiredTimesSubjects,
  ];

  let mapRunningTimeLabelsToSeconds: RunningTimeLabelsMap = {};
  mapRunningTimeLabelsToSeconds['1 minute'] = 60;
  mapRunningTimeLabelsToSeconds['5 minutes'] = 300;
  mapRunningTimeLabelsToSeconds['10 minutes'] = 600;
  mapRunningTimeLabelsToSeconds['30 minutes'] = 1800;
  mapRunningTimeLabelsToSeconds['1 hour'] = 3600;
  mapRunningTimeLabelsToSeconds['2 hours'] = 7200;
  mapRunningTimeLabelsToSeconds['10 hours'] = 36000;
  mapRunningTimeLabelsToSeconds['1 minuto'] = 60;
  mapRunningTimeLabelsToSeconds['5 minutos'] = 300;
  mapRunningTimeLabelsToSeconds['10 minutos'] = 600;
  mapRunningTimeLabelsToSeconds['30 minutos'] = 1800;
  mapRunningTimeLabelsToSeconds['1 hora'] = 3600;
  mapRunningTimeLabelsToSeconds['2 horas'] = 7200;
  mapRunningTimeLabelsToSeconds['10 horas'] = 36000;

  let mapRunningTimeSecondsToLabels: RunningTimeSecondsMap = {};
  mapRunningTimeSecondsToLabels[60] = t('1 minute');
  mapRunningTimeSecondsToLabels[300] = t('5 minutes');
  mapRunningTimeSecondsToLabels[600] = t('10 minutes');
  mapRunningTimeSecondsToLabels[1800] = t('30 minutes');
  mapRunningTimeSecondsToLabels[3600] = t('1 hour');
  mapRunningTimeSecondsToLabels[7200] = t('2 hours');
  mapRunningTimeSecondsToLabels[36000] = t('10 hours');

  const planCoverRuntime = (plan: string) => {
    if (plan === COURTESY || plan === PREMIUM) return true;
    if (plan === FREE && planFeatures.free.maxRuntime >= solution.runningTime) return true;
    if (plan === BASIC && planFeatures.basic.maxRuntime >= solution.runningTime) return true;
    return false;
  };

  const [display, setDisplay] = useState<string>(t(solution.display));
  const [solver, setSolver] = useState<string>(t(solution.solver));
  const [runningTimeLabel, setRunningTimeLabel] = useState<string>(
    planCoverRuntime(userPlan) ? mapRunningTimeSecondsToLabels[solution.runningTime] : mapRunningTimeSecondsToLabels[60]
  );

  function getRunningTimeLabels() {
    return [t('1 minute'), t('5 minutes'), t('10 minutes'), t('30 minutes'), t('1 hour'), t('2 hours')];
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <DialogContentText>{t('Overall')}</DialogContentText>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} paddingTop={1} alignItems="flex-start">
                <Grid item xs={12} md={8}>
                  <TextField
                    id="name"
                    required
                    fullWidth
                    size="small"
                    label={t('Name')}
                    variant="outlined"
                    translate="no"
                    disabled
                    defaultValue={solution.name}
                  />
                </Grid>
                {/* ALGORITHM */}
                {/* <Grid item xs={12} md={4}>
                  <BasicSelect
                    current={solver}
                    setCurrent={setSolver}
                    options={[t('Optimal'), t('Fast')]}
                    label={t('Algorithm')}
                  ></BasicSelect>
                </Grid> */}
                <Grid className="running-time" item xs={12} md={4}>
                  {/* RUNNING TIME */}
                  <RuntimeSelect
                    current={runningTimeLabel}
                    setCurrent={setRunningTimeLabel}
                    options={getRunningTimeLabels()}
                    label={t('Max Running Time')}
                    userPlan={userPlan}
                  ></RuntimeSelect>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {solution.status === OPTIMAL && (
            <Alert icon={<Info fontSize="inherit" />} severity="info">
              <Stack display="flex">
                <Typography>{t('The current timetable is the best possible for this input data.')}</Typography>
                <Typography>
                  {t(
                    'If it is not the ideal solution, please review your input requirements or priorities in the Advanced settings below.'
                  )}
                </Typography>
              </Stack>
            </Alert>
          )}
          <Accordion className="advanced-configs" defaultExpanded={solution.status === OPTIMAL}>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <DialogContentText>{t('Advanced')}</DialogContentText>
            </AccordionSummary>
            <AccordionDetails>
              <DialogContentText paddingTop={1}>{message}</DialogContentText>
              <Grid container spacing={2} paddingTop={1} alignItems="flex-start">
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{file?.studentsSetting !== 0 ? t('Classes') : t('Students')}</Typography>
                    {constraintTypesClasses.length === 0 && (
                      <EmptyConstraints label={t('There is no constraints for classes')} />
                    )}
                    {constraintTypesClasses.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesClasses[index]}
                            setCurrent={settersClasses[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Teachers')}</Typography>
                    {constraintTypesTeachers.length === 0 && (
                      <EmptyConstraints label={t('There is no constraints for teachers')} />
                    )}
                    {constraintTypesTeachers.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesTeachers[index]}
                            setCurrent={settersTeachers[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Rooms')}</Typography>
                    {constraintTypesRooms.length === 0 && (
                      <EmptyConstraints label={t('There is no constraints for rooms')} />
                    )}
                    {constraintTypesRooms.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesRooms[index]}
                            setCurrent={settersRooms[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Lessons')}</Typography>
                    {constraintTypesLessons.length === 0 && (
                      <EmptyConstraints label={t('There is no constraints for lessons')} />
                    )}
                    {constraintTypesLessons.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesLessons[index]}
                            setCurrent={settersLessons[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDefault}>
            {widthLessThan900 ? t('Default') : t('Restore Default')}
          </Button>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button className="confirm-button" onClick={handleConfirm}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
