import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ImportMethodSelection from 'components/ImportMethodSelection';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import FileUpload from 'components/FileUpload';
import ImportContentTable from 'components/ImportContentTable';
import { IImportWarning, MAX_IMPORT_COLS, useImportUtils } from 'util/importUtils';
import { SessionContext } from 'contexts';
import { useContainerDimensions } from 'hooks/useContainerDimensions';
import { toast } from 'react-toastify';
import { writeBatch, doc } from 'firebase/firestore';
import { db } from 'services';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import ImportClipboardAnimation from 'components/ImportClipboardAnimation';
import { ISubject } from '../Subject';

type Props = {
  type: string;
  setInfoDialogOpen: Dispatch<SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ImportSubjectsStepper({ type, setInfoDialogOpen, activeStep, setActiveStep, setOpen }: Props) {
  const { t } = useTranslation();
  const steps = [t('Choose import method'), t('Input data'), t('Data validation')];
  const [selectedFormat, setSelectedFormat] = useState<string>(t('Clipboard'));
  const { user, file, subjects, solutions, ownerEmail } = useContext(SessionContext);
  const days = file!.days;
  const times = file!.times;
  let warnings: IImportWarning[] = [];
  const gridRef = useRef<any>();
  let { width } = useContainerDimensions(gridRef);
  let maxSelectWidth = width !== 0 ? Math.floor(width / 8) : 172;
  const [fileData, setFileData] = useState<any[]>([]);
  const [clipboardText, setClipboardText] = useState<string>('');
  const [ignoreFirstLine, setIgnoreFirstLine] = useState(true);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const fileId = file?.id || file?.name;
  const subejctsPath = 'users/' + ownerEmail + '/files/' + fileId + '/subjects';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const handlePaste = async () => {
    const newClipboardText = await getContentFromClipboard();
    if (newClipboardText !== '') toast.success(t('Content successfully loaded from clipboard'));
    setClipboardText(newClipboardText);
  };

  const getContentFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      return text;
    } catch (error) {
      console.error('Failed to read clipboard contents: ', error);
      return '';
    }
  };

  const {
    getInitials,
    getRandomColor,
    handleName,
    handleArrayOfTimeslots,
    getResourceIdentifierField,
    handleIdentifierInput,
    handleTimeslotsInput,
  } = useImportUtils(t, warnings, type, days, times, file);

  const timeslotsImportFields = [t('Unavailable Times'), t('Undesired Times')];

  const importFields = [t("Don't import"), getResourceIdentifierField(), ...timeslotsImportFields];

  const [column1, setColumn1] = useState<string>(importFields[0]);
  const [column2, setColumn2] = useState<string>(importFields[0]);
  const [column3, setColumn3] = useState<string>(importFields[0]);
  const [column4, setColumn4] = useState<string>(importFields[0]);
  const [column5, setColumn5] = useState<string>(importFields[0]);
  const [column6, setColumn6] = useState<string>(importFields[0]);
  const [column7, setColumn7] = useState<string>(importFields[0]);
  const [column8, setColumn8] = useState<string>(importFields[0]);
  const columns: string[] = [column1, column2, column3, column4, column5, column6, column7, column8];
  const setColumns: React.Dispatch<React.SetStateAction<string>>[] = [
    setColumn1,
    setColumn2,
    setColumn3,
    setColumn4,
    setColumn5,
    setColumn6,
    setColumn7,
    setColumn8,
  ];

  useEffect(() => {
    const clipboardRowNames = clipboardText.split('\r\n')[0].split('\t');
    clipboardRowNames.forEach((clipboardRowName, idx) => {
      if (idx > MAX_IMPORT_COLS - 1) return;
      if (importFields.includes(clipboardRowName.trim())) {
        setColumns[idx](clipboardRowName);
      }
    });
  }, [clipboardText]);

  const validateMandatoryFields = () => {
    const idColumn = columns.find((column) => column === getResourceIdentifierField());
    if (!idColumn) {
      toast.error(`${t('One of the columns must be set as ')}${getResourceIdentifierField()}`);
      return false;
    }
    return true;
  };

  const hasRepeatedColumns = () => {
    for (let i = 0; i < columns.length; ++i) {
      for (let j = i + 1; j < columns.length; ++j) {
        if (columns[i] === columns[j] && columns[i] !== t("Don't import")) {
          toast.error(`${t('Cannot have more than one column with value')} ${columns[i]}`);
          return true;
        }
      }
    }
    return false;
  };

  const hasAtLeastTwoRows = () => {
    if (clipboardText.split('\n').length < 2) {
      toast.error(t('Data must have at least one row beyond the header'));
      return false;
    }
    return true;
  };

  const handleProceed = () => {
    console.log(clipboardText);
    if (!validateMandatoryFields()) return;
    if (hasRepeatedColumns()) return;
    if (!hasAtLeastTwoRows()) return;

    const subjectsToImport: ISubject[] = [];

    clipboardText.split('\r\n').forEach((line, index) => {
      if ((index === 0 && ignoreFirstLine) || line === '') return;

      const cells = line.split('\t');

      // Handle name
      let name: string[] = [];

      console.log({ cells });
      columns.forEach((column, i) => handleIdentifierInput(column, cells[i], handleName, index, name));

      // Handle array of timeslots input
      let unavailableTimes: string[] = [],
        undesiredTimes: string[] = [];
      const timeslotsInputConfig = {
        'Unavailable Times': { field: unavailableTimes },
        'Undesired Times': { field: undesiredTimes },
        'Horários Indisponíveis': { field: unavailableTimes },
        'Horários Indesejados': { field: undesiredTimes },
      };

      columns.forEach((column, i) => {
        handleTimeslotsInput(
          column,
          cells[i],
          handleArrayOfTimeslots,
          index,
          timeslotsImportFields,
          timeslotsInputConfig
        );
      });

      const subjectToImport = {
        color: getRandomColor(),
        photoURL: '',
        name: name[0],
        short: name.length === 0 ? '' : getInitials(name[0], 4),
        priority: priorityOptions[2],
        unavailableTimes: unavailableTimes,
        undesiredTimes: undesiredTimes,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      if (subjects.find((subject) => subject.name === subjectToImport.name) !== undefined) {
        warnings.push({
          line: index + 1,
          column: t('Subject'),
          description: `${t('Subject')} ${subjectToImport.name} ${t('already exists and will be ignored')}`,
          type: 'error',
        });
      } else if (subjectToImport.name !== undefined && subjectToImport.name !== '') {
        subjectsToImport.push(subjectToImport);
      }
    });
    setLoadingOpen(true);
    const batch = writeBatch(db);
    subjectsToImport.forEach((subject) => {
      // Add subject
      subject.createdAt = new Date();
      subject.updatedAt = new Date();
      batch.set(doc(db, subejctsPath, subject.name), subject);
      //Set solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });
    });
    // Update file stats
    batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
      updatedAt: new Date(),
    });
    batch
      .commit()
      .then(() => {
        setLoadingOpen(false);
        subjectsToImport.length > 0
          ? toast.success(`${subjectsToImport.length} ${t('subjects imported successfully')}`)
          : toast.warning(t(`No subjects imported`));
      })
      .catch(() => {
        setLoadingOpen(false);
        toast.warning(t('Data import limit per operation exceeded, please input less data at a time'));
      });

    resetStepper();
  };

  const resetStepper = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const convertFileDataToClipboard = (fileData: any[]) => {
    const clipboardData = fileData
      .map((row) => row.map((cell: string[]) => (cell === null ? '' : cell)).join('\t'))
      .join('\r\n');
    return clipboardData;
  };

  const handleNext = async () => {
    if (activeStep === 1) {
      if (selectedFormat === 'Clipboard') setClipboardText(await getContentFromClipboard());
      else {
        setClipboardText(convertFileDataToClipboard(fileData));
      }
    }
    if (activeStep === 2) {
      handleProceed();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        {activeStep === 0 && (
          <ImportMethodSelection
            t={t}
            selectedFormat={selectedFormat}
            setSelectedFormat={setSelectedFormat}
            setInfoDialogOpen={setInfoDialogOpen}
          />
        )}
        {activeStep === 1 &&
          (selectedFormat === t('Clipboard') ? <ImportClipboardAnimation /> : <FileUpload setFileData={setFileData} />)}
        {activeStep === 2 && (
          <ImportContentTable
            type={type}
            message={t('Set the data to import for each column:')}
            importFields={importFields}
            setInfoDialogOpen={setInfoDialogOpen}
            maxSelectWidth={maxSelectWidth}
            gridRef={gridRef}
            handlePaste={handlePaste}
            clipboardText={clipboardText}
            setClipboardText={setClipboardText}
            columns={columns}
            setColumns={setColumns}
            ignoreFirstLine={ignoreFirstLine}
            setIgnoreFirstLine={setIgnoreFirstLine}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button onClick={resetStepper} sx={{ mr: 1 }}>
            {t('Close')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            {t('Back')}
          </Button>
          <Button onClick={handleNext}>{activeStep === steps.length - 1 ? t('Confirm') : t('Next')}</Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}
