import OnboardingContent from 'components/OnboardingContent';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const EMPTY_CLASSES_KEY = 'onboarding-empty-list';
export const EMPTY_TEACHERS_KEY = 'onboarding-empty-list';
export const EMPTY_ROOMS_KEY = 'onboarding-empty-list';
export const useResourcesOnboarding = (t: TFunction<'translation', undefined>) => {
  const [runEmptyClassesOnboarding, setRunEmptyClassesOnboarding] = useState(false);
  const [runEmptyTeachersOnboarding, setRunEmptyTeachersOnboarding] = useState(false);
  const [runEmptyRoomsOnboarding, setRunEmptyRoomsOnboarding] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(EMPTY_CLASSES_KEY) !== 'true') {
      setRunEmptyClassesOnboarding(true);
    }
    if (localStorage.getItem(EMPTY_TEACHERS_KEY) !== 'true') {
      setRunEmptyTeachersOnboarding(true);
    }
    if (localStorage.getItem(EMPTY_ROOMS_KEY) !== 'true') {
      setRunEmptyRoomsOnboarding(true);
    }
  }, []);

  const emptyClassesSteps = [
    {
      target: '.resources-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of classes')} message={t('All classes of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import classes')}
          message={t(
            'You can import classes from any spreasheet software by uploading the file or copying from the clipboard (CTRL+C)'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: <OnboardingContent title={t('Add class')} message={t('To add a class manually, click CREATE button')} />,
    },
  ];

  const emptyTeachersSteps = [
    {
      target: '.resources-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of teachers')} message={t('All teachers of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import teachers')}
          message={t(
            'You can import teachers from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent title={t('Add teacher')} message={t('To add a teacher manually, click CREATE button')} />
      ),
    },
  ];

  const emptyRoomsSteps = [
    {
      target: '.resources-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of rooms')} message={t('All rooms of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import rooms')}
          message={t(
            'You can import rooms from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: <OnboardingContent title={t('Add room')} message={t('To add a room manually, click CREATE button')} />,
    },
  ];

  return {
    runEmptyClassesOnboarding,
    runEmptyTeachersOnboarding,
    runEmptyRoomsOnboarding,
    emptyClassesSteps,
    emptyTeachersSteps,
    emptyRoomsSteps,
  };
};
