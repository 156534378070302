import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { SessionContext } from 'contexts';
import { auth } from 'services';
import { db } from 'services';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DrawerMenuItem from '../DrawerMenuItem';
import { IFile } from 'pages/Files/File';
import {
  InsertDriveFileRounded,
  CoPresent,
  Settings,
  ExitToApp,
  ChevronRight,
  ChevronLeft,
  Home as HomeIcon,
  MapsHomeWork,
  Menu as MenuIcon,
  CalendarMonth,
  Book,
  CastForEducation,
  PeopleAlt,
  AutoStories,
} from '@mui/icons-material';
import {
  useMediaQuery,
  SelectChangeEvent,
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Grid,
  Stack,
  Typography,
  Tooltip,
  Button,
  Select,
  MenuItem,
  Avatar,
  Menu,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface MiniDrawerProps {
  currentPage?: string;
}

export default function MiniDrawer({ currentPage }: MiniDrawerProps) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const { authUser, user, file } = React.useContext(SessionContext);
  const userDoc = doc(db, `users/${authUser?.email}`);
  const history = useHistory();

  const languageOptions = [
    { code: 'BR', label: 'PT', description: 'Português (PT)' },
    { code: 'US', label: 'EN', description: 'English (US)' },
    { code: 'ES', label: 'ES', description: 'Español (ES)' },
    { code: 'DE', label: 'DE', description: 'Deutsch (DE)' },
  ];

  function signOut() {
    auth
      .signOut()
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [currentFile, setCurrentFile] = React.useState<string | undefined>(user?.selectedFile);
  const [currentCountryCode, setCurrentCountryCode] = React.useState<string | undefined>(
    languageOptions.find((option) => option.label === i18n.language.toUpperCase())
      ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
      : 'US'
  );

  React.useEffect(() => {
    setCurrentFile(user?.selectedFile);
  }, [user?.selectedFile]);

  React.useEffect(() => {
    setCurrentCountryCode(user?.countryCode);
    if (user?.countryCode === 'US') i18n.changeLanguage('en');
    else if (user?.countryCode === 'BR') i18n.changeLanguage('pt');
  }, [user?.email, user?.countryCode, i18n]);

  const handleFileNameClick = async (fileName: string) => {
    const filesPath = `users/${authUser?.email}/files`;
    const docRef = doc(db, filesPath, fileName);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.name,
        })
          .then(() => {
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
            // window.location.reload();
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  const handleCountryCodeSelectChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'US') {
      i18n.changeLanguage('en');
      toast.success(
        'Language set to ' + languageOptions.find((option) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'BR') {
      i18n.changeLanguage('pt');
      toast.success(
        'Idioma definido como ' + languageOptions.find((option) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'ES') {
      toast.warning('Aún no disponible en ESPAÑOL.. Utilice la función del navegador para traducir la página');
    } else if (event.target.value === 'DE') {
      toast.warning('Noch nicht auf DEUTSCH verfügbar.. Bitte benutzen Sie die Übersetzungsfunktion des Browsers');
    }
    updateDoc(userDoc, {
      countryCode: event.target.value,
    });
    setCurrentCountryCode(event.target.value);
  };

  const location = useLocation();

  const [selectedPage, setSelectedPage] = React.useState<string>(currentPage ? currentPage : location.pathname);

  React.useEffect(() => {
    setSelectedPage(currentPage ? currentPage : location.pathname);
  }, [currentPage, location.pathname]);

  const handleSelectPage = (selectedPage: string) => {
    if (!currentFile) {
      toast.error(t('Please define the file name and settings first'));
      return;
    }
    setSelectedPage(selectedPage);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="primary">
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container item xs={12} spacing={1} justifyContent={'space-between'} paddingRight={2}>
            <Grid item xs={9} sm={8} lg={4}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {<img height={20} width={20} src="/img/logoWhite.png" alt="Optables logo" />}
                {!widthLessThan600 && <Typography color="#ffffff">{'Optables'}</Typography>}
                {currentFile && file && (
                  <Tooltip placement="right-end" title={t('Current file')}>
                    <Button
                      style={{
                        textTransform: 'none',
                        color: 'white',
                        minWidth: 'auto',
                        minHeight: 'auto',
                        padding: 0, // Adjust padding as needed
                        margin: 0,
                      }}
                      onClick={() => {
                        handleFileNameClick(currentFile!);
                      }}
                    >
                      <Typography noWrap style={{ textDecoration: 'underline' }} translate="no">
                        {`/${file.name}`}
                      </Typography>
                    </Button>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3} sm={2} lg={1}>
              <Tooltip placement="left-end" title={t('Language')}>
                <Select
                  id="demo-select-small"
                  value={'' + currentCountryCode}
                  size="small"
                  fullWidth
                  variant="standard"
                  onChange={handleCountryCodeSelectChange}
                  sx={{
                    outline: 'none',
                    color: widthLessThan600 ? theme.palette.primary.main : '#ffffff',
                    paddingBottom: '1px',
                  }}
                >
                  {languageOptions.map((option, index) => {
                    return (
                      <MenuItem key={`${index}`} value={option.code} sx={{ minWidth: '100%' }}>
                        <Box
                          alignContent="center"
                          alignItems="center"
                          display="flex"
                          sx={{
                            '& > img': { mr: 1, flexShrink: 0 },
                            color: widthLessThan600 ? theme.palette.primary.main : 'default',
                          }}
                        >
                          <img
                            loading="lazy"
                            width="26"
                            height="auto"
                            src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt="Country flag"
                          />
                          {option.label}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Tooltip>
            </Grid>
          </Grid>
          {user && (
            <div>
              <Tooltip title={user?.name === undefined ? '' : user?.name} placement="right-end">
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar sx={{ width: 32, height: 32 }} alt={user?.name} src={user?.photoURL} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push('/settings');
                  }}
                >
                  {t('Profile')}
                </MenuItem>
                <MenuItem onClick={signOut}>{t('Sign Out')}</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            minHeight: '48px',
            maxHeight: '48px',
            color: '#ffffff',
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: '#ffffff',
              }}
              onClick={() => {
                history.push('/files');
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <HomeIcon htmlColor="#fff" />
                {/* {<img height={20} width={20} src="/img/logoWhite.png" alt="Optables logo" />} */}
              </ListItemIcon>
              <ListItemText primary={t('Home')} color="#ffffff" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRight style={{ color: '#ffffff' }} />
            ) : (
              <ChevronLeft style={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <DrawerMenuItem
          label={t('File data')}
          drawerOpen={open}
          selected={selectedPage === '/file'}
          disabled={false}
          targetPage={'/file'}
          setSelectedPage={setSelectedPage}
          icon={<InsertDriveFileRounded />}
        />
        <div className={'file-elements'}>
          <DrawerMenuItem
            label={t('Classes')}
            drawerOpen={open}
            selected={selectedPage === '/classes'}
            disabled={false}
            targetPage={!currentFile ? '/file' : '/classes'}
            setSelectedPage={() => handleSelectPage(selectedPage)}
            icon={<PeopleAlt />}
          />
          <DrawerMenuItem
            label={t('Teachers')}
            drawerOpen={open}
            selected={selectedPage === '/teachers'}
            disabled={false}
            targetPage={!currentFile ? '/file' : '/teachers'}
            setSelectedPage={() => handleSelectPage(selectedPage)}
            icon={<CoPresent />}
          />
          <DrawerMenuItem
            label={t('Rooms')}
            drawerOpen={open}
            selected={selectedPage === '/rooms'}
            disabled={file?.roomsSetting === 0}
            disabledMessage={t('Rooms were disabled for this file')}
            targetPage={!currentFile ? '/file' : '/rooms'}
            setSelectedPage={() => handleSelectPage(selectedPage)}
            icon={<MapsHomeWork />}
          />
          <DrawerMenuItem
            label={t('Subjects')}
            drawerOpen={open}
            selected={selectedPage === '/subjects'}
            disabled={false}
            targetPage={!currentFile ? '/file' : '/subjects'}
            setSelectedPage={() => handleSelectPage(selectedPage)}
            icon={<Book />}
          />
          <DrawerMenuItem
            label={t('Lessons')}
            drawerOpen={open}
            selected={selectedPage === '/lessons'}
            disabled={false}
            targetPage={!currentFile ? '/file' : '/lessons'}
            setSelectedPage={() => handleSelectPage(selectedPage)}
            icon={<AutoStories />}
          />
          <DrawerMenuItem
            label={t('Timetables')}
            drawerOpen={open}
            selected={selectedPage === '/solutions'}
            targetPage={!currentFile ? '/file' : '/solutions'}
            setSelectedPage={() => handleSelectPage(selectedPage)}
            icon={<CalendarMonth />}
          />
        </div>
        <DrawerMenuItem
          label={t('Settings')}
          drawerOpen={open}
          selected={selectedPage === '/settings'}
          disabled={false}
          targetPage={'/settings'}
          setSelectedPage={setSelectedPage}
          icon={<Settings />}
        />
        <List>
          {[t('Sign Out')].map((text, index) => (
            <Tooltip key={text} title={text} placement="right-end">
              <ListItem disablePadding sx={{ display: 'block' }} onClick={() => signOut()}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
