import MiniDrawer from 'components/Drawer/MiniDrawer';
import { SessionContext } from 'contexts';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createCheckoutSession } from '../../stripe/createCheckoutSession';
import useUserPlan from '../../stripe/useUserPlan';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { grey } from '@mui/material/colors';
import { customColors, theme } from 'styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { BASIC, FREE, planFeatures, PREMIUM } from 'configs/planFeatures';
import UserAvatarFile from './UserAvatarFile';
import { Grid, Paper, Typography, Divider, Card, CardContent, Stack, Button, colors } from '@mui/material';
import PageContainer from 'containers/PageContainer';
import Pricing from 'pages/Home/components/Pricing';
import PricingCard from 'pages/Home/components/PricingCard';

export default function Settings() {
  const { user } = useContext(SessionContext);
  // @TODO get the plans back
  // const userPlan = useUserPlan(user);
  const plans = ['free', 'basic', 'premium'];
  const userPlan = plans[0];
  const [loadingPro, setLoadingPro] = useState(false);
  const [loadingPremium, setLoadingPremium] = useState(false);

  function delay(seconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000); // Multiply by 1000 to convert seconds to milliseconds
    });
  }

  const { t } = useTranslation();
  return (
    <PageContainer>
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container spacing={1}>
            <Grid
              className="file-data-form"
              container
              item
              sm={12}
              spacing={1}
              alignItems={'start'}
              alignContent={'start'}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'space-between'}
                  padding={2}
                  spacing={1}
                  flexDirection={'row'}
                >
                  <Grid item container xs={12} spacing={1} padding={1}>
                    <Grid item flexGrow={1}>
                      <Typography variant="body1" gutterBottom sx={{ color: theme.palette.primary.main }}>
                        {t('Profile')}
                      </Typography>
                      <Divider color={theme.palette.primary.main} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justifyContent={'center'} alignItems={'center'}>
                  <UserAvatarFile user={user} />
                </Grid>
                <Grid item container justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="h4" color={grey[700]}>
                    {user?.name}
                  </Typography>
                </Grid>
                <Grid item container justifyContent={'center'} alignItems={'center'}>
                  <Typography fontSize={14}>{user?.email}</Typography>
                </Grid>
                <Grid container spacing={4} padding={4} justifyContent="space-around">
                  <PricingCard
                    t={t}
                    plan={FREE}
                    highlighted={userPlan === FREE}
                    currentCountryCode={user?.countryCode!}
                    ButtonComponent={
                      userPlan === FREE ? (
                        <Button fullWidth color="primary" disabled>
                          {t('Current plan')}
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          sx={{ backgroundColor: colors.grey[900], '&:hover': { backgroundColor: colors.grey[800] } }}
                          variant="contained"
                          onClick={() => {
                            window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank');
                          }}
                        >
                          {t('Downgrade to free')}
                        </Button>
                      )
                    }
                  />
                  <PricingCard
                    t={t}
                    plan={BASIC}
                    currentCountryCode={user?.countryCode!}
                    ButtonComponent={
                      userPlan === BASIC ? (
                        <Button
                          sx={{
                            backgroundColor: customColors.optables,
                            color: theme.palette.primary.main,
                            '&:hover': { backgroundColor: customColors.optablesDark },
                          }}
                          fullWidth
                          variant="contained"
                          onClick={() => window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank')}
                        >
                          {t('Manage subscription')}
                        </Button>
                      ) : userPlan === FREE ? (
                        <LoadingButton
                          loading={loadingPro}
                          loadingPosition="start"
                          startIcon={null}
                          fullWidth
                          sx={{
                            backgroundColor: customColors.optables,
                            color: theme.palette.primary.main,
                            '&:hover': { backgroundColor: customColors.optablesDark },
                          }}
                          variant="contained"
                          onClick={async () => {
                            setLoadingPro(true);
                            createCheckoutSession(user?.uid as string, BASIC);
                            await delay(10);
                            setLoadingPro(false);
                          }}
                        >
                          {loadingPro ? t('Redirecting') : t('Upgrade to basic')}
                        </LoadingButton>
                      ) : (
                        <Button
                          fullWidth
                          sx={{ backgroundColor: colors.grey[900], '&:hover': { backgroundColor: colors.grey[800] } }}
                          variant="contained"
                          onClick={() => {
                            window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank');
                          }}
                        >
                          {t('Downgrade to basic')}
                        </Button>
                      )
                    }
                    highlighted={userPlan === BASIC}
                  />
                  <PricingCard
                    t={t}
                    plan={PREMIUM}
                    currentCountryCode={user?.countryCode!}
                    highlighted={userPlan === PREMIUM}
                    ButtonComponent={
                      userPlan === PREMIUM ? (
                        <Button
                          sx={{
                            backgroundColor: customColors.optables,
                            color: theme.palette.primary.main,
                            '&:hover': { backgroundColor: customColors.optablesDark },
                          }}
                          fullWidth
                          variant="contained"
                          onClick={() => window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank')}
                        >
                          {t('Manage subscription')}
                        </Button>
                      ) : (
                        <LoadingButton
                          loading={loadingPremium}
                          loadingPosition="start"
                          startIcon={null}
                          fullWidth
                          sx={{ backgroundColor: colors.grey[900], '&:hover': { backgroundColor: colors.grey[800] } }}
                          variant="contained"
                          onClick={async () => {
                            setLoadingPremium(true);
                            createCheckoutSession(user?.uid as string, PREMIUM);
                            await delay(10);
                            setLoadingPremium(false);
                          }}
                        >
                          {loadingPremium ? t('Redirecting') : t('Upgrade to premium')}
                        </LoadingButton>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PageContainer>
  );
}
