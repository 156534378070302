import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function AgreementLabel() {
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} justifyContent="center">
      <Grid item xs={12}>
        <Typography color={grey[600]}>
          {t('By signing up, you agree to our')}
          &nbsp;
          <Link to={'terms'} style={{ color: 'inherit' }}>
            {t('Terms of Use')}
          </Link>
          &nbsp;{t('and')}&nbsp;
          <Link to={'privacy'} style={{ color: 'inherit' }}>
            {t('Privacy Policy')}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
