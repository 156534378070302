import { useState } from 'react';
import { useHistory } from 'react-router';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import { PaperComponent } from 'util/dialogUtils';

export default function RecoverDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const history = useHistory();
  const [email, setEmail] = useState('');

  const forgotPassword = async () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success(`${t('Instructions on recovering the password have been sent to')} ${email}`);
        history.push('/');
      })
      .catch((error) => {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    forgotPassword();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingBottom={1}>{message}</DialogContentText>
          <TextField
            label={t('Email')}
            type="email"
            variant="outlined"
            size="small"
            fullWidth
            style={{ borderRadius: 18 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirm}>{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
