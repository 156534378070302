import { FormControlLabel, Paper, Radio, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';

type Props = {
  title: string;
  image: string;
  isSelected: boolean;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
};

export default function ExportOptionCard({ title, image, isSelected, setSelected, disabled = false }: Props) {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'inline-block',
        padding: 4,
        paddingTop: 2,
        '&:hover': { cursor: 'pointer', backgroundColor: grey[50] },
        border: `${isSelected ? '4px' : '0px'} solid ${theme.palette.primary.main}`,
      }}
      onClick={() => !disabled && setSelected(title)}
    >
      <Stack spacing={2}>
        <FormControlLabel
          value={title}
          control={
            <Radio
              checked={isSelected}
              onChange={() => setSelected(title)}
              value={title}
              name={title}
              disabled={disabled}
              inputProps={{ 'aria-label': title }}
            />
          }
          label={title}
        />
        <img
          src={image}
          style={{ alignSelf: 'center' }}
          width={'120px'}
          height={'120px'}
          alt={t('Export format ') + title}
        />
      </Stack>
    </Paper>
  );
}
