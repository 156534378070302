import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import { Routes } from 'routes';
import { GlobalStyle, theme } from 'styles';
import { SessionProvider } from 'contexts';
import { CssBaseline, ThemeProvider } from '@mui/material';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Analytics />
      <ToastContainer autoClose={4000} position="bottom-left" theme="dark" />
      <CssBaseline />
      <GlobalStyle />
      <BrowserRouter>
        <SessionProvider>
          <Routes />
        </SessionProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export { App };
