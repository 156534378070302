import OnboardingContent from 'components/OnboardingContent';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const EMPTY_SOLUTIONS_KEY = 'onboarding-empty-solution';
export const useSolutionsOnboarding = (t: TFunction<'translation', undefined>) => {
  const [runEmptySolutionsOnboarding, setRunEmptySolutionsOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(EMPTY_SOLUTIONS_KEY) !== 'true') {
      setRunEmptySolutionsOnboarding(true);
    }
  }, []);

  const emptySolutionSteps = [
    {
      target: '.solutions-list',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('List of timetables')}
          message={t(
            'You can generate several timetables to the same file. All timetables of your file are displayed here'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent
          title={t('Create timetable')}
          message={t('To create a new timetable, click CREATE button')}
        />
      ),
    },
  ];

  return { runEmptySolutionsOnboarding, emptySolutionSteps };
};
