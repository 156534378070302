export interface PriorityOptionsMap {
  [key: string]: string;
}

export const priorityOptionsMap: PriorityOptionsMap = {
  Disabled: 'Disabled',
  'Very low': 'Very low',
  'Very Low': 'Very low',
  Low: 'Low',
  Average: 'Average',
  High: 'High',
  'Very high': 'Very high',
  'Very High': 'Very high',
  Desabilitado: 'Disabled',
  'Muito baixo': 'Very low',
  Baixo: 'Low',
  Normal: 'Average',
  Alto: 'High',
  'Muito alto': 'Very high',
};
