import { WorkspacePremium } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { customColors } from 'styles';
import { PaperComponent } from 'util/dialogUtils';

type Props = {
  open: boolean;
  positiveLabel: string;
  positiveAction: () => void;
  negativeLabel: string;
  negativeAction: () => void;
  title: React.ReactNode | string;
  message: React.ReactNode | string;
};

export default function PlanInfoDialog({
  open,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
  title,
  message,
}: Props) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={negativeAction}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={negativeAction}>
            {negativeLabel}
          </Button>
          <Button onClick={positiveAction}>{positiveLabel}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
