import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { TFunction } from 'react-i18next';
import { theme } from 'styles';

export const getClassHelperText = (
  t: TFunction,
  selectedClassNum: number,
  selectedClasses: string[],
  hasAttemptedToSave: boolean
) => {
  if (selectedClassNum === 0)
    return (
      <Typography variant="body2" sx={{ color: grey[600] }}>
        {t('You request no classes to attend this lesson')}
      </Typography>
    );
  else {
    if (selectedClasses.length === selectedClassNum)
      return (
        <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
          {selectedClasses.join(' ')}{' '}
          {selectedClasses.length === 1 ? t('will attend this lesson') : t('PLURAL will attend this lesson')}{' '}
          {selectedClassNum > 1 && t('together')}
        </Typography>
      );
    else if (selectedClasses.length > selectedClassNum)
      return (
        <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
          {`${selectedClassNum} ${selectedClassNum === 1 ? t('class') : t('classes')} ${t('will be chosen from the')} ${
            selectedClasses.length
          } ${t('available options to attend this lesson')}`}
        </Typography>
      );
    else
      return (
        <Typography variant="body2" sx={{ color: hasAttemptedToSave ? theme.palette.error.main : grey[600] }}>
          {selectedClassNum === 1
            ? `${t('You required one class to attend this lesson, but set no class option')}`
            : `${t('You required')} ${selectedClassNum} ${t('classes to attend this lesson, but only set')}
    ${selectedClasses.length} ${t('class')}`}
        </Typography>
      );
  }
};

export const getTeacherHelperText = (
  t: TFunction,
  selectedTeacherNum: number,
  selectedTeachers: string[],
  hasAttempedToSave: boolean
) => {
  if (selectedTeacherNum === 0)
    return (
      <Typography variant="body2" sx={{ color: grey[600] }}>
        {t('You request no teachers to teach this lesson')}
      </Typography>
    );
  else {
    if (selectedTeachers.length === selectedTeacherNum)
      return (
        <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
          {selectedTeachers.join(' ')}{' '}
          {selectedTeachers.length === 1 ? t('will teach this lesson') : t('PLURAL will teach this lesson')}{' '}
          {selectedTeacherNum > 1 && t('together')}
        </Typography>
      );
    else if (selectedTeachers.length > selectedTeacherNum)
      return (
        <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
          {`${selectedTeacherNum} ${selectedTeacherNum === 1 ? t('teacher') : t('teachers')} ${t(
            'will be chosen from the'
          )} ${selectedTeachers.length} ${t('available options to teach this lesson')}`}
        </Typography>
      );
    else
      return (
        <Typography variant="body2" sx={{ color: hasAttempedToSave ? theme.palette.error.main : grey[600] }}>
          {selectedTeacherNum === 1
            ? `${t('You required one teacher to teach this lesson, but set no teacher option')}`
            : `${t('You required')} ${selectedTeacherNum} ${t('teachers to teach this lesson, but only set')}
  ${selectedTeachers.length} ${t('options')}`}
        </Typography>
      );
  }
};

export const getRoomHelperText = (t: TFunction, selectedRoomNum: number, selectedRooms: string[]) => {
  if (selectedRoomNum === 0)
    return (
      <Typography variant="body2" sx={{ color: grey[600] }}>
        {t('You request no rooms to host this lesson')}
      </Typography>
    );
  if (selectedRooms.length !== 0) {
    if (selectedRooms.length === selectedRoomNum)
      return (
        <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
          {selectedRooms.join(' ')}{' '}
          {selectedRooms.length === 1 ? t('will host this lesson') : t('PLURAL will host this lesson')}{' '}
          {selectedRoomNum > 1 && t('together')}
        </Typography>
      );
    else if (selectedRooms.length > selectedRoomNum)
      return (
        <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
          {`${selectedRoomNum} ${selectedRoomNum === 1 ? t('room') : t('rooms')} ${t('will be chosen from the')} ${
            selectedRooms.length
          } ${t('available options to host this lesson')}`}
        </Typography>
      );
    else
      return (
        <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
          {selectedRoomNum === 1
            ? `${t('You required one room to host this lesson, but set no room option')}`
            : `${t('You required')} ${selectedRoomNum} ${t('rooms to host this lesson, but only set')}
    ${selectedRooms.length} ${t('options')}`}
        </Typography>
      );
  }
  return <></>;
};

export const getSimultaneousWithHelperText = (t: TFunction, lessonName: string, selectedSimultaneousWith: string[]) => {
  if (selectedSimultaneousWith.length > 0)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {t('All class meetings of ')} {lessonName === '' ? 'this lesson' : lessonName}
        {t(' will occur at the same times as the meetings of ')} {selectedSimultaneousWith.join(', ')}
      </Typography>
    );
};

export const getNotSimultaneousWithHelperText = (
  t: TFunction,
  lessonName: string,
  selectedNotSimultaneousWith: string[]
) => {
  if (selectedNotSimultaneousWith.length > 0)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {t('All class meetings of ')} {lessonName === '' ? 'this lesson' : lessonName}{' '}
        {t('will occur at different times than the meetings of ')} {selectedNotSimultaneousWith.join(', ')}
      </Typography>
    );
};

export const getOccurBeforeHelperText = (
  t: TFunction,
  lessonName: string,
  selectedOccurBefore: string[],
  minDaysGap: number
) => {
  if (selectedOccurBefore.length > 0 && minDaysGap > 0)
    return (
      <Typography paddingLeft={1} variant="body2" sx={{ color: theme.palette.primary.main }}>
        {t('All class meetings of ')} {lessonName === '' ? 'this lesson' : lessonName} {t('will occur at least ')}{' '}
        {minDaysGap} {t(' days before any class meeting of ')} {selectedOccurBefore.join(', ')}
      </Typography>
    );
  else if (selectedOccurBefore.length > 0 && Number(minDaysGap) === 0)
    return (
      <Typography paddingLeft={1} variant="body2" color="error">
        {t('You must select how many days, at least, the class meetings of ')}{' '}
        {lessonName === '' ? 'this lesson' : lessonName} {t('should occur before any class meeting of ')}{' '}
        {selectedOccurBefore.join(', ')}
      </Typography>
    );
  else if (selectedOccurBefore.length === 0 && minDaysGap > 0)
    return (
      <Typography paddingLeft={1} variant="body2" color="error">
        {t('You must select the lesson(s) that require ')} {lessonName === '' ? 'this lesson' : lessonName}{' '}
        {t('to occur ')} {minDaysGap} {t('days before')}
      </Typography>
    );
};

export const getSubjectHelperText = (t: TFunction, selectedSubject: string[], hasAttemptedToSave: boolean) => {
  if (selectedSubject.length === 0)
    return (
      <Typography variant="body2" sx={{ color: hasAttemptedToSave ? theme.palette.error.main : grey[600] }}>
        {t('You must set the subject of this lesson')}
      </Typography>
    );
  else {
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {selectedSubject.join('') + t(' will be taugh in this lesson')}
      </Typography>
    );
  }
};
