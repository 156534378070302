import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  ButtonGroup,
  Tooltip,
  Button,
  DialogActions,
} from '@mui/material';
import ExportOptionCard from 'components/Card/ExportOptionCard';
import { useTranslation } from 'react-i18next';
import { PaperComponent } from 'util/dialogUtils';

export default function ExportDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const setConfirmed = props.setConfirmed;
  const selectedFormat = props.selectedFormat;
  const setSelectedFormat = props.setSelectedFormat;
  const publishTimetable = props.publishTimetable;
  const setPublishTimetable = props.setPublishTimetable;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setConfirmed(true);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingTop={1}>{message}</DialogContentText>
          <Grid container spacing={2} paddingTop={1} alignItems="flex-start">
            <Grid container item spacing={1} direction={'row'} alignItems={'center'}>
              <Grid item>
                <ExportOptionCard
                  title={t('MS Excel')}
                  image={'img/exportFormats/xls.png'}
                  isSelected={selectedFormat === t('MS Excel')}
                  setSelected={setSelectedFormat}
                />
              </Grid>
              <Grid item>
                <ExportOptionCard
                  title={t('Json')}
                  image={'img/exportFormats/json.png'}
                  isSelected={selectedFormat === t('Json')}
                  setSelected={setSelectedFormat}
                />
              </Grid>
              <Grid item>
                <ExportOptionCard
                  title={t('Csv')}
                  image={'img/exportFormats/csv.png'}
                  isSelected={selectedFormat === t('Csv')}
                  setSelected={setSelectedFormat}
                />
              </Grid>
              {/* <Grid item>
                <ExportOptionCard
                  title={t('Pdf')}
                  image={'img/exportFormats/pdfNotAvailable.png'}
                  selected={selectedFormat === t('Pdf')}
                  setSelected={setSelectedFormat}
                  disabled
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <DialogContentText paddingTop={4}>{t('Publish timetable to Optables Android/iOS app?')}</DialogContentText>
          </Grid>
          <Grid item xs={6}>
            <ButtonGroup fullWidth size="small" aria-label="outlined primary button group">
              <Tooltip placement="top-end" title={t('This timetable will not be visible to any user other than you')}>
                <Button
                  variant={!publishTimetable ? 'contained' : 'outlined'}
                  onClick={() => setPublishTimetable(false)}
                >
                  {t('No')}
                </Button>
              </Tooltip>
              <Tooltip
                placement="top-end"
                title={t('This timetable will be visible for any user in the Optables Android/iOS app')}
              >
                <Button variant={publishTimetable ? 'contained' : 'outlined'} onClick={() => setPublishTimetable(true)}>
                  {t('Yes')}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button autoFocus onClick={handleConfirm}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
