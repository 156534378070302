import { useTranslation } from 'react-i18next';
import AccordionTableDescription from 'components/AccordionTableDescription';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Link, Typography, AccordionDetails, DialogContentText } from '@mui/material';

export default function ImportTeachersAccordion() {
  const { t } = useTranslation();

  function createData(name: string, description: string) {
    return { name, description };
  }

  const nameRows = [createData(t('James Chan'), ''), createData(t('Emily Liu'), '')];

  const minMaxRows = [
    createData('2-3', t('Represents min 2 and max 3')),
    createData('4-', t('Means min 4 and max unrestricted')),
    createData('-1', t('Means min unrestricted and max 1')),
  ];

  const forbiddenCombinationRows = [
    createData('1+5', t('Represents Monday (day 1) and Friday (day 5)')),
    createData('1+3+5', t('Means Monday, Wednesday, and Friday')),
  ];

  const unavailableTimesRows = [
    createData('1:1+1:2', t('Represents Monday (day 1) at 1st and 2nd times')),
    createData('1:1+2:1+3:1+4:1+5:1', t('Means Monday until Friday at the 1st time')),
    createData('*:1', t('The * mark here represents all days on the 1st time')),
  ];

  const exampleRows = [
    createData(
      t('Working Times'),
      t(
        'James and Emily are constrained to work in exactly 2 times (due to their contract hours). Lucas is available to be assigned up to 10 times. The others work exactly 4 times'
      )
    ),
    createData(
      t('Working Days'),
      t(
        'Matthew and Lucas requested to work in at least and at most 3 days. Isabela and Benjamin lecture in up to 2 days a week'
      )
    ),
    createData(
      t('Forbidden Combination'),
      t('All teachers will have either Monday (day 1) or Friday (day 5) (or both) free')
    ),
    createData(
      t('Unavailable Times'),
      t('George is unavailable all days at the 1st time, and James is unavailable on Friday')
    ),
  ];

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography color={'primary'}>{t('Column Teacher')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t('This column cannot be empty and must be a text that uniquelly identifies the teacher')}. ${t(
              'Ex.'
            )}:`}
          </DialogContentText>
          <AccordionTableDescription data={nameRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Columns Working Times until Distinct Subjects')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'These columns are imported as a pair of min-max values. Either min or max can be omitted if not constrained. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={minMaxRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Column Forbidden Combination')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('This column is imported as a set of numbers separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={forbiddenCombinationRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Columns Unavailable and Undesired Times')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('These columns are imported as a set of timeslots (day:time) separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={unavailableTimesRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Example')}&nbsp;</Typography>
          <Link href={t('/data/teachers-exampleEN.xlsx')} download>
            {'(download)'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>{t('In this example, seven teachers will be imported:')}</DialogContentText>
          <img
            src={t('img/examples/importTeachersEN.png')}
            width={'100%'}
            alt={t('Example of Import Teachers')}
            style={{ border: '1pt solid grey', borderRadius: '4pt' }}
          />
          <AccordionTableDescription data={exampleRows} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
