export const features = [
  {
    imgPath: '/img/features/awardWinning.jpg',
    title: 'Award Winner',
    message: 'Award Winner detail',
  },
  {
    imgPath: '/img/features/free.jpg',
    title: 'Free of Charge',
    message: 'Free of Charge detail',
  },
  {
    imgPath: '/img/features/flexible.jpg',
    title: 'Flexible',
    message: 'Flexible detail',
  },
  // {
  //   imgPath: '/img/features/easyToUse.jpg',
  //   title: 'Easy to Use',
  //   message: 'Easy to Use detail',
  // },
  {
    imgPath: '/img/features/dataImport.jpg',
    title: 'Data Import',
    message: 'Data Import detail',
  },
  {
    imgPath: '/img/features/prioritySetup.jpg',
    title: 'Priorities Setup',
    message: 'Priorities Setup detail',
  },
  {
    imgPath: '/img/features/resourceAssignment.jpg',
    title: 'Resource Assignment',
    message: 'Resource Assignment detail',
  },
  {
    imgPath: '/img/features/collaborationWeb.webp',
    title: 'Collaboration',
    message: 'Collaboration detail',
  },
  // {
  //   imgPath: '/img/features/aiPowered.jpg',
  //   title: 'AI Powered',
  //   message: 'AI Powered detail',
  // },
  {
    // imgPath: '/img/features/webHosted.webp',
    imgPath: '/img/features/easyToUse.jpg',
    title: 'Web Hosted',
    message: 'Web Hosted detail',
  },
  // {
  //   imgPath: '/img/features/mobileApp.jpeg',
  //   title: 'Mobile App',
  //   message: 'Mobile App detail',
  // },
  // {
  //   imgPath: '/img/features/lockTimes.jpeg',
  //   title: 'Lock Times',
  //   message: 'Lock Times detail',
  // },
  // {
  //   imgPath: '/img/features/lockTimes.jpeg',
  //   title: 'Modern and Beautiful',
  //   message: 'Modern and Beautiful detail',
  // },
];

export const testimonials = [
  {
    imgPath: '/img/testmonials/welbert_rodrigues_deelt.jpg',
    pearsonName: 'Welbert Rodrigues',
    position: 'Head of the Eletrical Engineering Department - UFOP',
    message: 'Depo 1',
  },
  {
    imgPath: '/img/testmonials/mateus_satler_decsi.jpeg',
    pearsonName: 'Mateus Satler',
    position: 'Head of the Computing and Systems Department - UFOP',
    message: 'Depo 2',
  },
  {
    imgPath: '/img/testmonials/felipe_cota_deelt.jpeg',
    pearsonName: 'Felipe Cota',
    position: 'Professor at Eletrical Engineering Department - UFOP',
    message: 'Depo 3',
  },
];
