import { TableCell } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

export default function EmptySpaceCell() {
  return (
    <TableCell
      align="center"
      sx={{ minWidth: 32, borderRight: `2px solid ${grey[300]}`, backgroundColor: 'white' }}
    ></TableCell>
  );
}
