'use client';
import { Grid, Typography, Box, TextField, Button, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customColors, theme } from 'styles/material';
import DarkChipSelect from 'components/Select/DarkChipSelect';
import { setDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from 'services';

export default function Contact() {
  const { t } = useTranslation();

  const [category, setCategory] = useState<string>('');
  const categoryOptions = [
    t('Testimonial'),
    t('Sales and pricing'),
    t('Support'),
    t('Feature request'),
    t('Bug report'),
    t('Other'),
  ];

  function handleContact() {
    const nameInput = document?.getElementById('name') as HTMLInputElement;
    const emailInput = document?.getElementById('email') as HTMLInputElement;
    const messageInput = document?.getElementById('message') as HTMLInputElement;

    const name = nameInput?.value;
    const email = emailInput?.value;
    const message = messageInput?.value;
    const sentAt = new Date();
    const contactData: IContact = {
      name: name,
      email: email,
      category: category,
      message: message,
      sentAt: sentAt,
    };
    setDoc(doc(db, 'messages', sentAt.toUTCString() + ' ' + email), contactData);
    toast.success(t('Message sent successfully'));

    nameInput.value = '';
    emailInput.value = '';
    messageInput.value = '';
    setCategory('');
  }

  return (
    <Grid container item justifyContent="center">
      <Box paddingInline={'10%'} paddingTop={'64px'} paddingBottom={'80px'}>
        <Grid container spacing={4} padding={4} justifyContent="space-around">
          <Grid container item xs={12} paddingTop={4} justifyContent="center">
            <Typography variant="h3" color={grey[50]} align="center" display="inline">
              {t('Contact us')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={3}>
              <TextField
                translate="no"
                fullWidth
                sx={{
                  input: {
                    backgroundColor: 'white',
                    borderRadius: 1,
                  },
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'white',
                    borderRadius: 1,
                  },
                  '& .MuiInputLabel-root': {
                    color: grey[600],
                  },
                }}
                id="name"
                label={t('Name')}
                variant="filled"
              />
              <TextField
                translate="no"
                fullWidth
                sx={{
                  input: {
                    backgroundColor: 'white',
                    borderRadius: 1,
                  },
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'white',
                    borderRadius: 1,
                  },
                  '& .MuiInputLabel-root': {
                    color: grey[600],
                  },
                }}
                id="email"
                label={t('Email')}
                variant="filled"
              />
              <DarkChipSelect
                current={category}
                setCurrent={setCategory}
                options={categoryOptions}
                label={t('Contact reason')}
              />

              <TextField
                translate="no"
                fullWidth
                multiline
                rows={5}
                sx={{
                  // input: { color: grey[800] },
                  label: { color: grey[800] },
                  backgroundColor: 'white',
                  borderRadius: 1,
                  input: {
                    backgroundColor: 'white',
                    borderRadius: 1,
                  },
                  '&:hover': {},
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'white',
                    borderRadius: 1,
                  },
                  '& .MuiInputLabel-root': {
                    color: grey[600],
                  },
                }}
                label={t('Message')}
                id="message"
                variant="filled"
              />
              <Box width={2}></Box>
              <Button
                fullWidth
                sx={{
                  backgroundColor: customColors.optables,
                  color: theme.palette.primary.main,
                  '&:hover': { backgroundColor: customColors.optablesDark },
                }}
                variant="contained"
                onClick={handleContact}
              >
                {t('Send')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
