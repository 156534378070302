import OnboardingContent from 'components/OnboardingContent';
import { IFile } from 'pages/Files/File';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const LESSON_CREATION_KEY = 'onboarding-lesson-creation';
export const useLessonOnboarding = (t: TFunction<'translation', undefined>, file: IFile) => {
  const [runLessonCreationOnboarding, setRunLessonCreationOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(LESSON_CREATION_KEY) !== 'true') {
      setRunLessonCreationOnboarding(true);
    }
  }, []);

  const lessonCreationSteps = [
    {
      target: '.lesson-definition',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Lesson definition')}
          message={
            file?.roomsSetting !== 0
              ? t('A lesson is a subject taught by a teacher to a class in a room')
              : t('A lesson is a subject taught by a teacher to a class')
          }
        />
      ),
    },
    {
      target: '.lesson-teacher',
      content: (
        <OnboardingContent
          title={t('Participant options')}
          message={t(
            'If you set more teacher options than requested, Optables will automatically assign the one(s) that best fit the specified constraints'
          )}
        />
      ),
    },
    {
      target: '.joint-teachers',
      content: (
        <OnboardingContent
          title={t('Joint classes/teachers/rooms')}
          message={t(
            'You can assign more than one teacher to lecture this lesson together. You can also set it for classes and rooms.'
          )}
        />
      ),
    },
    {
      target: '.distribution',
      content: (
        <OnboardingContent
          title={t('Distribution')}
          message={t('You can set how many times per week the lesson occurs and set constraints on its distribution.')}
        />
      ),
    },
    {
      target: '.split-options',
      content: (
        <OnboardingContent
          title={t('Split options')}
          message={t(
            'You can set how you want the meets of the lesson to be split. For example, you may prefer double-time (2) lessons or single-time (1) lessons'
          )}
        />
      ),
    },
    {
      target: '.advanced-lessons',
      content: (
        <OnboardingContent
          title={t('Advanced options')}
          message={t(
            'You can enforce additional constraints such as locking the times in which the lesson occurs, and setting relations between lessons such as occuring at the same times, at different times, and before other lesson(s)'
          )}
        />
      ),
    },
    // {
    //   target: '.predefined-times',
    //   content: (
    //     <OnboardingContent
    //       title={t('Predefined times')}
    //       message={t('You can enforce some of the meets of the lesson to occur at a set of predefined time slots')}
    //     />
    //   ),
    // },
    // {
    //   target: '.relations',
    //   content: (
    //     <OnboardingContent
    //       title={t('Relations')}
    //       message={t(
    //         'Last, you can set a lesson to occur simultaneously with other lesson(s), non-simultaneously with other lesson(s), and before other lesson(s)'
    //       )}
    //     />
    //   ),
    // },
  ];

  return { runLessonCreationOnboarding, lessonCreationSteps };
};
