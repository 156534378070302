import { Check } from '@mui/icons-material';
import { Grid, Card, CardContent, Typography, Stack, CardHeader, colors } from '@mui/material';
import { BASIC, COUNTRY_CODE_BR as BR, FREE, planFeatures, PREMIUM } from 'configs/planFeatures';
import React from 'react';
import { TFunction } from 'react-i18next';
import { customColors } from 'styles';

type Props = {
  t: TFunction;
  plan: 'free' | 'basic' | 'premium';
  currentCountryCode: string;
  ButtonComponent: React.ReactNode;
  highlighted?: boolean;
};

export default function PricingCard({ t, plan, currentCountryCode, ButtonComponent, highlighted = false }: Props) {
  return (
    <Grid container item xs={12} lg={4} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderColor: highlighted ? customColors.optables : colors.grey[400],
          boxShadow: 2,
          borderWidth: 1,
          borderRadius: 4,
          borderStyle: 'solid',
          padding: 4,
        }}
      >
        <CardHeader
          title={
            <Typography variant="h4" fontWeight="bold">
              {t(planFeatures[plan].name)}
            </Typography>
          }
          subheader={
            <Typography color={colors.grey[600]} variant="h3" component="div" display="flex" alignItems="center">
              <Typography variant="body2" component="span" sx={{ marginRight: '4px' }}>
                {currentCountryCode === BR ? 'R$' : '$'}
              </Typography>
              <span style={{ fontWeight: 'bold' }}>
                {currentCountryCode === BR ? planFeatures[plan].priceBRL : planFeatures[plan].priceUSD}
              </span>

              <Typography variant="body2" component="span" sx={{ marginLeft: '4px' }}>
                {planFeatures[plan].fullPriceBRL && (
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ textDecoration: 'line-through', marginLeft: '8px' }}
                  >
                    {currentCountryCode === BR ? planFeatures[plan].fullPriceBRL : planFeatures[plan].fullPriceUSD}
                  </Typography>
                )}
                /{t('month')}
                <Typography
                  variant="caption"
                  component="span"
                  display="block"
                  sx={{ fontSize: '0.8rem', marginLeft: '4px', marginTop: '2px' }}
                >
                  ({t('billed annually')})
                </Typography>
              </Typography>
            </Typography>
          }
        />
        {/* <Divider /> */}
        {/* <CardActionArea> */}
        <CardContent>
          <Grid container item justifyContent="center">
            <Grid item xs={12}>
              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                <Check color="success" />
                <Typography variant="body2">
                  {plan === FREE ? t('Access to the main features') : t('Access to all features')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                <Check color="success" />
                <Typography variant="body2">
                  {plan === PREMIUM ? t('No file limit') : `${t('Up to')} ${planFeatures[plan].maxFiles} ${t('files')}`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                <Check color="success" />
                <Typography variant="body2">
                  {t(`Up to ${planFeatures[plan].maxLessons} lessons / file`)}
                  {/* {t('Up to 300 lessons / file')} */}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                <Check color="success" />
                <Typography variant="body2">
                  {plan === FREE
                    ? t('Up to 1 minute of running time')
                    : plan === PREMIUM
                    ? t('Up to 1 hour of running time')
                    : t(`Up to ${planFeatures[plan].maxRuntime / 60} minutes of running time`)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={1}
                paddingTop={2}
                direction={'row'}
                alignItems="center"
                sx={{ visibility: plan === FREE ? 'hidden' : '' }}
              >
                <Check color="success" />
                <Typography variant="body2">
                  {t('Export formats: ')}
                  {planFeatures[plan].exportFormats.join(' ')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={1}
                paddingTop={2}
                direction={'row'}
                alignItems="center"
                sx={{ visibility: plan === FREE ? 'hidden' : '' }}
              >
                <Check color="success" />
                <Typography variant="body2">{t('Support for advanced constraints')}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={1}
                paddingTop={2}
                direction={'row'}
                alignItems="center"
                sx={{ visibility: plan === FREE ? 'hidden' : '' }}
              >
                <Check color="success" />
                <Typography variant="body2">{t('Setup constraint priority')}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={1}
                paddingTop={2}
                direction={'row'}
                alignItems="center"
                sx={{ visibility: plan === FREE || plan === BASIC ? 'hidden' : '' }}
              >
                <Check color="success" />
                <Typography variant="body2">{t('Share files with colleagues')}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={1}
                paddingTop={2}
                direction={'row'}
                alignItems="center"
                sx={{ visibility: plan === FREE || plan === BASIC ? 'hidden' : '' }}
              >
                <Check color="success" />
                <Typography variant="body2">{t('Publish timetable to mobile app')}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} paddingTop={4}>
              {ButtonComponent}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
