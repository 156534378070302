import OnboardingContent from 'components/OnboardingContent';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const RESOURCE_CREATION_KEY = 'onboarding-resource-creation';
export const useResourceOnboarding = (
  t: TFunction<'translation', undefined>,
  type: string,
  updateResource: boolean
) => {
  const [runResourceCreationOnboarding, setRunResourceCreationOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(RESOURCE_CREATION_KEY) !== 'true') {
      setRunResourceCreationOnboarding(true);
    }
  }, []);

  const resourceCreationSteps = [
    {
      target: '.name-priority',
      content: (
        <OnboardingContent
          title={t('Name and priority')}
          message={t('First, you should set the name and, optionally, the priority of this ') + t(type)}
        />
      ),
    },
    {
      target: '.availability-form',
      content: (
        <OnboardingContent
          title={t('Availability')}
          message={
            t('You can check the slots in which this ') +
            t(type) +
            t(' is unavailable, forbidding assignments at these times')
          }
        />
      ),
    },
    {
      target: '.undisired-times-tab',
      content: (
        <OnboardingContent
          title={t('Undesired Times')}
          message={t(
            'You can also mark slots as undesired, which is similar unavailable times, but with lesser priority (these times might be used if needed)'
          )}
        />
      ),
    },
    {
      target: '.constraints-form',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Constraints')}
          message={t('You can setup several types of constraints to this ') + t(type) + t(' according to your needs')}
        />
      ),
    },
    {
      target: '.constraint-description',
      content: (
        <OnboardingContent
          title={t('Constraint description')}
          message={t(
            'Hover the mouse (press and hold on mobile) on a constraint name to see its description and an example'
          )}
        />
      ),
    },
    {
      target: updateResource ? '.update-button' : '.save-button',
      content: (
        <OnboardingContent
          title={(updateResource ? t('Update ') : t('Save ')) + t(type)}
          message={
            (updateResource
              ? t("When you're done, click UPDATE to update the ")
              : t("When you're done, click SAVE to create the ")) + t(type)
          }
        />
      ),
    },
  ];

  return { runResourceCreationOnboarding, resourceCreationSteps };
};
