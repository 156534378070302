import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { doc, updateDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { SessionContext } from 'contexts';
import { db } from 'services';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AuthDialog from '../Dialog/AuthDialog';
import {
  useMediaQuery,
  SelectChangeEvent,
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Grid,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Select,
  MenuItem,
  colors,
} from '@mui/material';
import { customColors } from 'styles';
import { darkTheme } from 'styles/material';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const languageOptions = [
  { code: 'BR', label: 'PT', description: 'Português (PT)' },
  { code: 'US', label: 'EN', description: 'English (US)' },
  { code: 'ES', label: 'ES', description: 'Español (ES)' },
  { code: 'DE', label: 'DE', description: 'Deutsch (DE)' },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MiniDrawerHome(props: any) {
  const currentCountryCode = props.currentCountryCode;
  const setCurrentCountryCode = props.setCurrentCountryCode;
  const languageOptions = props.languageOptions;

  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const widthLessThan900 = useMediaQuery('(max-width:900px)');

  const { authUser } = React.useContext(SessionContext);
  const userDoc = doc(db, `users/${authUser?.email}`);
  const history = useHistory();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleCountryCodeSelectChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'US') {
      changeLanguage('en');
      toast.success(
        t('Language set to ') + languageOptions.find((option: any) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'BR') {
      changeLanguage('pt');
      toast.success(
        t('Language set to ') + languageOptions.find((option: any) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'ES') {
      toast.warning('Aún no disponible en ESPAÑOL.. Utilice la función del navegador para traducir la página');
    } else if (event.target.value === 'DE') {
      toast.warning('Noch nicht auf DEUTSCH verfügbar.. Bitte benutzen Sie die Übersetzungsfunktion des Browsers');
    }
    updateDoc(userDoc, {
      countryCode: event.target.value,
    });
    // .then(() => {
    // })
    // .catch(() => {
    //   toast.error(t('An error has occurred'));
    // });
    setCurrentCountryCode(event.target.value);
  };

  const [authOpen, setAuthOpen] = React.useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <AuthDialog open={authOpen} setOpen={setAuthOpen} title={''} message={''} />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ backgroundColor: customColors.blueDark, borderBottom: `1pt solid ${colors.grey[800]}` }}
        open={open}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: 'none' }),
            }}
          ></IconButton>
          <Grid container item xs={12} justifyContent={'space-between'} alignItems="center" paddingRight={2}>
            <Grid item xs={9} sm={8} lg={4}>
              <Stack direction={'row'} justifyContent="flex-start">
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <HashLink to="/#teaser" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        '&:hover': { color: customColors.optables },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {<img height={20} width={20} src="/img/logo.png" alt="Optables logo" />}
                      </ListItemIcon>
                      <ListItemText primary={'Optables'} sx={{ open: 1, paddingLeft: 1 }} />
                    </ListItemButton>
                  </HashLink>
                </ListItem>
                {!widthLessThan900 && (
                  <Stack direction={'row'} justifyContent="flex-start">
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <HashLink to="/#features" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            '&:hover': { color: customColors.optables },
                          }}
                        >
                          <ListItemText primary={t('Features')} sx={{ open: 1, paddingLeft: 1 }} />
                        </ListItemButton>
                      </HashLink>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <HashLink to="/#testmonials" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            '&:hover': { color: customColors.optables },
                          }}
                        >
                          <ListItemText primary={t('Testimonials')} sx={{ open: 1, paddingLeft: 1 }} />
                        </ListItemButton>
                      </HashLink>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <HashLink to="/#pricing" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            '&:hover': { color: customColors.optables },
                          }}
                        >
                          <ListItemText primary={t('Pricing')} sx={{ open: 1, paddingLeft: 1 }} />
                        </ListItemButton>
                      </HashLink>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <HashLink to="/#contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            '&:hover': { color: customColors.optables },
                          }}
                          onClick={() => history.push('/')}
                        >
                          <ListItemText primary={t('Contact')} sx={{ open: 1, paddingLeft: 1 }} />
                        </ListItemButton>
                      </HashLink>
                    </ListItem>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={3} sm={2} lg={1}>
              <Tooltip placement="left-end" title={t('Language')}>
                <ThemeProvider theme={darkTheme}>
                  <Select
                    id="demo-select-small"
                    value={'' + currentCountryCode}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={handleCountryCodeSelectChange}
                    sx={{ outline: 'none', color: widthLessThan900 ? '#ffffff' : 'primary' }}
                  >
                    {languageOptions.map((option: any, index: number) => {
                      return (
                        <MenuItem key={`${index}`} value={option.code} sx={{ minWidth: '100%' }}>
                          <Box
                            alignContent="center"
                            alignItems="center"
                            display="flex"
                            sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
                          >
                            <img
                              loading="lazy"
                              width="26"
                              src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ThemeProvider>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
