import React, { useRef, ChangeEvent, Dispatch, SetStateAction, MutableRefObject } from 'react';
import { storage } from 'services';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from 'contexts/SessionContext/SessionContext';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { IconButton, CardMedia, colors } from '@mui/material';
import { AccountBalance } from '@mui/icons-material';

type Props = {
  user: IUser;
  photoURL: string;
  setPhotoURL: Dispatch<SetStateAction<string>>;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  readOnly: boolean;
};

export default function FileAvatarFile({ user, photoURL, setPhotoURL, fileInputRef, readOnly }: any) {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleFileClick = () => {
    if (fileInputRef.current && !readOnly) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      // Handle the selected file here

      try {
        setIsLoading(true);
        const storageRef = ref(storage, `images/${user.uid}/profile/${uuidv4()}`);
        await uploadBytes(storageRef, selectedFile);

        const downloadUrl = await getDownloadURL(storageRef);

        setPhotoURL(downloadUrl);
        setIsLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <IconButton
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '0px',
          padding: '0px',
          // marginLeft: '0px',
        }}
        onClick={handleFileClick}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : photoURL ? (
          <CardMedia component="img" height="160px" image={photoURL} alt="Photo" />
        ) : (
          <AccountBalance sx={{ fontSize: 60, height: '160px' }} htmlColor={colors.grey[600]} />
        )}
      </IconButton>
    </div>
  );
}
