import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import MiniDrawer from 'components/Drawer/MiniDrawer';
import { DataGrid, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { SessionContext } from 'contexts';
import { doc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';

import { db } from 'services';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import { blue, grey } from '@mui/material/colors';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import { setRandomColor } from 'styles/randomColor';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { HighlightAlt, SearchOff } from '@mui/icons-material';
import { Grid, Typography, Paper, Divider, Button, Stack } from '@mui/material';
import { ILesson } from 'pages/Lessons/utils/types';
import { createData, useDataGrid } from './utils/useDataGrid';
import { EMPTY_SUBJECTS_KEY, useSubjectsOnboarding } from './utils/useSubjectsOnboarding';
import Onboarding from 'components/Onboarding';
import PageContainer from 'containers/PageContainer';
import { theme } from 'styles';
import HelpButton from 'components/Button/HelpButton';
import ImportStepperDialog from 'components/Dialog/ImportStepperDialog';
import useEnterKey from 'hooks/useEnterKey';
import Subject, { ISubject } from './Subject';
import { priorityOptionsMap } from 'util/configUtils';

const defaultColor: string = (
  localStorage.getItem('randomColor') ? localStorage.getItem('randomColor') : blue[900]
) as string;

export default function Subjects(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { subjects, lessons, solutions, file, user, ownerEmail, shareMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { columns } = useDataGrid(t, file!, user!);
  const { runEmptySubjectsOnboarding, emptySubjectSteps } = useSubjectsOnboarding(t);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const fileId = file?.id || file?.name;
  if (!fileId) {
    history.push('/files');
    toast.warning(t('Select a file first'));
  }

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const subjectsPath = `users/${ownerEmail}/files/${fileId}/subjects`;
  const lessonsPath = `users/${ownerEmail}/files/${fileId}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${fileId}/solutions`;

  function getNumTimesWeek(lessons: ILesson[], subjectName: string) {
    let numtimesWeek: number = 0;
    lessons
      .filter((lesson) => lesson.subject[0] === subjectName)
      .forEach((lesson) => (numtimesWeek += +lesson.lessonsWeek));
    return numtimesWeek;
  }
  let rows: any[] = [];
  subjects.map(function (subject, index) {
    return rows.push(
      createData(
        subject.short,
        subject.color,
        subject.photoURL,
        subject.name,
        t(subject.priority),
        lessons.filter((lesson) => lesson.subject[0] === subject.name).length,
        getNumTimesWeek(lessons, subject.name),
        subject.unavailableTimes.length * 100 + subject.undesiredTimes.length,
        subject.unavailableTimes,
        subject.undesiredTimes,
        (subject.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (name: string) => {
    setSelectedSubject(subjects.find((subject) => subject.name === name));
  };

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, subjectsPath, selectedId.toString()));
        //Delete related lessons
        lessons
          .filter((lesson) => lesson.subject[0] === selectedId.toString())
          .forEach((lesson) => batch.delete(doc(db, lessonsPath, lesson.name)));
        //Set solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + t(' subjects deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const handleImportClick = async () => {
    setImportOpen(true);
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  useEnterKey(handleCreate);

  function handleCreate() {
    setSelectedSubject(emptySubject);
  }

  const priorityOptions = [t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];
  const emptySubject: ISubject = {
    color: setRandomColor(),
    photoURL: '',
    name: '',
    short: '',
    priority: priorityOptionsMap[priorityOptions[2]],
    unavailableTimes: [],
    undesiredTimes: [],
    createdAt: new Date('2022-01-01T07:00:00'),
    updatedAt: new Date('2022-01-01T07:00:00'),
  };
  const [selectedSubject, setSelectedSubject] = useState<ISubject | undefined>();

  return (
    <PageContainer>
      <Onboarding steps={emptySubjectSteps} run={runEmptySubjectsOnboarding} storageFlagKey={EMPTY_SUBJECTS_KEY} />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={
          selectionModel.length + t(' subjects and their linked lessons will be permanently deleted. Are you sure?')
        }
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_subjects')}
        ></VideoPlayerDialog>
      )}
      <ImportStepperDialog open={importOpen} setOpen={setImportOpen} title={t('Import subjects')} type={'subject'} />
      <MiniDrawer />
      {selectedSubject ? (
        <Subject subject={selectedSubject} setSubject={setSelectedSubject} />
      ) : (
        <Grid container justifyContent="center">
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item flexGrow={1}>
                <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                  {'5. ' + t('Subjects')}
                </Typography>
                <Divider color={theme.palette.primary.main} />
              </Grid>
              <HelpButton t={t} onClick={() => setVideoPlayerDialogOpen(true)} />
              {selectionModel.length !== 0 ? (
                <Grid item xs={3} md={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={readOnly}
                    onClick={() => setDeleteConfirmationOpen(true)}
                    color="error"
                  >
                    {`${t('Delete')} (${selectionModel.length})`}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={3} md={2}>
                  <Button
                    className="import-button"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={readOnly}
                    onClick={() => handleImportClick()}
                    color="primary"
                  >
                    {t('Import')}
                  </Button>
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <Button
                  className="create-button"
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={handleCreate}
                  color="primary"
                >
                  {t('Create')}
                </Button>
              </Grid>
              {/* </Grid> */}
            </Grid>
            <Grid className={'subjects-list'} item xs={12} sx={{ height: '80vh', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                // autoPageSize
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[20, 50, 100]}
                // hideFooterPagination
                density={'compact'}
                // loading={rows.length === 0}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                  setSelectionModel(ids);
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'asc' }],
                  },
                }}
                localeText={
                  user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined
                }
                onRowClick={(params) => handleClick(params.id.toString())}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                      <Typography color={grey[500]} textAlign="center">
                        {t('Click on IMPORT or CREATE to add subjects')}
                      </Typography>
                    </Stack>
                  ),
                  NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                      <Typography color={grey[500]} textAlign="center">
                        {t('Local FILTER returns no data')}
                      </Typography>
                    </Stack>
                  ),
                }}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </Grid>
            <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => (file?.roomsSetting !== 0 ? history.push('/rooms') : history.push('/teachers'))}
                  color="primary"
                >
                  {t('Back')}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => history.push('/lessons')}
                  color="primary"
                >
                  {t('Next')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </PageContainer>
  );
}
