import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';

function setRandomColor() {
  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey,
    blueGrey,
  ];
  const intensities: number[] = [400, 500, 600, 700, 800, 900];
  const randomColor =
    colors[Math.floor(Math.random() * colors.length)][intensities[Math.floor(Math.random() * intensities.length)]];
  localStorage.setItem('randomColor', randomColor);
  return randomColor;
}

export { setRandomColor };
