'use client';
import { Button, colors, Grid } from '@mui/material';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customColors } from 'styles';

type ITeaserActions = {
  handleGetStarted: () => void;
};

export default function TeaserActions({ handleGetStarted }: ITeaserActions) {
  const { t } = useTranslation();

  const [isVideoPlayerDialogOpen, setIsVideoPlayerDialogOpen] = useState(false);

  return (
    <Grid container item spacing={4} xs={12} padding={4}>
      <Grid item container xs={12} sm={6} justifyContent="center">
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          sx={{ backgroundColor: customColors.optables, '&:hover': { backgroundColor: customColors.optablesDark } }}
          onClick={handleGetStarted}
        >
          {t('Get started')}
        </Button>
      </Grid>
      <Grid item container xs={12} sm={6} justifyContent="center">
        <Button
          fullWidth
          size="large"
          variant="outlined"
          color="secondary"
          onClick={() => setIsVideoPlayerDialogOpen(true)}
        >
          {t('Watch demo')}
        </Button>
      </Grid>
      <VideoPlayerDialog
        open={isVideoPlayerDialogOpen}
        setOpen={setIsVideoPlayerDialogOpen}
        videoURL={t('video_url_demo')}
      ></VideoPlayerDialog>
    </Grid>
  );
}
