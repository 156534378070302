import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ImportResourcesStepper from 'pages/Resources/components/ImportResourcesStepper';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaperComponent } from 'util/dialogUtils';
import InfoDialog from './InfoDialog';
import ImportClassesAccordion from 'pages/Resources/components/ImportClassesAccordion';
import ImportTeachersAccordion from 'pages/Resources/components/ImportTeachersAccordion';
import ImportRoomsAccordion from 'pages/Resources/components/ImportRoomsAccordion';
import ImportSubjectsStepper from 'pages/Subjects/components/ImportSubjectsStepper';
import ImportSubjectsAccordion from 'pages/Subjects/components/ImportSubjectsAccordion';
import ImportLessonsAccordion from 'pages/Lessons/components/ImportLessonsAccordion';
import ImportAssignmentsAccordion from 'pages/Solutions/components/ImportAssignmentsAccordion';
import ImportLessonsStepper from 'pages/Lessons/components/ImportLessonsStepper';
import ImportAssignmentsStepper from 'pages/Solutions/components/ImportAssignmentsStepper';
import { ISolution } from 'pages/Solutions/Solution';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  type: string;
  solution?: ISolution;
};
export default function ImportStepperDialog({ open, setOpen, title, type, solution }: Props) {
  const { t } = useTranslation();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <InfoDialog
        open={infoDialogOpen}
        setOpen={setInfoDialogOpen}
        title={t('Columns Data Format')}
        message={
          type === 'class' ? (
            <ImportClassesAccordion />
          ) : type === 'teacher' ? (
            <ImportTeachersAccordion />
          ) : type === 'room' ? (
            <ImportRoomsAccordion />
          ) : type === 'subject' ? (
            <ImportSubjectsAccordion />
          ) : type === 'lesson' ? (
            <ImportLessonsAccordion />
          ) : type === 'assignment' ? (
            <ImportAssignmentsAccordion />
          ) : (
            <></>
          )
        }
      />
      <Dialog
        fullWidth
        maxWidth={activeStep === 2 ? false : 'lg'}
        // maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {['class', 'teacher', 'room'].includes(type) && (
            <ImportResourcesStepper
              type={type}
              setInfoDialogOpen={setInfoDialogOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setOpen={setOpen}
            />
          )}
          {type === 'subject' && (
            <ImportSubjectsStepper
              type={type}
              setInfoDialogOpen={setInfoDialogOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setOpen={setOpen}
            />
          )}
          {type === 'lesson' && (
            <ImportLessonsStepper
              type={type}
              setInfoDialogOpen={setInfoDialogOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setOpen={setOpen}
            />
          )}
          {type === 'assignment' && (
            <ImportAssignmentsStepper
              type={type}
              setInfoDialogOpen={setInfoDialogOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setOpen={setOpen}
              solution={solution!}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
