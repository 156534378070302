import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { SelectBaseProps } from 'util/selectUtils';

type Props = SelectBaseProps & {
  backgroundColors: string[];
  colors: string[];
};

export default function PrioritySelect({ current, setCurrent, options, backgroundColors, colors, label }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={current} label={label} onChange={handleChange}>
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              <Chip
                size="small"
                translate="no"
                sx={{
                  minWidth: '100%',
                  // color: backgroundColors[index],
                  // backgroundColor: colors[index],
                  // borderColor: backgroundColors[index],
                  // borderWidth: 1,
                  // border: 1,
                  color: colors[index],
                  backgroundColor: backgroundColors[index],
                }}
                label={option}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
