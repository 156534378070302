import { CalendarViewMonth, ViewCompact } from '@mui/icons-material';
import { Grid, Typography, ToggleButtonGroup, ToggleButton, Tooltip, Divider } from '@mui/material';
import { TFunction } from 'react-i18next';
import { theme } from 'styles';
import { INDIVIDUAL_TABLES, COMPACT } from 'util/solutionUtils';
import { ISolution } from '../Solution';

type Props = {
  t: TFunction;
  label: string;
  solution: ISolution;
  handleViewModeChange: any;
};

export default function TimetableHeader({ t, label, solution, handleViewModeChange }: Props) {
  return (
    <>
      <Grid item xs>
        <Typography
          variant="body1"
          gutterBottom
          color={theme.palette.primary.main}
          sx={{ textAlign: 'center', flexGrow: 1 }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          color="primary"
          value={solution.display}
          exclusive
          onChange={handleViewModeChange}
          aria-label="View mode"
          sx={{ marginBottom: 0.5 }}
        >
          <ToggleButton value={INDIVIDUAL_TABLES} sx={{ paddingBlock: 0.25 }}>
            <Tooltip title={t('View individual timetables')}>
              <CalendarViewMonth />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={COMPACT} sx={{ paddingBlock: 0.25 }}>
            <Tooltip title={t('View compact timetables')}>
              <ViewCompact />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </>
  );
}
