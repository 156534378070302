import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { DataGrid, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { doc, deleteDoc, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import MiniDrawer from 'components/Drawer/MiniDrawer';
import { SessionContext } from 'contexts';
import { db } from 'services';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { HighlightAlt, SearchOff } from '@mui/icons-material';
import { Typography, Container, Grid, Paper, Divider, Button, Stack, colors } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EMPTY_SOLUTIONS_KEY, useSolutionsOnboarding } from './utils/useSolutionsOnboarding';
import Onboarding from 'components/Onboarding';
import { createData, useDataGrid } from './utils/useDataGrid';
import { theme } from 'styles';
import HelpButton from 'components/Button/HelpButton';
import useEnterKey from 'hooks/useEnterKey';
import Solution, { ISolution } from './Solution';
import { priorityOptionsMap } from 'util/configUtils';
import { COMPACT, EMPTY, FAST, OPTABLES } from 'util/solutionUtils';
import useUserPlan from 'stripe/useUserPlan';
import { BASIC, FREE, planFeatures } from 'configs/planFeatures';
import dayjs from 'dayjs';
import ptBRDayJS from 'dayjs/locale/pt-br';

export default function Solutions() {
  const { t } = useTranslation();
  const history = useHistory();
  const { solutions, lessons, user, file, ownerEmail, shareMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { columns } = useDataGrid(t, file!, user!);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const fileId = file?.id || file?.name;
  if (!fileId) {
    history.push('/files');
    toast.warning(t('Select a file first'));
  }

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
  const { runEmptySolutionsOnboarding, emptySolutionSteps } = useSolutionsOnboarding(t);

  let rows: any[] = [];
  solutions.map(function (solution, index) {
    return rows.push(
      createData(
        solution.name,
        solution.status,
        (solution.cost === 0 ? 100 : (solution.lowerBound / solution.cost) * 100).toFixed(1) + '%',
        ((solution.assignments.length / lessons.length) * 100).toFixed(1) + '%',
        (solution.assignments.length === 0
          ? 0
          : ((solution.numConstraints - solution.defects.length) / solution.numConstraints) * 100
        ).toFixed(1) + '%',
        solution.runningTime,
        (solution.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (name: string) => {
    setSelectedSolution(solutions.find((solution) => solution.name === name));
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      selectionModel.forEach((selectedId) => {
        deleteDoc(doc(db, solutionsPath, selectedId.toString()));
      });
      toast.success(selectionModel.length + t(' timetables deleted sucessfully'));
      setDeleteConfirmed(false);
    }
  };

  checkDeleteConfirmation();

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  useEnterKey(handleCreate);
  function handleCreate() {
    setSelectedSolution(emptySolution);
    // history.push('/solution');
  }

  const userPlan = useUserPlan(user);
  const getRuntimeSuggestion = () => {
    if (lessons.length < 50) {
      return 60;
    } else if (lessons.length >= 50 && lessons.length < 200) {
      return 600;
    } else {
      return 3600;
    }
  };
  const runtimeSuggestion = getRuntimeSuggestion();
  function getPlanRuntimeSuggestion() {
    if (userPlan === FREE && runtimeSuggestion > planFeatures.free.maxRuntime) return planFeatures.free.maxRuntime;
    else if (userPlan === BASIC && runtimeSuggestion > planFeatures.basic.maxRuntime)
      return planFeatures.basic.maxRuntime;
    else return getRuntimeSuggestion();
  }

  const priorityOptions = [t('Disabled'), t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];
  const [selectedSolution, setSelectedSolution] = useState<ISolution>();
  const emptySolution: ISolution = {
    assignments: [],
    lockedAssignments: [],
    defects: [],

    cost: -1,
    creator: OPTABLES,
    randomSeed: -1,
    remarks: '',
    runningTime: getPlanRuntimeSuggestion(),
    solver: FAST,
    threads: -1,
    warmStartCost: -1,
    status: EMPTY,
    lowerBound: 0,
    numConstraints: -1,
    display: COMPACT,

    name: `${t('Auto')} ${
      user?.countryCode === 'BR'
        ? dayjs(new Date().toString()).locale(ptBRDayJS).format('DD [de] MMMM [de] YYYY [às] HH:mm[h]')
        : dayjs(new Date().toString()).format('MMMM DD,YY[ at ]hh:mma')
    }`,
    createdAt: new Date(),
    updatedAt: new Date(),

    clashesClasses: priorityOptionsMap[priorityOptions[5]],
    workloadClasses: priorityOptionsMap[priorityOptions[5]],
    workingDaysClasses: priorityOptionsMap[priorityOptions[3]],
    idleWindowClasses: priorityOptionsMap[priorityOptions[3]],
    dailyWorkloadClasses: priorityOptionsMap[priorityOptions[3]],
    distinctSubjectsClasses: priorityOptionsMap[priorityOptions[3]],
    forbiddenCombinationClasses: priorityOptionsMap[priorityOptions[3]],
    unavailableTimesClasses: priorityOptionsMap[priorityOptions[5]],
    undesiredTimesClasses: priorityOptionsMap[priorityOptions[1]],
    clashesTeachers: priorityOptionsMap[priorityOptions[5]],
    workloadTeachers: priorityOptionsMap[priorityOptions[5]],
    workingDaysTeachers: priorityOptionsMap[priorityOptions[1]],
    idleWindowTeachers: priorityOptionsMap[priorityOptions[1]],
    dailyWorkloadTeachers: priorityOptionsMap[priorityOptions[1]],
    distinctSubjectsTeachers: priorityOptionsMap[priorityOptions[1]],
    forbiddenCombinationTeachers: priorityOptionsMap[priorityOptions[1]],
    unavailableTimesTeachers: priorityOptionsMap[priorityOptions[5]],
    undesiredTimesTeachers: priorityOptionsMap[priorityOptions[1]],
    clashesRooms: priorityOptionsMap[priorityOptions[5]],
    workloadRooms: priorityOptionsMap[priorityOptions[5]],
    workingDaysRooms: priorityOptionsMap[priorityOptions[3]],
    idleWindowRooms: priorityOptionsMap[priorityOptions[3]],
    dailyWorkloadRooms: priorityOptionsMap[priorityOptions[3]],
    distinctSubjectsRooms: priorityOptionsMap[priorityOptions[3]],
    forbiddenCombinationRooms: priorityOptionsMap[priorityOptions[3]],
    unavailableTimesRooms: priorityOptionsMap[priorityOptions[5]],
    undesiredTimesRooms: priorityOptionsMap[priorityOptions[1]],
    unavailableTimesSubjects: priorityOptionsMap[priorityOptions[5]],
    undesiredTimesSubjects: priorityOptionsMap[priorityOptions[1]],
    assignTimes: priorityOptionsMap[priorityOptions[5]],
    assignResources: priorityOptionsMap[priorityOptions[5]],
    split: priorityOptionsMap[priorityOptions[5]],
    daysBetweenLessons: priorityOptionsMap[priorityOptions[5]],
    predefinedTimes: priorityOptionsMap[priorityOptions[5]],
    simultaneousWith: priorityOptionsMap[priorityOptions[5]],
    notSimultaneousWith: priorityOptionsMap[priorityOptions[5]],
    occurBefore: priorityOptionsMap[priorityOptions[5]],

    restBetweenDaysClasses: priorityOptionsMap[priorityOptions[1]],
    roomChangesDayClasses: priorityOptionsMap[priorityOptions[1]],
    consecutiveTimesClasses: priorityOptionsMap[priorityOptions[1]],
    restBetweenDaysTeachers: priorityOptionsMap[priorityOptions[1]],
    roomChangesDayTeachers: priorityOptionsMap[priorityOptions[1]],
    consecutiveTimesTeachers: priorityOptionsMap[priorityOptions[1]],
    consecutiveTimesRooms: priorityOptionsMap[priorityOptions[1]],
    travelTimeRooms: priorityOptionsMap[priorityOptions[1]],
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '56px 8px 8px 65px',
        minHeight: '100vh',
        backgroundColor: colors.grey[50],
      }}
    >
      <Onboarding steps={emptySolutionSteps} run={runEmptySolutionsOnboarding} storageFlagKey={EMPTY_SOLUTIONS_KEY} />
      <VideoPlayerDialog
        open={videoPlayerDialogOpen}
        setOpen={setVideoPlayerDialogOpen}
        videoURL={t('video_url_help_solutions')}
      ></VideoPlayerDialog>
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={selectionModel.length + t(' timetables will be permanently deleted. Are you sure?')}
      />
      <MiniDrawer />
      {selectedSolution ? (
        <Solution solution={selectedSolution} setSolution={setSelectedSolution} />
      ) : (
        <Grid container justifyContent="center">
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item flexGrow={1}>
                <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                  {'7. ' + t('Timetables')}
                </Typography>
                <Divider color={theme.palette.primary.main} />
              </Grid>
              <HelpButton t={t} onClick={() => setVideoPlayerDialogOpen(true)} />
              {selectionModel.length !== 0 && (
                <Grid item xs={3} md={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={readOnly}
                    onClick={() => setDeleteConfirmationOpen(true)}
                    color="error"
                  >
                    {`${t('Delete')} (${selectionModel.length})`}
                  </Button>
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <Button
                  className="create-button"
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={handleCreate}
                  color="primary"
                >
                  {t('Create')}
                </Button>
              </Grid>
            </Grid>
            <Grid className="solutions-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                density={'compact'}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                  setSelectionModel(ids);
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'updatedAt', sort: 'desc' }],
                  },
                }}
                localeText={
                  user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined
                }
                onRowClick={(params) => handleClick(params.id.toString())}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                      <Typography color={grey[500]} textAlign="center">
                        {t('Click on CREATE to add a new timetable')}
                      </Typography>
                    </Stack>
                  ),
                  NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                      <Typography color={grey[500]} textAlign="center">
                        {t('Local FILTER returns no data')}
                      </Typography>
                    </Stack>
                  ),
                }}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </Grid>
            <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => history.push('/lessons')}
                  color="primary"
                >
                  {t('Back')}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{ visibility: 'hidden' }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => history.push('/settings')}
                  color="primary"
                >
                  {t('Next')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Container>
  );
}
