import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Dispatch, SetStateAction } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

type Props = {
  single?: boolean;
  label: string;
  names: string[];
  personName: string[];
  setPersonName: Dispatch<SetStateAction<string[]>>;
  disabled?: boolean;
  chipColor?: string;
  InputProps?: any;
  onChangeFn?: () => void;
};

export default function MultipleSelectChip({
  single = false,
  label,
  names,
  personName,
  setPersonName,
  disabled = false,
  chipColor = '',
  InputProps = {},
  onChangeFn,
}: Props) {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    if (onChangeFn) {
      // Used to block premium features
      onChangeFn();
      return;
    }
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple={!single}
          required
          value={personName}
          onChange={handleChange}
          disabled={disabled}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          inputProps={InputProps}
          // sx={{
          //   backgroundColor: +personName.length !== 0 ? '#edf4fd' : '#fff',
          //   color: +personName.length !== 0 ? '#1a73e8' : '#fff',
          // }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  sx={chipColor ? { backgroundColor: chipColor, color: 'white', border: '0px' } : {}}
                  // sx={{
                  //   backgroundColor: '#1a73e8',
                  //   color: '#fff',
                  // }}
                  size="small"
                  // variant={chipColor ? 'outlined' : 'filled'}
                  variant={'outlined'}
                  translate="no"
                  key={value}
                  label={value}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem translate="no" key={name} value={name} style={getStyles(name, personName, theme)}>
              {single ? (
                <ListItemText primary={name} />
              ) : (
                <>
                  <Checkbox sx={{ width: 18, height: 18, paddingRight: 2 }} checked={personName.includes(name)} />
                  <ListItemText primary={name} />
                </>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
