import { createTheme } from '@mui/material';
import { customColors } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: customColors.blue,
      contrastText: customColors.whiteDark,
    },
    secondary: {
      main: customColors.whiteDark,
      contrastText: customColors.blue,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export { theme, darkTheme, customColors };
