import { Tooltip, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { customColors, theme } from 'styles';

export default function DrawerMenuItem(props: any) {
  const history = useHistory();
  const label = props.label;
  const drawerOpen = props.drawerOpen;
  const selected = props.selected;
  const disabled = props.disabled;
  const disabledMessage = props.disabledMessage;
  const targetPage = props.targetPage;
  const setSelectedPage = props.setSelectedPage;
  const icon = props.icon;

  return (
    <Tooltip title={label} placement="right-end">
      <ListItem
        className={'menu-' + label}
        disablePadding
        sx={{ display: 'block' }}
        onClick={() => {
          if (disabled) toast.warning(disabledMessage);
          else {
            history.push(targetPage);
            setSelectedPage(targetPage);
          }
        }}
      >
        <ListItemButton
          disabled={disabled}
          selected={selected}
          sx={{
            color: selected ? theme.palette.primary.main : 'primary',
            minHeight: 48,
            justifyContent: drawerOpen ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              color: selected ? theme.palette.primary.main : 'primary',
              minWidth: 0,
              mr: drawerOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}
