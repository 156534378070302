import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PaperComponent } from 'util/dialogUtils';
import SignInScreen from 'components/SignInScreen';

export default function LoginDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" textAlign={'center'} paddingTop={4}>
            {t('Get started for free:')}
          </Typography>
          <SignInScreen />
        </Box>
      </Dialog>
    </div>
  );
}
